/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import WorldMap from '../components/WorldMap';

import useGlobalState from '../hooks/useGlobalState';

import APITimer from '../lib/APITimer';
import SourceDialog from '../components/SourceDialog';

import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f8fafb',
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 400,
  },
  brandsBlock: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 400,
  },
}));

const defaultState = {
  mapInfo: [],
};

export default function Specs() {
  const classes = useStyles();
  const globalState = useGlobalState();

  const [state, setState] = React.useState(defaultState);

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    setState(defaultState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();

    tim.start();

    const filters = cleanFilters(inFilters);

    try {
      EventRecorder('map/offices', filters);

      const stateData = await Promise.all([
        dataToState({ url: '/v2/analytics/map/projects', filters }, (data) => {
          const results = data.data.map(r => ({ name: r.name, lat: r.lat, lon: r.lng, cnt: r.cnt }));
          return { mapInfo: results };
        }),
      ]);

      tim.finishAPI();

      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }

    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    document.title = 'Maps Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    // We have to ensure the browser url has the parameters.
    setBrowserUrl(globalState.filters);

    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <SourceDialog />
      <Grid container spacing={3}>
        <Grid item className="brands-block" xs={12} sm={12}>
          <WorldMap title="Projects" data={state.mapInfo} />
        </Grid>
      </Grid>
    </Paper>
  );
}
