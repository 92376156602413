import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import resttp from '../lib/resttp';
import history from '../lib/history';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
  container: {
    maxHeight: '300px',
    height: '300px',
    overflow: 'auto',
  },
  list: {
    margin: '10px',
    columnCount: 4,
  },

  transferRoot: {
    margin: 'auto',
  },
  transferPaper: {
    width: 300,
    height: 430,
    overflow: 'auto',
  },
  transferButton: {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function Admin(props) {
  const classes = useStyles();

  // oh wow, this is a pretty bad way to manage the alert messaging
  // 0 = loading, 1 = editing, 2 = saving, 3 = saved, 4 = changed, 5 = error
  const [alerting, setAlerting] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [available, setAvailable] = React.useState({});
  const [firmId, setFirmId] = React.useState(props.match.params.firmId);

  const [defaultCategories, setDefaultCategories] = React.useState([]);
  const [defaultSubCategories, setDefaultSubCategories] = React.useState([]);

  const [checkedCat, setCheckedCat] = React.useState([]);
  const [leftCat, setLeftCat] = React.useState([]);
  const [rightCat, setRightCat] = React.useState([]);

  const leftCatChecked = intersection(checkedCat, leftCat);
  const rightCatChecked = intersection(checkedCat, rightCat);

  // Nested categories
  const [checkedSubCat, setCheckedSubCat] = React.useState([]);
  const [leftSubCat, setLeftSubCat] = React.useState([]);
  const [rightSubCat, setRightSubCat] = React.useState([]);

  const leftSubCatChecked = intersection(checkedSubCat, leftSubCat);
  const rightSubCatChecked = intersection(checkedSubCat, rightSubCat);

  const [settings, setSettings] = React.useState({
    hasfeature: {
      export: false,
      savefilters: false,
      favorite: false,
      hideprojects: false,
      hidesegments: false,
      nestedcategories: false,
    },
    permissions: {
      brands: false,
      products: false,
      internal: false,
    },
    firmbrands: [],
    specification: [],
    filters: {
      categories: [],
    },
    defaultcategories: [],
    defaultsubcategories: [],
  });

  const saveSettings = async () => {
    const { filters } = settings;
    setAlerting(2);

    filters.categories = rightCat;
    filters.subCategories = rightSubCat;
    setSettings({ ...settings, filters });

    const thesettings = settings;
    thesettings.defaultcategories = defaultCategories;
    thesettings.defaultsubcategories = defaultSubCategories;
    setSettings(thesettings);

    const url = `/v2/admin/user/${firmId}`;
    const response = await resttp.post(url, settings);
    if (response.error) {
      setAlerting(5);
    } else {
      setAlerting(3);
    }
  };

  const handlePermissions = (id) => (event) => {
    const { permissions } = settings;
    permissions[id] = event.target.checked;
    setAlerting(4);
    setSettings({ ...settings, permissions });
  };
  const handleFeatures = (id) => (event) => {
    const { hasfeature } = settings;
    hasfeature[id] = event.target.checked;
    setAlerting(4);
    setSettings({ ...settings, hasfeature });
  };
  const handleFirmBrandsChange = (event) => {
    setAlerting(4);
    const firmbrands = event.target.value.split(',');
    setSettings({ ...settings, firmbrands });
  };

  const handleSpecificationsChange = (event) => {
    setAlerting(4);
    const specifications = event.target.value.split(',');
    setSettings({ ...settings, specifications });
  };

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const curr = await resttp.get(`/v2/admin/user/${firmId}`, {});
      if (curr.error) {
        alert('No admin access for firm info');
        history.push('/');
        return;
      }

      curr.data.configuration.filters.categories =
        curr.data.configuration.filters.categories.map((c) => c.id);
      curr.data.configuration.filters.subCategories =
        curr.data.configuration.filters.subCategories.map((c) => c.id);

      setSettings(curr.data.configuration);

      const res = await resttp.get('/v2/admin/available', {});
      if (res.error) {
        alert('No admin access for available data');
        history.push('/');
        return;
      }

      setAvailable(res.data);

      // categories
      const availCats = res.data.filters.categories.map((itm) => itm.id);
      setLeftCat(
        availCats.filter(
          (c) => !curr.data.configuration.filters.categories.includes(c)
        )
      );
      setRightCat(curr.data.configuration.filters.categories);

      // sub-categories
      const availSubCats = res.data.filters.subCategories.map((itm) => itm.id);
      setLeftSubCat(
        availSubCats.filter(
          (c) => !curr.data.configuration.filters.subCategories.includes(c)
        )
      );
      setRightSubCat(curr.data.configuration.filters.subCategories);

      // defaults
      setDefaultCategories(curr.data.defaults.filters.categories);
      setDefaultSubCategories(curr.data.defaults.filters.subCategories);

      setLoading(false);
    };

    if (firmId) {
      fetchData();
    }
  }, [firmId]);

  const handleDefaultCategoryToggle = (value, type = 'cat') => {
    if (type === 'cat') {
      const currentIndex = defaultCategories.indexOf(value);
      const newChecked = [...defaultCategories];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setDefaultCategories(newChecked);
      setAlerting(4);
    } else {
      const currentIndex = defaultSubCategories.indexOf(value);
      const newChecked = [...defaultSubCategories];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setDefaultSubCategories(newChecked);
      setAlerting(4);
    }
  };

  const handleToggle = (value, type = 'cat') => {
    if (type === 'cat') {
      const currentIndex = checkedCat.indexOf(value);
      const newChecked = [...checkedCat];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setCheckedCat(newChecked);
    } else {
      const currentIndex = checkedSubCat.indexOf(value);
      const newChecked = [...checkedSubCat];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setCheckedSubCat(newChecked);
    }
  };
  const handleCheckedRight = (type = 'cat') => {
    if (type === 'cat') {
      setRightCat(rightCat.concat(leftCatChecked));
      setLeftCat(not(leftCat, leftCatChecked));
      setCheckedCat(not(checkedCat, leftCatChecked));
      setAlerting(4);
    } else if (type === 'subcat') {
      setRightSubCat(rightSubCat.concat(leftSubCatChecked));
      setLeftSubCat(not(leftSubCat, leftSubCatChecked));
      setCheckedSubCat(not(checkedSubCat, leftSubCatChecked));
      setAlerting(4);
    }
  };

  const handleCheckedLeft = (type = 'cat') => {
    if (type === 'cat') {
      setLeftCat(leftCat.concat(rightCatChecked));
      setRightCat(not(rightCat, rightCatChecked));
      setCheckedCat(not(checkedCat, rightCatChecked));
      setAlerting(4);
    } else if (type === 'subcat') {
      setLeftSubCat(leftSubCat.concat(rightSubCatChecked));
      setRightSubCat(not(rightSubCat, rightSubCatChecked));
      setCheckedSubCat(not(checkedSubCat, rightSubCatChecked));
      setAlerting(4);
    }
  };

  const getCategoryName = (value, type = 'cat') => {
    const col =
      type === 'cat'
        ? available.filters.categories
        : available.filters.subCategories;
    const itm = col.find((v) => v.id === value);
    if (itm) {
      return itm.name;
    }
    return `didnt find ${value}`;
  };

  const customList = (title, items, type = 'cat') => {
    const col = type === 'cat' ? checkedCat : checkedSubCat;
    return (
      <>
        <Typography>
          {title} ({items.length})
        </Typography>
        <Paper className={classes.transferPaper}>
          <List dense component="div" role="list">
            {items.map((value) => {
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={() => handleToggle(value, type)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={col.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={getCategoryName(value, type)}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Paper>
      </>
    );
  };

  if (loading) {
    return (
      <div>
        <Alert severity="info" variant="outlined">
          Loading Firm Configuration
        </Alert>
      </div>
    );
  }
  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          {alerting === 2 ? (
            <Alert severity="info" variant="outlined">
              Saving Configuration
            </Alert>
          ) : (
            ''
          )}
          {alerting === 3 ? (
            <Alert severity="success" variant="outlined">
              Configuration Saved! - User will have to log out for changes to
              take effect
            </Alert>
          ) : (
            ''
          )}
          {alerting === 4 ? (
            <Alert severity="warning" variant="outlined">
              Configuration has been modified
            </Alert>
          ) : (
            ''
          )}
          {alerting === 5 ? (
            <Alert severity="error" variant="outlined">
              Failed to save configuration
            </Alert>
          ) : (
            ''
          )}

          <h3>Firm : {firmId}</h3>
          <p>
            Modification of the settings for this firm affects all users
            associated with the firm.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button variant="contained" onClick={() => history.push('/admin/')}>
            Cancel
          </Button>
          <Button variant="contained" onClick={saveSettings}>
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Permissions</FormLabel>
            <FormHelperText>
              These settings determine which parts of the application a Firm has
              access to
            </FormHelperText>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.permissions.brands}
                    onChange={handlePermissions('brands')}
                  />
                }
                label="Access Brands"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.permissions.products}
                    onChange={handlePermissions('products')}
                  />
                }
                label="Access Products"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.permissions.internal}
                    onChange={handlePermissions('internal')}
                  />
                }
                label="Access Internal Reports"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Settings</FormLabel>
            <FormHelperText>
              These settings determine the type of actions a Firm can perform.
            </FormHelperText>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.hideprojects}
                    onChange={handleFeatures('hideprojects')}
                  />
                }
                label="Hide Projects on Brand Drilldown"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.hidesegments}
                    onChange={handleFeatures('hidesegments')}
                  />
                }
                label="Hide Segments on Filters"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.nestedcategories}
                    onChange={handleFeatures('nestedcategories')}
                  />
                }
                label="Allow to filter by Nested Categories"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.export}
                    onChange={handleFeatures('export')}
                  />
                }
                label="Can Export (not implemented yet)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.savefilters}
                    onChange={handleFeatures('savefilters')}
                  />
                }
                label="Can Save Filters (not implemented yet)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.hasfeature.favorite}
                    onChange={handleFeatures('favorite')}
                  />
                }
                label="Can Favorite (not implemented yet)"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">My Brands</FormLabel>
            <FormHelperText>
              For Firms that show only a drill down of their brand data, include
              the manufacturer ids that should be used.
              <b>Note: Most firms will NOT need a value here</b>
              <br />
              This must be a comma separated list (e.g. 1234,56789,101010 )
            </FormHelperText>
            <TextField
              label="Firm Brands"
              variant="outlined"
              value={settings.firmbrands}
              onChange={handleFirmBrandsChange}
            />
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Specification Parameters </FormLabel>
            <FormHelperText>
              If a firm should have access to specifications, add the spec type
              ids
              <br />
              This must be a comma separated list (e.g. 1234,56789,101010 )
              <br />
              Color and Pattern are STRINGS, 'color' and 'pattern'
              <br />
              e.g. 1234,color,5678,pattern,101010
              <br />
              Will enable: color, pattern and spec types 1234, 5678, 101010
            </FormHelperText>
            <TextField
              label="Specification Types"
              variant="outlined"
              value={settings.specifications}
              onChange={handleSpecificationsChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 50 }}>
        <Grid item xs={8}>
          <FormLabel component="legend">Categories</FormLabel>
          <FormHelperText>
            Choose the categories a firm should have access to from the list of
            `Available Categories`
          </FormHelperText>
          <Grid container spacing={2} className={classes.transferRoot}>
            <Grid item>
              {customList('Available Categories', leftCat, 'cat')}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.transferButton}
                  onClick={() => handleCheckedRight('cat')}
                  disabled={leftCatChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.transferButton}
                  onClick={() => handleCheckedLeft('cat')}
                  disabled={rightCatChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList('Enabled Categories', rightCat, 'cat')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <FormLabel component="legend">Default Categories</FormLabel>
          <FormHelperText>
            Choose the categories that should be used as the default categories
            on load.
          </FormHelperText>
          <Paper className={classes.transferPaper}>
            <List dense>
              {rightCat.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem key={value} button>
                    <ListItemText
                      id={labelId}
                      primary={getCategoryName(value, 'cat')}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          handleDefaultCategoryToggle(value, 'cat')
                        }
                        checked={defaultCategories.indexOf(value) !== -1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: 50 }}>
        <Grid item xs={8}>
          <FormLabel component="legend">Sub-Categories</FormLabel>
          <FormHelperText>
            Choose the sub-categories a firm should have access to from the list
            of `Available Sub-Categories`
          </FormHelperText>
          <Grid container spacing={2} className={classes.transferRoot}>
            <Grid item>
              {customList('Available Sub-Categories', leftSubCat, 'subcat')}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.transferButton}
                  onClick={() => handleCheckedRight('subcat')}
                  disabled={leftSubCatChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.transferButton}
                  onClick={() => handleCheckedLeft('subcat')}
                  disabled={rightSubCatChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {customList('Enabled Sub-Categories', rightSubCat, 'subcat')}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <FormLabel component="legend">Default Sub-Categories</FormLabel>
          <FormHelperText>
            Choose the sub-categories that should be used as the default
            sub-categories on load.
          </FormHelperText>
          <Paper className={classes.transferPaper}>
            <List dense>
              {rightSubCat.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem key={value} button>
                    <ListItemText
                      id={labelId}
                      primary={getCategoryName(value, 'subcat')}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() =>
                          handleDefaultCategoryToggle(value, 'subcat')
                        }
                        checked={defaultSubCategories.indexOf(value) !== -1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
