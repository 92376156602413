import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { makeStyles, Typography, Button, IconButton, Icon, Toolbar } from '@material-ui/core';

import mapDataUS from './us-all';

const XLSX = require('xlsx');

require('highcharts/modules/map')(Highcharts);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#f8fafb',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
}));

export default function MapChart(props) {
  const classes = useStyles();
  const [title, setTitle] = React.useState('');
  const [state, setState] = React.useState({
    chartOptions: {
      title: {
        text: '',
      },
      colorAxis: {
        min: 0,
        minColor: '#1fcdba',
        maxColor: '#01a891',
        labels: {
          format: '{value}%',
        },
      },
      chart: {
        backgroundColor: '#f8fafb',
      },
      series: [
        {
          mapData: mapDataUS,
          name: 'Score distribution by US State',
          data: Array.from(props.data),
          states: {
            hover: {
              color: '#a4edba',
            },
          },
        },
      ],
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 4,
      },
      exporting: {
        enabled: false,
      },
      chartOptions: [],
    },
  });

  React.useEffect(() => {
    const setup = async () => {
      const chartOpts = {
        series: [
          { data: Array.from(props.data) },
        ],
      };

      setState(prevState => ({ ...prevState, ...{ chartOptions: chartOpts } }));
      setTitle(props.title);
    };
    setup();
  }, [props]);

  const handleExport = () => {
    const data = state.chartOptions.series[0].data;
    const out = data.map(r => ({ state: r['hc-key'], value: r.value }));

    const exportName = 'mapchart.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(out);
    XLSX.utils.book_append_sheet(workbook, ws, 'dataset');
    XLSX.writeFile(workbook, exportName);
  };


  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant='h4'>
          {title}
          {(state.chartOptions.series.length > 0) ? (
            <IconButton className={classes.exportBtn} onClick={handleExport}>
              <Icon className="icon-download" />
            </IconButton>
          ) : <></> }
        </Typography>
      </Toolbar>
      <HighchartsReact
        highcharts={Highcharts}
        options={state.chartOptions}
        constructorType="mapChart"
      />
    </Paper>
  );
}


MapChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  title: PropTypes.string.isRequired,
};
