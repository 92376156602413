/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import DataTable from '../components/DataTable';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const globalState = useGlobalState();

  const [state, setState] = React.useState({
    categories: [],
    loadTime: '∞',

    categoryColumns: [
      { name: 'Spec Id', column: 'spec_id' },
      { name: 'Spec Type Name', column: 'spec_type_name', sort: 'asc' },
      { name: 'Spec Type Id', column: 'spec_type_id' },
      { name: 'Spec Name', column: 'spec_name' },
      { name: 'Score', column: 'score' },
      { name: 'Group Score', column: 'grp_score', digits: 4, digits: 4 },
    ],
  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const ensureFilters = (filters) => {
    if (filters.category_brands.length < 1) {
      globalState.setErrors('Please select one or more category brands');
      return false;
    }
    return true;
  };

  const callAll = async (inFilters) => {
    globalState.setFilterType('category');
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();
    tim.start();
    const filters = cleanFilters(inFilters);

    if (!ensureFilters(filters)) {
      globalState.setProcessing(false);
      return;
    }

    try {
      EventRecorder('category/overview', filters);

      const stateData = await Promise.all([
        dataToState({ url: '/v2/analytics/category/breakdown', filters }, data => ({
          categoryColumns: data.columns,
          categories: data.data,
        })),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    document.title = 'Category Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <DataTable title="Categories" height="600px" columns={state.categoryColumns} records={state.categories} defaultSort={{ dir: 'desc', column: 'grp_score' }} />
        </Grid>
      </Grid>
    </Paper>
  );
}
