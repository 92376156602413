/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import DataTable from '../components/DataTable';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

const defaultState = {
  events: [],

  loadTime: '∞',

  columns: [
    { name: 'Event Type Id', column: 'event_type_id' },
    { name: 'Event Name', column: 'name' },
    { name: 'Count', column: 'cnt', sort: 'desc' },
  ],
};

export default function BrandEventTypeSummary(props) {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState(defaultState);
  const brand = { id: 'multi', name: 'whatever' };
  // const [brand, setBrand] = React.useState({
  //   id: props.match.params.brandId,
  //   name: `Loading Brand ${props.match.params.brandId}`,
  // });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    setState(defaultState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();
    tim.start();

    const filters = cleanFilters(inFilters);
    filters.multibrands = props.match.params.brandids;
    try {
      EventRecorder('brand/eventtypesummary', { brandId: brand.id, filters });

      const stateData = await Promise.all([
        // dataToState({ url: `/v2/analytics/brand/${brand.id}/name` }, 'brandName'),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/eventtypesummary`, filters }, 'events'),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    document.title = `Brand Event Type Summary : ${brand.name}`;
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      {/* <h2>{brand.id} :: {brand.name}</h2> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <DataTable title="Brand Event Type Summary" height="600px" columns={state.columns} records={state.events} />
        </Grid>
      </Grid>
    </Paper>
  );
}

BrandEventTypeSummary.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
