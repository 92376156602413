import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { makeStyles, Button, IconButton, Icon, Toolbar } from '@material-ui/core';

const XLSX = require('xlsx');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#f8fafb',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
}));

export default function LineChart(props) {
  const classes = useStyles();
  const [title, setTitle] = React.useState('');
  const [state, setState] = React.useState({
    chartOptions: {
      type: 'line',
      width: '100%',
      lang: {
        thousandsSep: ',',
      },
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: props.yTitle,
        },
      },
      chart: {
        backgroundColor: '#f8fafb',
      },
      legend: {
        enabled: (props.useLegend),
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
      },
      xAxis: {
        tickInterval: 1,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 4,
        formatter() {
          if (props.percentage) {
            return `${this.y}%`;
          } else {
            return this.y;
          }
        },
      },
      series: [],
    },
  });

  React.useEffect(() => {
    const setup = async () => {
      const chartOpts = {
        series: props.series,
        colors: ['#1fcdba', '#01a891'],
        xAxis: { categories: props.labels },
      };

      setState(prevState => ({ ...prevState, ...{ chartOptions: chartOpts } }));
      setTitle(props.title);
    };
    setup();
  }, [props]);

  const handleExport = () => {
    const cats = state.chartOptions.xAxis.categories;
    const data = state.chartOptions.series[0].data;
    const out = [];

    for (let i = 0; i < cats.length; i++) {
      out.push({ name: cats[i], value: data[i] });
    }

    const exportName = 'linechart.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(out);
    XLSX.utils.book_append_sheet(workbook, ws, 'dataset');
    XLSX.writeFile(workbook, exportName);
  };

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="h4">
          {title}
          {(state.chartOptions.series.length > 0) ? (
            <IconButton className={classes.exportBtn} onClick={handleExport}>
              <Icon className="icon-download" />
            </IconButton>
          ) : <></> }
        </Typography>
      </Toolbar>
      <HighchartsReact
        highcharts={Highcharts}
        options={state.chartOptions}
      />
    </Paper>
  );
}


LineChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  yTitle: PropTypes.string,
  useLegend: PropTypes.bool,
  percentage: PropTypes.bool,
};

LineChart.defaultProps = {
  yTitle: '',
  useLegend: false,
  percentage: false,
};
