import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

// import { CsvBuilder } from 'filefy';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Pagination from '@material-ui/lab/Pagination';

const XLSX = require('xlsx');

const MIN_ROW_COUNT = 10;

const CustomTableCell = withStyles((theme) => ({
  head: {
    xbackgroundColor: theme.palette.primary.main,
    xcolor: theme.palette.common.black,
    backgroundColor: '#f8fafb !important',
    color: '#888888 !important',
    lineHeight: '1.2 !important',
    paddingBottom: '3px !important',
    textAlign: 'left !important',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ImageTableCell = withStyles((theme) => ({
  head: {
    xbackgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
  root: {
    '& img': {
      maxWidth: '100px',
      maxHeight: '100px',
      padding: '5px',
      minWidth: '100px',
      borderRadius: '12px',
      border: '1px solid #b9b9b9',
      background: '#ffffff',
    },
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    // marginLeft: theme.spacing(2.5),
  },
  paginationSpace: {
    flex: '1 1 100%',
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleGotoPageClick = (event, thePage) => {
    onChangePage(event, thePage - 1);
  };

  // total number of pages we have
  const pages = Math.max(0, Math.ceil(count / rowsPerPage));

  return (
    <>
      <div className={classes.paginationSpace} />
      <div className={classes.root}>
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={pages}
          page={page + 1}
          onChange={handleGotoPageClick}
        />
      </div>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  title: {
    flexGrow: 1,
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
  table: {
    xminWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  margin: {
    margin: theme.spacing(2),
  },
  container: {
    maxHeight: '300px',
    height: '300px',
  },

  rowCounter: {
    fontSize: '0.50rem',
    fontWeight: 'lighter',
  },
  paginationSpacer: {
    display: 'none',
  },
  paginationCaption: {
    display: 'none',
  },
  paginationSelectRoot: {
    marginRight: '2px',
  },
  colorNegative: {
    color: 'red',
  },
  colorPositive: {
    color: 'green',
  },
});

function desc(a, b, orderBy) {
  const av =
    typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const bv =
    typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

  if (bv < av) {
    return -1;
  }
  if (bv > av) {
    return 1;
  }

  // if (b[orderBy] < a[orderBy]) {
  //   return -1;
  // }
  // if (b[orderBy] > a[orderBy]) {
  //   return 1;
  // }
  return 0;
}
function stableSort(array, cmp) {
  if (!cmp) {
    return array;
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getSorting(order, orderBy) {
  if (!orderBy) {
    return null;
  }
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const CustomizedTable = React.memo((props) => {
  const {
    classes,
    records,
    title,
    columns,
    rowClick,
    height,
    perPage,
    pagination,
    showExport,
  } = props;

  const [hasSort, setHasSort] = React.useState(false);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    !pagination ? -1 : perPage
  );

  React.useEffect(() => {
    const sortCol = columns.find((c) => !!c.sort);
    setHasSort(!!sortCol);
    setOrder(sortCol.sort ?? 'desc');
    setOrderBy(sortCol.sort ? sortCol.column : null);
  }, [columns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createCell = (field, value) => {
    if (field.skipDisplay) {
      return <React.Fragment key={field.column} />;
    }
    if (field.image) {
      return (
        <ImageTableCell key={field.column}>
          <img alt="" src={value} />
        </ImageTableCell>
      );
    }

    let cls = '';
    if (field.colorValue) {
      if (value < 0.0) {
        cls = classes.colorNegative;
      } else {
        cls = classes.colorPositive;
      }
    }

    let cellValue = value;
    if (field.digits) {
      cellValue = Number.parseFloat(cellValue).toFixed(field.digits);
    }
    if (field.valueSuffix) {
      cellValue = `${cellValue}${field.valueSuffix}`;
    }
    return (
      <CustomTableCell key={field.column} className={cls}>
        {cellValue}
      </CustomTableCell>
    );
  };

  const createRow = (record, index, startingIndex = 1) => {
    const opts = {
      className: classes.row,
      key: index,
    };

    if (rowClick) {
      opts.style = { cursor: 'pointer' };
      opts.onClick = () => rowClick(record);
    }

    const rowNumber = startingIndex + index + 1;
    return (
      <TableRow {...opts}>
        <CustomTableCell key="rowCounter" className={classes.rowCounter}>
          {rowNumber}
        </CustomTableCell>
        {columns.map((field) => createCell(field, record[field.column]))}
      </TableRow>
    );
  };

  const handleExport = (event, to) => {
    if (records.length < 1) {
      alert('no records to export');
    } else {
      // xls way
      const exportName = `${title.replace(' ', '_').toLowerCase()}.xlsx`;
      const json = [];
      const fields = columns
        .filter((field) => !field.skipExport)
        .map((field) => field);

      stableSort(records, getSorting(order, orderBy)).forEach((r) => {
        const row = {};

        fields.forEach((field) => {
          if (field.valueSuffix) {
            row[field.column] = `${r[field.column]}${field.valueSuffix}`;
          }
          row[field.column] = r[field.column];
        });
        json.push(row);
      });

      const workbook = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(json);
      XLSX.utils.book_append_sheet(workbook, ws, 'jsonsheet');
      XLSX.writeFile(workbook, exportName);
    }
  };

  const createSortHandler = (property) => (event) => {
    if (!hasSort) {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /**
   * createHeaderField
   *
   * Takes into account the field.span (number) definition
   *   If not set, uses 1.
   *
   * This allows a field to skipped in the header, as well as span multiple columns.
   * e.g. [{ name: 'logo' image: true, span: 0 }
   *       { name: 'title' span: 2 }]
   * This would allow cell 1 to be an image, and the second cell to be the title.
   * The title then spans cell 1 and cell 2.
   *
   * @param {*} field The field definition
   */
  const createHeaderField = (field) => {
    if (field.skipDisplay) {
      return <React.Fragment key={field.name} />;
    }

    const colSpan = typeof field.span === 'undefined' ? 1 : field.span;
    if (colSpan === 0) {
      return <React.Fragment key={field.name} />;
    }
    return (
      <CustomTableCell key={field.name} colSpan={colSpan}>
        <TableSortLabel
          active={orderBy === field.column}
          direction={orderBy === field.column ? order : 'asc'}
          onClick={createSortHandler(field.column)}
        >
          {field.name}
        </TableSortLabel>
      </CustomTableCell>
    );
  };

  const containerHeight = {
    height,
    maxHeight: height,
  };

  return (
    <Paper className={classes.root}>
      {showExport || title ? (
        <Toolbar>
          <Typography variant="h4" className={classes.title}>
            <>
              {title}
              {showExport && records.length > 0 ? (
                <IconButton
                  className={classes.exportBtn}
                  onClick={handleExport}
                >
                  <Icon className="icon-download" />
                </IconButton>
              ) : (
                <></>
              )}
            </>
          </Typography>
        </Toolbar>
      ) : (
        ''
      )}
      <TableContainer className={classes.container} style={containerHeight}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell key="rowCnt" />
              {columns.map((field, index) => createHeaderField(field))}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.length < 1 ? (
              <TableRow key="nodata">
                <CustomTableCell colSpan={columns.length} align="center">
                  No Data
                </CustomTableCell>
              </TableRow>
            ) : (
              <></>
            )}
            {stableSort(records, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => createRow(row, index, page * rowsPerPage))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[
            MIN_ROW_COUNT,
            25,
            50,
            { label: 'All', value: -1 },
          ]}
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={() => {}}
          classes={{
            spacer: classes.paginationSpacer,
            selectRoot: classes.paginationSelectRoot,
          }}
        />
      ) : (
        ''
      )}
    </Paper>
  );
});

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(CustomizedTable);

CustomizedTable.propTypes = {
  title: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      column: PropTypes.string,
      valueSuffix: PropTypes.string,
      image: PropTypes.bool,
      skipExport: PropTypes.bool,
      skipDisplay: PropTypes.bool,
      sort: PropTypes.oneOf(['desc', 'asc']),
      span: PropTypes.number, // span for the HEADER only.
      digits: PropTypes.number,
    })
  ).isRequired,
  rowClick: PropTypes.func,
  height: PropTypes.string,
  perPage: PropTypes.number,
  pagination: PropTypes.bool,
  showExport: PropTypes.bool,
};

CustomizedTable.defaultProps = {
  rowClick: null,
  height: '300px',
  perPage: MIN_ROW_COUNT,
  pagination: true,
  showExport: true,
};
