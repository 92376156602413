import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { useCookies } from 'react-cookie';

const cookieName = 'snapshotConsent';
const keepCookieFor = 60 * 60 * 24 * 10; // 10days

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  contentroot: {
    width: '100%',
    padding: '10px',
  },
  position: {
    bottom: 0,
    left: 0,
    right: 0,
    transform: 'none',
  },
  action: {
    marginRight: '0',
  },
}));

export default function CookieConsent(props) {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies([cookieName]);

  const { message, buttonMessage } = props;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setCookie(cookieName, true, { path: '/', sameSite: 'strict', maxAge: keepCookieFor });
    setOpen(false);
  };

  React.useEffect(() => {
    const checkPersistent = async () => {
      if (cookies[cookieName]) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    checkPersistent();
  }, []);


  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        anchorOriginBottomCenter: classes.position,
      }}
      ContentProps={{
        classes: { root: classes.contentroot, action: classes.action },
      }}
      message={message}
      action={(
        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {buttonMessage}
        </Button>
      )}
    />
  );
}

CookieConsent.propTypes = {
  message: PropTypes.string,
  buttonMessage: PropTypes.string,
};

CookieConsent.defaultProps = {
  message: 'This website or its third-party tools process personal data (e.g. browsing data or IP addresses) and use cookies or other identifiers, which are necessary for its functioning. You accept the use of cookies or other identifiers by closing or dismissing this notice, by clicking a link or button or by continuing to browse otherwise.',
  buttonMessage: 'Ok',
};
