/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import history from '../lib/history';
import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import LineChart from '../components/LineChart';
import MapChart from '../components/MapChart';
import DataTable from '../components/DataTable';
import ValueLabel from '../components/ValueLabel';

import useGlobalState from '../hooks/useGlobalState';

import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f8fafb',
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 400,
  },
  brandsBlock: {
    background: 'none',
    border: 'none',
    boxShadow:  'none',
    fontWeight: 400,
  },
}));

const defaultState = {
  topbrands: [],
  topbrandsCols: [
    { name: 'Logo', column: 'image_url', image: true, skipExport: true },
    { name: 'Name', column: 'name' },
    { name: 'Manufacturer Id', column: 'manufacturer_id', skipDisplay: true },
    { name: 'Spec Percent', column: 'spec_percent', valueSuffix: '%', sort: 'desc', colorValue: true, digits: 4 },
    { name: 'Growth (Real)', column: 'spec_growth_real', valueSuffix: '%', colorValue: true, digits: 4 },
    { name: 'Growth (Absolute)', column: 'spec_growth_absolute', valueSuffix: '%', colorValue: true, digits: 4 },
  ],
};

export default function InternalTop() {
  const classes = useStyles();
  const globalState = useGlobalState();

  const [state, setState] = React.useState(defaultState);

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    setState(defaultState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();

    tim.start();

    const filters = cleanFilters(inFilters);

    try {
      EventRecorder('brand/internaltop', filters);

      const stateData = await Promise.all([
        dataToState({ url: '/v2/analytics/internal/topbrands', filters }, 'topbrands'),
      ]);

      tim.finishAPI();

      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }

    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  const topBrandHandler = (row) => {
    history.push(`/brands/drill/${row.manufacturer_id}`);
  };

  React.useEffect(() => {
    document.title = 'Brand Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    // We have to ensure the browser url has the parameters.
    setBrowserUrl(globalState.filters);

    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item className= "brands-block top-brands-block" xs={12} sm={12}>
          <DataTable height="600px" perPage={25} title="Brands List" columns={state.topbrandsCols} records={state.topbrands} rowClick={topBrandHandler} />
        </Grid>
      </Grid>
    </Paper>
  );
}
