/**
 * globalReducer is the reducer for our globalState object.
 *
 * see globalStore and useGlobalState
 */


export const ActionTypes = {
  SET_STATUS: 'SET_STATUS', // Handles the (debug) status text
  SET_PROCESSING: 'SET_PROCESSING', // Handles the Processing Indicator
  SET_ERROR: 'SET_ERROR', // Handles the Error Alert Contents
  SET_API: 'SET_API', // Handles the API handler
  SET_FILTERTYPE: 'SET_FILTERTYPE', // Different pages support different filters
  SET_FILTERS: 'SET_FILTERS', // holds the current set of filters
  SET_USER: 'SET_USER',
  SET_CONFIGURATION: 'SET_CONFIGURATION',
  SET_DEFAULTS: 'SET_DEFAULTS',
};

const globalStateReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROCESSING:
      return { ...state, processing: action.payload };
    case ActionTypes.SET_STATUS:
      return { ...state, status: action.payload };
    case ActionTypes.SET_ERROR:
      return { ...state, errors: action.payload };
    case ActionTypes.SET_API:
      return { ...state, callAPI: action.payload };
    case ActionTypes.SET_FILTERTYPE:
      return { ...state, filterType: action.payload };
    case ActionTypes.SET_FILTERS:
      return { ...state, filters: action.payload };
    case ActionTypes.SET_USER:
      return { ...state, user: action.payload };
    case ActionTypes.SET_CONFIGURATION:
      return { ...state, configuration: action.payload };
    case ActionTypes.SET_DEFAULTS:
      return { ...state, defaults: action.payload };
    default:
      return state;
  }
};

export default globalStateReducer;
