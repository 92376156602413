import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import throttle from 'lodash/throttle';

import resttp from '../lib/resttp';

import useGlobalState from '../hooks/useGlobalState';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 400,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },

    mt20: {
      marginTop: '20px !important',
    },

    filterLine: {
      width: '100%',
      borderBottom: '1px solid #ccc',
      marginTop: '36px',
      marginBottom: '-38px',
    },
  },
}));

const useAutocompleteStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root.Mui-focused': {
      position: 'relative',
      left: 15,
      top: 10,
    },
    '& .MuiFormLabel-root.MuiFormLabel-filled': {
      position: 'relative',
      left: 15,
      top: 10,
    },
  },
  inputRoot: {
    '& .MuiFormLabel-root': {
      color: 'red',
    },
  },
  paper: {
    border: '1px solid #ccc',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ComplexBrand(props) {
  const classes = useStyles();
  const autocompleteClasses = useAutocompleteStyles();
  const { onChange, defaults, multiple } = props;

  const gState = useGlobalState();

  const [currentComplex, setCurrentComplex] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const [complexItems, setComplexItems] = React.useState([]);

  React.useEffect(() => {
    setComplexItems(defaults);
  }, [defaults]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const onChangeAuto = (val) => {
    setCurrentComplex(val);
  };

  const updateComplexRules = (rules) => {
    setComplexItems(rules);
    onChange(rules);
  };

  const handleAddBrand = (event) => {
    // copy value from autocomplete into new row.
    if (!currentComplex) {
      return;
    }

    const newRow = {
      brandId: currentComplex.id,
      name: currentComplex.name,
      categories: [],
      statuses: [],
      join: 'or',
      end: '',
    };
    const curr = complexItems.slice(0);
    curr.push(newRow);
    updateComplexRules(curr);
    setCurrentComplex(null);
  };

  const handleRemoveBrand = (idx) => {
    const curr = complexItems.slice(0);
    curr.splice(idx, 1);
    updateComplexRules(curr);
  };

  const handleToggleJoinChange = (idx) => (event, toggleValue) => {
    const curr = complexItems.slice(0);
    const itm = curr[idx];
    itm.join = toggleValue;
    curr[idx] = itm;
    updateComplexRules(curr);
  };
  const handleToggleEndChange = (idx) => (event, toggleValue) => {
    const curr = complexItems.slice(0);
    const itm = curr[idx];
    itm.end = toggleValue;
    curr[idx] = itm;
    updateComplexRules(curr);
  };

  const handleCatChange = (idx) => (event) => {
    const curr = complexItems.slice(0);
    const itm = curr[idx];
    itm.categories = event.target.value;
    curr[idx] = itm;
    updateComplexRules(curr);
  };

  const selectedCats = (idx) => () => {
    return complexItems[idx].categories;
  };

  const catIdsToNames = (ids) => {
    if (!ids?.length) {
      return ['Category'];
    }
    return ids.map((id) => {
      const rec = gState.configuration.filters.categories.find(
        (r) => r.id === id
      );
      if (rec) {
        return rec.name;
      }
      return '';
    });
  };

  const hasSelectedCat = (idx, categoryId) => {
    const scats = selectedCats(idx)();
    //console.log('the cats', scats);
    return scats.indexOf(categoryId) > -1;
  };

  // proiduct status
  const handleProductStatusChange = (idx) => (event) => {
    const curr = complexItems.slice(0);
    const itm = curr[idx];
    itm.statuses = event.target.value;
    curr[idx] = itm;
    updateComplexRules(curr);
  };

  const selectedProductStatuses = (idx) => () => {
    return complexItems[idx].statuses;
  };
  const productStatusIdsToNames = (ids) => {
    if (!ids?.length) {
      return ['Status'];
    }
    return ids.map((id) => {
      const rec = gState.configuration.filters.productStatus.find(
        (r) => r.id === id
      );
      if (rec) {
        return rec.name;
      }
      return '';
    });
  };

  const hasSelectedProductStatus = (idx, statusId) => {
    const scats = selectedProductStatuses(idx)();
    //console.log('the cats', scats);
    return scats.indexOf(statusId) > -1;
  };

  const fetch = React.useMemo(
    () =>
      throttle((term, callback) => {
        resttp
          .get('/v2/autocomplete/brand/', { params: { term } })
          .then(callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results.data || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <InputLabel id="complex-label">
              Complicated Brand Queries
            </InputLabel>
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Autocomplete
                id="autocomplete-brand-name"
                size="small"
                classes={autocompleteClasses}
                //          autoComplete
                options={options}
                getOptionLabel={(option) => option.name}
                value={currentComplex}
                filterSelectedOptions
                onChange={(e, v) => onChangeAuto(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    label="Brand"
                    placeholder="Brand"
                    fullWidth
                    onChange={handleInputChange}
                    style={{ width: 300 }}
                  />
                )}
              />
              <IconButton
                style={{
                  display: 'inline-block',
                  borderRadius: '16px',
                  boxShadow: 'none',
                  marginLeft: 8,
                }}
                variant="contained"
                color="primary"
                onClick={handleAddBrand}
              >
                <AddIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* {complexItems.length < 1 ? <div>no items</div> : ''} */}
      {complexItems.map((itm, index) => (
        // NOTE this key is actually not correct, since in theory
        // you could specify the same brand more than once
        <React.Fragment key={index}>
          {!!index && (
            <Grid container>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    borderBottom: '1px solid #ccc',
                    position: 'absolute',
                    top: 15,
                    zIndex: 0,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    zIndex: 1,
                  }}
                >
                  <div>
                    <ToggleButtonGroup
                      size="small"
                      value={itm.join}
                      exclusive
                      onChange={handleToggleJoinChange(index)}
                    >
                      <ToggleButton
                        style={{
                          color: itm.join === 'and' ? '#fff' : null,
                          backgroundColor:
                            itm.join === 'and' ? '#1fcdba' : '#fff',
                          borderRadius: '20px',
                          height: '30px',
                          padding: '0 14px',
                          marginRight: '8px',
                        }}
                        key={1}
                        value="and"
                      >
                        AND
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          color: itm.join === 'or' ? '#fff' : null,
                          backgroundColor:
                            itm.join === 'or' ? '#1fcdba' : '#fff',
                          borderRadius: '20px',
                          height: '30px',
                          padding: '0 14px',
                          marginRight: '8px',
                        }}
                        key={2}
                        value="or"
                      >
                        OR
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div>
                    <ToggleButtonGroup
                      color="primary"
                      size="small"
                      value={itm.end}
                      exclusive
                      onChange={handleToggleEndChange(index)}
                    >
                      <ToggleButton
                        style={{
                          color: itm.end === 'end' ? '#fff' : null,
                          backgroundColor:
                            itm.end === 'end' ? '#1fcdba' : '#fff',
                          borderRadius: '20px',
                          height: '30px',
                          padding: '0 14px',
                        }}
                        key={1}
                        value="end"
                      >
                        End/Start
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={5}>
              {itm.name}
            </Grid>
            <Grid item xs={7}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Select
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    padding: '8px 20px',
                    fontSize: '13px',
                    color: '#6e6e6e',
                    width: 180,
                    height: '33px',
                  }}
                  multiple
                  displayEmpty
                  value={selectedCats(index)()}
                  onChange={handleCatChange(index)}
                  input={<Input />}
                  renderValue={(selected) => catIdsToNames(selected).join(', ')}
                  MenuProps={MenuProps}
                >
                  {gState.configuration.filters.categories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      <Checkbox checked={hasSelectedCat(index, cat.id)} />
                      <ListItemText primary={cat.name} />
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: 5,
                    padding: '8px 20px',
                    fontSize: '13px',
                    color: '#6e6e6e',
                    width: 180,
                    height: '33px',
                    marginLeft: 10,
                  }}
                  multiple
                  displayEmpty
                  value={selectedProductStatuses(index)()}
                  onChange={handleProductStatusChange(index)}
                  input={<Input />}
                  renderValue={(selected) =>
                    productStatusIdsToNames(selected).join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {gState.configuration.filters.productStatus.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      <Checkbox
                        checked={hasSelectedProductStatus(index, cat.id)}
                      />
                      <ListItemText primary={cat.name} />
                    </MenuItem>
                  ))}
                </Select>

                <IconButton
                  style={{
                    display: 'inline-block',
                    borderRadius: '100%',
                    boxShadow: 'none',
                    backgroundColor: '#d32f2f',
                    marginLeft: 10,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleRemoveBrand(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </div>
  );
}
