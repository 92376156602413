/* global localStorage */

/**
 * Authentication hook:  Manage all user auth related methods
 *
 */

import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import history from '../lib/history';

const tokenName = 'jwtToken';
const Context = createContext();

function clearJWT() { localStorage.removeItem(tokenName); }
function setJWT(token) { localStorage.setItem(tokenName, token); }

function getUser() {
  const jwt = localStorage.getItem('jwtToken');
  try {
    const user = jwtDecode(jwt);
    return user;
  } catch (e) {
    return null;
  }
}

function useAuthState() {
  const [user, setUser] = useState(getUser());

  React.useEffect(() => {
    const u = getUser();
    setUser(u);
  }, []);

  const isAuthenticated = () => {
    if (user && user.is_active) {
      return true;
    }
    return false;
  };

  const login = (token) => {
    setJWT(token);
    const u = getUser();
    setUser(u);
  };

  const logout = () => {
    clearJWT();
    history.push('/login');
  };

  return {
    user,
    login,
    logout,
    isAuthenticated,
  };
}

export function AuthProvider({ children }) {
  const auth = useAuthState();
  return (
    <Context.Provider value={auth}>
      {children}
    </Context.Provider>
  );
}

export const useAuth = () => useContext(Context);


AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
