import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export const ExportConfirmDialog = ({
  open,
  type,
  csvType,
  onClose,
  onAccept,
}) => {
  const getExportType = () => {
    if (type === 'PDF') {
      return 'Projects';
    }
    if (csvType === 'specs') {
      return 'Specifications';
    }
    return 'Contacts';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Export All {getExportType()} to {type}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Given the number of {getExportType()}, the {type} report can take
          several minutes to generate. Please, don&apos;t close or refresh the
          screen until the {type} is downloaded.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExportConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string,
  csvType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

ExportConfirmDialog.defaultProps = {
  type: 'PDF',
  csvType: 'specs',
};
