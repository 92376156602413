/**
 * useGlobalState is the hooker for our globalState object.
 *
 * see globalReducer and globalStore
 */


import { useContext } from 'react';
import PropTypes from 'prop-types';

import { ActionTypes } from './globalReducer';
import { Context } from './globalStore';


/**
 * This is the main state handler. It provides convenience methods
 * for each dispatch operation.
 */
const useGlobalState = () => {
  const [state, dispatch] = useContext(Context);

  /**
   * @param {boolean} bl Indicator if there is application processing occurring
   */
  const setProcessing = (bl) => {
    dispatch({
      type: ActionTypes.SET_PROCESSING,
      payload: bl,
    });
  };

  /**
   *
   * setStatus: Sets a string to show status of the application.
   * This could/should go away.  Current used for showing timing information
   *
   * @param {string} value A status string, typically used for debugging.
   *
   */
  const setStatus = (value) => {
    dispatch({
      type: ActionTypes.SET_STATUS,
      payload: value,
    });
  };

  /**
   * setErrors allows errors to be queued.
   *
   * The Error Handler listens for changes
   *
   * @param {string|Array<string>} errors The error(s)
   */
  const setErrors = (errors) => {
    const errs = Array.isArray(errors) ? errors : [errors];
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: errs,
    });
  };

  /**
   * setAPI:  Defines the function a component should use to load data from the API
   *
   * This is used by the Filter component, to call down to the
   *   Display component when it needs to load data
   *
   * @param {function} fn The function to call
   */
  const setAPI = (fn) => {
    dispatch({
      type: ActionTypes.SET_API,
      payload: fn,
    });
  };

  /**
   * setFilterType:  Sets the type of filter we want to have.
   *
   * The Display component will set this to alter the look of the Filter component
   *
   * @param {string} typ   A string, see Filter component for values
   */
  const setFilterType = (typ) => {
    dispatch({
      type: ActionTypes.SET_FILTERTYPE,
      payload: typ,
    });
  };

  /**
   * setFilters:  Update the current filter data
   *
   * The Filter component sets and utilizes the filter data
   *
   * @param {Object} filters  The filters to store
   */
  const setFilters = (filters) => {
    dispatch({
      type: ActionTypes.SET_FILTERS,
      payload: filters,
    });
  };


  const setUser = (data) => {
    dispatch({
      type: ActionTypes.SET_USER,
      payload: data,
    });
  };

  const setConfiguration = (data) => {
    dispatch({
      type: ActionTypes.SET_CONFIGURATION,
      payload: data,
    });
  };
  const setDefaults = (data) => {
    dispatch({
      type: ActionTypes.SET_DEFAULTS,
      payload: data,
    });
  };

  /**
   * reset: Call this when you want to reset the various settings.
   *
   * Used when entering a new part of the app.
   */
  const reset = () => {
    setErrors([]);
    setStatus('');
    setAPI(null);
    setFilterType('');
  };

  /**
   * reset: Call this when you want to reset the user.
   *
   * Used when logging in to ensure we don't keep possible old info around
   */
  const resetUser = () => {
    reset();
    setUser({});
  };


  return {
    setProcessing,
    setStatus,
    setErrors,
    setAPI,
    setFilterType,
    reset,
    resetUser,
    setFilters,
    setUser,
    setConfiguration,
    setDefaults,

    status: state.status,
    errors: state.errors,
    callAPI: state.callAPI,
    isProcessing: state.processing,
    filterType: state.filterType,
    filters: state.filters,
    user: state.user,
    configuration: state.configuration,
    defaults: state.defaults,
  };
};

export default useGlobalState;

useGlobalState.propTypes = {
  children: PropTypes.element.isRequired,
};
