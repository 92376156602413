import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import { Divider, Avatar, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import CategorySelect from './CategorySelect';
import BrandSelect from './BrandSelect';
import ComplexBrand from './ComplexBrand';

import {
  defaultFilters,
  getURLFilters,
  setBrowserUrl,
  mergeFilters,
} from '../lib/massage';
import useGlobalState from '../hooks/useGlobalState';
import resttp from '../lib/resttp';

function scrubValues(input, valid) {
  if (!input) {
    return [];
  }
  const nu = input.filter((itm) => valid.includes(itm));
  return nu;
}

const useStyles = makeStyles((theme) => ({
  filterwrapper: {
    flexDirection: 'row !important',
    display: 'flex !important',
    flexWrap: 'wrap !important',
  },
  root: {
    display: 'flex',
    xmargin: theme.spacing(1),
    width: '100%',
  },
  rootpanel: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  dialog: {
    position: 'absolute',
    zIndex: '100000',
    left: '200px',
  },
  dialogcontainer: {
    margin: 0,
    justifyContent: 'left',
  },
  dialogpaper: {
    overflowY: 'visible',
    margin: 0,
    maxWidth: '800px !important',
  },

  filtersummary: {
    position: 'relative',
    width: '100%',
    padding: '20px',
    cursor: 'pointer',
  },

  filterLeft: {
    float: 'left',
    display: 'flex',
    gap: '10px',
  },
  filterRight: {
    marginRight: 0,
    marginLeft: 0,
    textAlign: 'right',
  },

  filterHeader: {
    color: '#1fcdba',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginRight: '5px',
  },

  filterLabel: {
    fontWeight: 600,
    color: '#5d5d5d',
    fontSize: '0.9rem',
  },

  filterLabel2: {
    fontWeight: 400,
    color: '#a5a5a5',
    fontSize: '0.8rem',
    marginRight: '2px',
    marginLeft: '4px',
  },
  filterOpenSpecLabel: {
    fontWeight: 400,
    color: '#a5a5a5',
    fontSize: '0.8rem',
    marginRight: '2px',
    marginLeft: '4px',
    verticalAlign: 'middle',
  },
  filterValue: {
    color: '#1fcdba',
    fontWeight: 600,
    fontSize: '13px',
  },
  filterOpenSpec: {
    color: '#1fcdba',
    fontWeight: 600,
    fontSize: '13px',
    padding: '11px',
  },

  filterDivider: {
    marginTop: '8px !important',
  },

  categoriesFilterWrapper: {
    maxHeight: '200px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    margin: '0 12px',
    padding: '24px 0px 10px 0',
    minHeight: 160,
  },

  categoriesFilterLabel: {
    margin: '10px 0 8px 34px',
  },

  categoriesFilterLabel2: {
    margin: '10px 0 16px 34px',
    fontWeight: 600,
    color: '#000000',
    fontSize: '0.9rem',
  },

  innerCategoriesFilterWrapper: {
    marginTop: '-40px',
  },

  ml20: {
    marginLeft: '20px',
  },

  applyFilterButton: {
    position: 'relative',
    right: '-24px',
    bottom: '-24px',
    marginTop: '-11px',
    borderRadius: '18px',
  },

  segmentLabel: {
    fontFamily: 'icomoon !important',
    content: 'e904',
  },
  segment: {
    fontSize: '30px',
  },
  segmentChecked: {
    color: '#336699',
  },

  actions: {
    overflow: 'visible',
  },

  active: {
    color: '#fff',
    backgroundColor: '#1fcdba',
    textTransform: 'capitalize',
    height: '30px',
    width: '30px',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  inactive: {
    textTransform: 'capitalize',
    color: '#ccc',
    backgroundColor: '#fff',
    border: '1px solid',
    height: '30px',
    width: '30px',
    fontSize: '0.7rem',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  radios: {
    '&': {
      padding: 0,
      marginRight: 3,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  radiosChecked: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },

  sectionGrid: {
    borderBottom: '1px solid #ccc',
    padding: '24px 0px 10px 0',
    minWidth: '760px',
  },

  filterDateLabel: {
    justifyContent: 'flex-end',
    margin: '-10px 0',
  },

  dateRangeItem: {
    margin: '-33px 0 0 -50px',
  },

  dateRangeItem2: {
    margin: '-33px 0 0 -21px;',
  },

  hideAdvanced: {
    textTransform: 'capitalize',
  },
  categoryText: {
    fontSize: '11px',
    color: '#878787',
  },

  itemSize: {
    fontSize: '11px',
  },

  advancedButton: {
    borderRadius: '20px',
    boxShadow: 'none',
  },
  segmentRow: {
    flexWrap: 'nowrap',
  },
  flex: {
    display: 'flex',
  },
  flexAndGrow: {
    display: 'flex',
    flexGrow: 1,
  },
  flexAndCentered: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateRangeLabel: {
    marginRight: 0,
    marginLeft: 0,
    marginTop: 3,
  },
  idsTextField: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      top: -9,
      left: 15,
    },
  }
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
));

const segmentIcons = [];
segmentIcons['Education'] = 'icon-education';
segmentIcons['Workplace'] = 'icon-business';
segmentIcons['Health & Wellness'] = 'icon-health';
segmentIcons['Hospitality'] = 'icon-hotels';
segmentIcons['Infrastructure & Transportation'] = 'icon-travel';
segmentIcons['Residential'] = 'icon-building';
segmentIcons['Retail'] = 'icon-shopping';
segmentIcons['Other'] = 'icon-all-others';

export default function Filter() {
  const classes = useStyles();
  const gState = useGlobalState();

  const [state, setState] = React.useState(defaultFilters);
  const [expanded, setExpanded] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const [filterSummary, setFilterSummary] = React.useState('');
  const nestedCategoriesActive =
    gState.configuration.hasfeature.nestedcategories || false;

  React.useEffect(() => {
    // if there are filters on the url, use those.

    const urlFilters = getURLFilters();
    // scrub invalid data on the url... segments and categories
    urlFilters.segments = scrubValues(
      urlFilters.segments,
      gState.configuration.filters.segments
    );
    urlFilters.categories = scrubValues(
      urlFilters.categories,
      gState.configuration.filters.categories.map((c) => c.id)
    );
    urlFilters.subCategories = scrubValues(
      urlFilters.subCategories,
      gState.configuration.filters.subCategories.map((c) => c.id)
    );

    // need to merge properly... also this is done in more than 1 place.
    // so, here, we have a requirement that CATEGORIES cannot be empty.
    // if they are we MUST use the defaults.
    const theFilters = mergeFilters(gState.defaults.filters, urlFilters);
    //const theFilters = { ...defaultFilters, ...gState.defaults.filters, ...urlFilters };
    gState.setFilters(theFilters);
  }, []);

  // update when state updates
  React.useEffect(() => {
    setState(gState.filters);
    setBrowserUrl(gState.filters, true);
    setFilterSummary(buildFilterSummary());
  }, [gState.filters]);

  const handleDialogOpen = () => {
    setShowDialog(true);
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleChange = (name) => (event) => {
    let value = event.target.value;
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      value = parseInt(value);
    }
    setState({ ...state, [name]: value });
  };

  const handleChangeI = (name) => (event) => {
    setState({ ...state, [name]: parseInt(event.target.value, 10) });
  };

  const handleValues = (name) => (values) => {
    setState({ ...state, [name]: values });
  };

  const handleChecked = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const hasChk = (name) => (item) => state[name]?.includes(item) || false;

  const handleChkChange = (name) => (event) => {
    let curr = state[name];
    const val = parseInt(event.target.value, 10);
    if (!event.target.checked) {
      curr = curr.filter((v) => v !== val);
    } else if (!curr.includes(val)) {
      curr.push(val);
    }
    setState({ ...state, [name]: curr });
  };

  const handleChkChangeS = (name) => (event) => {
    let curr = state[name];
    const val = event.target.value;
    if (!event.target.checked) {
      curr = curr.filter((v) => v !== val);
    } else if (!curr.includes(val)) {
      curr.push(val);
    }
    setState({ ...state, [name]: curr });
  };

  const handleComplex = (complex) => {
    setState({ ...state, complex: complex });
  };

  const handleShowAdvanced = () => {
    setShowAdvanced(true);
  };
  const handleHideAdvanced = () => {
    // Do we delete any of the advanced settings, or leave as they may be set?
    setShowAdvanced(false);
  };

  const handleClick = () => {
    gState.setFilters(state);
    setFilterSummary(buildFilterSummary());

    if (gState.callAPI) {
      console.log('>>>> api', state)
      gState.callAPI(state);
    }
    setExpanded(false);
    handleDialogClose();
  };

  const expander = () => {
    setExpanded(!expanded);
  };

  const buildFilterSummary = () => {
    const regions = state.regions.join(',');
    const segments = state.segments.join(',');
    const projectsNumber = (state.project_ids || '').split(',').filter(id => !!id).length;
    const os = state.openspec ? 'ON' : 'OFF';
    const bod = state.bod ? 'ON' : 'OFF';
    const qs = state.qtyShares ? 'ON' : 'OFF';

    const categories = state.categories
      .map((c) => {
        const i = gState.configuration.filters.categories.find(
          (gc) => gc.id === c
        );
        if (i) {
          return i.name;
        }
        return null;
      })
      .filter((c) => c)
      .join(', ');

    const subCategories = state.subCategories
      .map((c) => {
        const i = gState.configuration.filters.subCategories.find(
          (gc) => gc.id === c
        );
        if (i) {
          return i.name;
        }
        return null;
      })
      .filter((c) => c)
      .join(', ');

    // let projprodstatus = '';
    // if (state.projprodstatus) {
    //   projprodstatus = state.projprodstatus.map(f => f.name).join(',');
    //   if (projprodstatus) {
    //     projprodstatus = `/ ProjProd Status: ${projprodstatus}`;
    //   }
    // }

    return (
      <div>
        <div className={classes.filterLeft}>
          <span className={classes.filterHeader}>filters &gt;</span>
          <div>
            <span className="icon-open-spec spec-icon" />
            <span className={classes.filterLabel2}>OpenSpec</span>
            <span className={classes.filterValue}>{os}</span>
          </div>
          {/*
          <div>
            <span className="icon-open-spec spec-icon" />
            <span className={classes.filterLabel2}>BOD</span>
            <span className={classes.filterValue}>{bod}</span>
          </div>
          */}
          <div>
            <span className="icon-open-spec spec-icon" />
            <span className={classes.filterLabel2}>Quantity Shares</span>
            <span className={classes.filterValue}>{qs}</span>
          </div>
        </div>
        <div className={classes.filterRight}>
          <span className={classes.filterLabel2}>Date range is</span>
          <span
            className={classes.filterValue}
          >{`${state.period_interval} Months`}</span>
          {/* not showing period anymore */}
          <span className={classes.filterLabel2}>Categories are</span>
          <span className={classes.filterValue}>{categories}</span>
          {nestedCategoriesActive && !!subCategories.length && (
            <>
              <span className={classes.filterLabel2}>Sub-Categories are</span>
              <span className={classes.filterValue}>{subCategories}</span>
            </>
          )}
          {regions ? (
            <>
              <span className={classes.filterLabel2}>Region is</span>
              <span className={classes.filterValue}>{regions}</span>
            </>
          ) : (
            ''
          )}
          {segments ? (
            <>
              <span className={classes.filterLabel2}>Segment is</span>
              <span className={classes.filterValue}>{segments}</span>
            </>
          ) : (
            ''
          )}
          {projectsNumber > 0 ? (
            <>
              <span className={classes.filterLabel2}>Projects</span>
              <span className={classes.filterValue}>{projectsNumber}</span>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.filtersummary} onClick={handleDialogOpen}>
        {filterSummary}
        <Divider className={classes.filterDivider} />
      </div>
      <Dialog
        classes={{
          root: classes.dialog,
          container: classes.dialogcontainer,
          paper: classes.dialogpaper,
        }}
        style={{ position: 'absolute', left: '200px' }}
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={showDialog}
        TransitionComponent={Transition}
      >
        <DialogContent style={{ overflowX: 'hidden' }}>
          <Grid container spacing={1} className={classes.sectionGrid}>
            <Grid item xs={12}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <div>
                  <span className="icon-open-spec spec-icon" />
                  <span className={classes.filterOpenSpecLabel}>OpenSpec</span>
                  <FormControlLabel
                    classes={{ label: classes.filterOpenSpec }}
                    control={
                      <Checkbox
                        style={{ display: 'none' }}
                        checked={state.openspec}
                        onChange={handleChecked('openspec')}
                        name="gilad"
                      />
                    }
                    label={state.openspec ? 'ON' : 'OFF'}
                  />
                </div>
                {/*
                <div>
                  <span className="icon-open-spec spec-icon" />
                  <span className={classes.filterOpenSpecLabel}>BOD</span>
                  <FormControlLabel
                    classes={{ label: classes.filterOpenSpec }}
                    control={
                      <Checkbox
                        style={{ display: 'none' }}
                        checked={state.bod}
                        onChange={handleChecked('bod')}
                        name="gilad"
                      />
                    }
                    label={state.bod ? 'ON' : 'OFF'}
                  />
                </div>
                */}
                <div>
                  <span className="icon-open-spec spec-icon" />
                  <span className={classes.filterOpenSpecLabel}>
                    Quantity Shares
                  </span>
                  <FormControlLabel
                    classes={{ label: classes.filterOpenSpec }}
                    control={
                      <Checkbox
                        style={{ display: 'none' }}
                        checked={state.qtyShares}
                        onChange={handleChecked('qtyShares')}
                        name="gilad"
                      />
                    }
                    label={state.qtyShares ? 'ON' : 'OFF'}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <Grid container spacing={1} className={classes.sectionGrid}>
            <Grid item xs={4}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel className={classes.filterLabel} component="legend">Misc</FormLabel>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={<Checkbox checked={state.openspec} onChange={handleChecked('openspec')} />}
                label={<Typography className={classes.categoryText}>{'Open Spec'}</Typography>}
              />
            </Grid>
          </Grid> */}
          <Grid className={classes.sectionGrid} container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.flex}>
                <div className={classes.flexAndGrow}>
                  <div className={classes.flexCol}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormLabel
                        className={classes.filterLabel}
                        component="legend"
                      >
                        Date Range
                      </FormLabel>
                      <FormHelperText
                        component="legend"
                        className={classes.dateRangeLabel}
                      >
                        Last day of
                      </FormHelperText>
                    </FormControl>
                  </div>
                  <div
                    className={classes.flex}
                    style={{ borderRight: '1px solid #ccc', marginRight: 10, paddingRight: 20 }}
                  >
                    <RadioGroup
                      className={classes.filterDateLabel}
                      row
                      aria-label="period_end"
                      name="period_end"
                      value={state.period_end}
                      onChange={handleChange('period_end')}
                    >
                      {gState.configuration.filters.periods.map((row) => (
                        <FormControlLabel
                          style={{ marginLeft: 0, marginRight: 5 }}
                          key={row.id}
                          value={row.id}
                          control={
                            <Radio
                              classes={{
                                root: classes.radios,
                                checked: classes.radiosChecked,
                              }}
                              icon={
                                <Avatar
                                  className={classes.inactive}
                                  color="secondary"
                                  size="small"
                                >
                                  {row.abbr}
                                </Avatar>
                              }
                              checkedIcon={
                                <Avatar
                                  className={classes.active}
                                  color="primary"
                                  size="small"
                                >
                                  {row.abbr}
                                </Avatar>
                              }
                            />
                          }
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </div>
                <div className={classes.flexAndCentered}>
                  <FormHelperText
                    component="legend"
                    className={classes.dateRangeLabel}
                    style={{marginRight: 15}}
                  >
                    Months
                  </FormHelperText>
                  <RadioGroup
                    className={classes.filterDateLabel}
                    row
                    aria-label="period_interval"
                    name="period_interval"
                    value={state.period_interval.toString()}
                    onChange={handleChangeI('period_interval')}
                  >
                    {gState.configuration.filters.intervals.map((row) => (
                      <FormControlLabel
                        key={row}
                        value={row.toString()}
                        style={{ marginLeft: 0, marginRight: 3 }}
                        control={
                          <Radio
                            classes={{
                              root: classes.radios,
                              checked: classes.radiosChecked,
                            }}
                            icon={
                              <Avatar
                                className={classes.inactive}
                                color="secondary"
                                size="small"
                              >
                                {row}
                              </Avatar>
                            }
                            checkedIcon={
                              <Avatar
                                className={classes.active}
                                color="primary"
                                size="small"
                              >
                                {row}
                              </Avatar>
                            }
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={1} className={classes.sectionGrid}>
            <Grid item xs={nestedCategoriesActive ? 6 : 12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel className={classes.filterLabel} component="legend">
                  Categories
                </FormLabel>
              </FormControl>
              <Grid
                className={classes.categoriesFilterWrapper}
                container
                spacing={!nestedCategoriesActive ? 4 : 0}
              >
                <Grid
                  className={classes.innerCategoriesFilterWrapper}
                  item
                  xs={12}
                  sm={12}
                >
                  <FormControl
                    fullWidth
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup className={classes.filterwrapper}>
                      {gState.configuration.filters.categories.map((row) => (
                        <Grid
                          item
                          xs={nestedCategoriesActive ? 12 : 3}
                          sm={nestedCategoriesActive ? 12 : 4}
                          key={row.id}
                        >
                          <FormControlLabel
                            className={classes.itemSize}
                            key={row.id}
                            control={
                              <Checkbox
                                checked={hasChk('categories')(row.id)}
                                onChange={handleChkChange('categories')}
                                value={row.id}
                              />
                            }
                            label={
                              <Typography className={classes.categoryText}>
                                {row.name}
                              </Typography>
                            }
                          />
                        </Grid>
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {nestedCategoriesActive && (
              <Grid item xs={6}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel className={classes.filterLabel} component="legend">
                    Sub-Categories <i>(Nested)</i>
                  </FormLabel>
                </FormControl>
                <Grid
                  className={classes.categoriesFilterWrapper}
                  container
                  style={{ padding: '24px 0px 10px 0', minHeight: 160 }}
                >
                  <Grid
                    className={classes.innerCategoriesFilterWrapper}
                    item
                    xs={12}
                    sm={12}
                  >
                    <FormControl
                      fullWidth
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormGroup className={classes.filterwrapper}>
                        {gState.configuration.filters.subCategories.map(
                          (row) => (
                            <Grid
                              item
                              xs={nestedCategoriesActive ? 12 : 3}
                              sm={nestedCategoriesActive ? 12 : 4}
                              key={row.id}
                            >
                              <FormControlLabel
                                className={classes.itemSize}
                                key={row.id}
                                control={
                                  <Checkbox
                                    checked={hasChk('subCategories')(row.id)}
                                    onChange={handleChkChange('subCategories')}
                                    value={row.id}
                                  />
                                }
                                label={
                                  <Typography className={classes.categoryText}>
                                    {row.name}
                                  </Typography>
                                }
                              />
                            </Grid>
                          )
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          {!gState.configuration.hasfeature.hidesegments && (
            <Grid container spacing={1} direction="column" className={classes.sectionGrid}>
              <Grid container spacing={1} className={classes.sectionGrid}>
                <Grid item xs={3}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel className={classes.filterLabel} component="legend">
                      Project Segment
                    </FormLabel>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <FormGroup row classes={{ row: classes.segmentRow }}>
                    {gState.configuration.filters.segments.map((segment) => (
                      <FormControlLabel
                        className="filter-icon-wrapper"
                        key={segment}
                        control={
                          <Checkbox
                            icon={
                              <Icon
                                className={`${segmentIcons[segment]} segment-icons`}
                              />
                            }
                            checkedIcon={
                              <Icon
                                className={`${segmentIcons[segment]} segment-icons`}
                              />
                            }
                            checked={hasChk('segments')(segment)}
                            onChange={handleChkChangeS('segments')}
                            value={segment}
                            classes={{ checked: classes.segmentChecked }}
                            title={segment}
                          />
                        }
                        classes={{
                          label: classes.segmentLabel,
                        }}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid style={{marginTop: 20, padding: '0 10px'}}>
                <TextField
                  fullWidth
                  id="project-ids"
                  label="Filter By Project IDs"
                  placeholder="Comma-separated Project IDs: 12345, 56789"
                  value={state.project_ids || ''}
                  onChange={handleChange('project_ids')}
                  variant="outlined"
                  size="small"
                  className={classes.idsTextField}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} className={classes.sectionGrid}>
            <Grid item xs={3}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel className={classes.filterLabel} component="legend">
                  Regions
                </FormLabel>
                <FormHelperText>United States</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <FormGroup
                row
                style={{ flexDirection: 'row-reverse', marginRight: '19px' }}
              >
                {gState.configuration.filters.regions.map((row) => (
                  <FormControlLabel
                    key={row}
                    control={
                      <Checkbox
                        checked={hasChk('regions')(row)}
                        onChange={handleChkChangeS('regions')}
                        value={row}
                      />
                    }
                    label={
                      <Typography className={classes.categoryText}>
                        {row}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>

          {gState.filterType === 'specattribute' ? (
            <Grid container spacing={1} xs={12} className={classes.sectionGrid}>
              <Grid className="xfilter-container" item xs={12} sm={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Spec Attribute Id</FormLabel>
                  <TextField
                    id="standard-basic"
                    label="Spec Attribute Id"
                    value={state.spec_attribute_id}
                    onChange={handleChange('spec_attribute_id')}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {gState.filterType === 'category' ? (
            <Grid container spacing={1} xs={12} className={classes.sectionGrid}>
              <Grid className="xfilter-container" item xs={12} sm={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Manufacturers</FormLabel>
                  <FormGroup>
                    1.
                    <BrandSelect
                      defaults={state.category_brand1}
                      onChange={handleValues('category_brand1')}
                    />
                    2.
                    <BrandSelect
                      defaults={state.category_brand2}
                      onChange={handleValues('category_brand2')}
                    />
                    3.
                    <BrandSelect
                      defaults={state.category_brand3}
                      onChange={handleValues('category_brand3')}
                    />
                    4.
                    <BrandSelect
                      defaults={state.category_brand4}
                      onChange={handleValues('category_brand4')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {gState.filterType === 'adjacency' ? (
            <Grid container spacing={1} xs={12} className={classes.sectionGrid}>
              <Grid className="xfilter-container" item xs={12} sm={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">Adjacency</FormLabel>
                  <FormGroup>
                    Anchor Brand.
                    <BrandSelect
                      defaults={state.adjacent_brand}
                      onChange={handleValues('adjacent_brand')}
                    />
                    Adjacent Category.
                    <CategorySelect
                      onChange={handleValues('adjacent_category')}
                      defaults={state.adjacent_category}
                      multiple={false}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <Grid item xs={12} className={classes.sectionGrid}>
            <Button
              className={classes.advancedButton}
              variant={showAdvanced ? 'contained' : 'outlined'}
              color="primary"
              onClick={handleShowAdvanced}
            >
              Advanced Filters
            </Button>
            {showAdvanced ? (
              <Button
                className={classes.hideAdvanced}
                onClick={handleHideAdvanced}
              >
                Cancel
              </Button>
            ) : (
              ''
            )}
          </Grid>

          {showAdvanced ? (
            <Grid item xs={12} className={classes.sectionGrid}>
              <ComplexBrand
                className={classes.filterLabel}
                onChange={handleComplex}
                defaults={state.complex}
              />
            </Grid>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            className={classes.applyFilterButton}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
