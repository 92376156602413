import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
  makeStyles,
  Typography,
  ButtonGroup,
  Button,
  IconButton,
  Icon,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import mapDataUS from './us-all';

const XLSX = require('xlsx');

require('highcharts/modules/map')(Highcharts);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#f8fafb',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
  title: {
    flexGrow: 1,
  },
  selected: {
    color: '#fff !important',
    backgroundColor: '#1fcdba !important',
    borderRadius: '16px',
    border: '#1fcdba',
  },
  rounded: {
    padding: '0 11px',
    borderRadius: '16px',
    height: '26px',
    fontSize: '12px',
  },
}));

export default function MultiMapChart(props) {
  const classes = useStyles();
  const [dataset, setDataset] = React.useState('spec');
  const [title, setTitle] = React.useState('');
  const [state, setState] = React.useState({
    chartOptions: {
      title: {
        text: '',
      },
      colorAxis: {
        min: 0,
        minColor: '#1fcdba',
        maxColor: '#01a891',
        labels: {
          format: '{value}%',
        },
      },
      chart: {
        backgroundColor: '#f8fafb',
      },
      series: [
        {
          mapData: mapDataUS,
          name: 'Score distribution by US State',
          data: Array.from(props.data),
          states: {
            hover: {
              color: '#a4edba',
            },
          },
        },
      ],
      tooltip: {
        valueSuffix: '%',
        valueDecimals: 4,
      },
      exporting: {
        enabled: false,
      },
      chartOptions: [],
    },
  });

  const loadData = () => {
    const chartOpts = {
      series: [{ data: Array.from(props.data[dataset]) }],
    };
    setState((prevState) => ({ ...prevState, ...{ chartOptions: chartOpts } }));
  };

  React.useEffect(() => {
    setTitle(props.title);
    loadData();
  }, [props]);

  const handleExport = () => {
    const exportName = 'multimapchart.xlsx';
    const workbook = XLSX.utils.book_new();

    // we have multiple datasets here...
    Object.keys(props.data).forEach((key) => {
      const data = props.data[key];
      const out = data.map((r) => ({ state: r[0], value: r[1] }));
      const ws = XLSX.utils.json_to_sheet(out);
      XLSX.utils.book_append_sheet(workbook, ws, key);
    });

    XLSX.writeFile(workbook, exportName);
  };

  const switchDataset = (event, newValue) => {
    if (newValue) {
      setDataset(newValue);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [dataset]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="h4" className={classes.title}>
          {title}
          {state.chartOptions.series.length > 0 ? (
            <IconButton className={classes.exportBtn} onClick={handleExport}>
              <Icon className="icon-download" />
            </IconButton>
          ) : (
            <></>
          )}
        </Typography>
        <ToggleButtonGroup
          style={{ background: 'none' }}
          size="small"
          value={dataset}
          exclusive
          onChange={switchDataset}
        >
          <ToggleButton
            className={classes.rounded}
            classes={{ selected: classes.selected }}
            color="primary"
            variant="contained"
            value="search"
          >
            Search
          </ToggleButton>
          <ToggleButton
            className={classes.rounded}
            classes={{ selected: classes.selected }}
            color="primary"
            variant="contained"
            value="spec"
          >
            Spec
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
      <HighchartsReact
        highcharts={Highcharts}
        options={state.chartOptions}
        constructorType="mapChart"
      />
    </Paper>
  );
}

MultiMapChart.propTypes = {
  data: PropTypes.shape({
    spec: PropTypes.arrayOf(PropTypes.array).isRequired,
    search: PropTypes.arrayOf(PropTypes.array).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
