import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, Icon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import GridOnIcon from '@material-ui/icons/GridOn';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const XLSX = require('xlsx');

const MIN_ROW_COUNT = 10;

const CustomTableCell = withStyles((theme) => ({
  head: {
    xbackgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ImageTableCell = withStyles((theme) => ({
  head: {
    xbackgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
  root: {
    '& img': {
      maxWidth: '100px',
      maxHeight: '100px',
    },
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  title: {
    flexGrow: 1,
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
  table: {
    minWidth: '700px',
    backgroundColor: '#f5f5f5',
  },
  subtable: {
    minWidth: '700px',
    backgroundColor: '#f5f5f5',
    '& tr:nth-of-type(odd)': {
      backgroundColor: 'transparent !important',
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f5f5',
    },
  },
  margin: {
    margin: theme.spacing(2),
  },
  container: {
    maxHeight: '300px',
    height: '300px',
  },

  rowCounter: {
    fontSize: '0.50rem',
    fontWeight: 'lighter',
  },
  showSubRow: {
    display: 'table-row',
  },
  hideSubRow: {
    display: 'none',
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  if (!cmp) {
    return array;
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function getSorting(order, orderBy) {
  if (!orderBy) {
    return null;
  }
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const CustomizedTable = React.memo((props) => {
  const {
    classes,
    records,
    subData,
    title,
    columns,
    rowClick,
    height,
    perPage,
    pagination,
    showExport,
    onPdfDownload,
    onCsvDownload,
    loading,
  } = props;

  const [openRecord, setOpenRecord] = React.useState(0);
  const [openSubData, setOpenSubData] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openPrincipals, setOpenPrincipals] = React.useState(false);

  const handleProductClick = () => {
    setOpenProducts(!openProducts);
  };
  const handlePrincipalClick = () => {
    setOpenPrincipals(!openPrincipals);
  };

  const sortCol = columns.find((c) => c.sort);

  const [order, setOrder] = React.useState(sortCol ? sortCol.sort : 'desc');
  const [orderBy, setOrderBy] = React.useState(sortCol ? sortCol.column : null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    !pagination ? -1 : perPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePdfClick = (record) => {
    if (record) {
      onPdfDownload && onPdfDownload(record);
    }
  };

  const handleCsvClick = (record, type) => {
    if (record) {
      onCsvDownload && onCsvDownload(record, type);
    }
  };

  const createCell = (field, value, record) => {
    if (field.print) {
      return (
        <CustomTableCell key="print">
          <IconButton onClick={() => handlePdfClick(record)} color="secondary">
            <PictureAsPdf />
          </IconButton>
          <Tooltip title="Specs" placement="top">
            <IconButton
              onClick={() => handleCsvClick(record, 'specs')}
              color="secondary"
            >
              <GridOnIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Contacts" placement="top">
            <IconButton
              onClick={() => handleCsvClick(record, 'contacts')}
              color="secondary"
            >
              <GridOnIcon />
            </IconButton>
          </Tooltip>
        </CustomTableCell>
      );
    }

    if (field.image) {
      return (
        <ImageTableCell key={field.column}>
          <img alt="" src={value} />
        </ImageTableCell>
      );
    }

    let cellValue = value;
    if (field.digits) {
      cellValue = Number.parseFloat(cellValue).toFixed(field.digits);
    }
    if (field.valueSuffix) {
      cellValue = `${cellValue}${field.valueSuffix}`;
    }
    return <CustomTableCell key={field.column}>{cellValue}</CustomTableCell>;
  };

  const createRow = (record, index, startingIndex = 1) => {
    const opts = {
      className: classes.row,
      key: index,
    };

    if (rowClick) {
      opts.style = { cursor: 'pointer' };
      opts.onClick = () => {
        if (openRecord === record.id) {
          // closing the project row....
          setOpenSubData(false);
          setOpenRecord(0);
          setOpenProducts(false);
          setOpenPrincipals(false);
        } else {
          setOpenSubData(true);
          setOpenRecord(record.id);
          setOpenProducts(false);
          setOpenPrincipals(false);
          rowClick(record);
        }
      };
    }

    let subrecord = <></>;
    if (loading === record.id) {
      subrecord = (
        <TableRow
          className={openSubData ? classes.showSubRow : classes.hideSubRow}
        >
          <TableCell align="center" colSpan={6}>
            <CircularProgress color="primary" />
          </TableCell>
        </TableRow>
      );
    }
    if (!loading && subData.projectId === record.id) {
      subrecord = (
        <TableRow
          className={openSubData ? classes.showSubRow : classes.hideSubRow}
        >
          <TableCell padding="none" colSpan={10}>
            <Table stickyHeader className={(classes.table, 'expotical')}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" padding="checkbox">
                    &nbsp;
                  </TableCell>
                  <TableCell size="small" padding="checkbox">
                    &nbsp;
                  </TableCell>
                  <TableCell
                    size="small"
                    padding="checkbox"
                    onClick={handleProductClick}
                  >
                    <IconButton color="primary" aria-label="expand">
                      {openProducts ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </TableCell>
                  <CustomTableCell size="small">
                    Products ({subData.products.length})
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  className={
                    openProducts ? classes.showSubRow : classes.hideSubRow
                  }
                >
                  <TableCell>&nbsp;</TableCell>
                  <TableCell colSpan={3}>
                    <Table className={classes.subtable}>
                      <TableBody>
                        {subData.products.map((product) => (
                          <TableRow key={product.product_id}>
                            <TableCell>&nbsp;</TableCell>
                            <ImageTableCell>
                              <img alt="" src={product.product_image_url} />
                            </ImageTableCell>
                            <CustomTableCell>{product.name}</CustomTableCell>
                            {/* <CustomTableCell>{product.product_id}</CustomTableCell> */}
                            <CustomTableCell>
                              by {product.manufacturer_name}
                            </CustomTableCell>
                            <TableCell>{product.statusText}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="checkbox">
                    &nbsp;
                  </TableCell>
                  <TableCell size="small" padding="checkbox">
                    &nbsp;
                  </TableCell>
                  <TableCell
                    size="small"
                    padding="checkbox"
                    onClick={handlePrincipalClick}
                    key={'xxx'}
                  >
                    <IconButton color="primary" aria-label="expand">
                      {openPrincipals ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                  </TableCell>
                  <CustomTableCell>
                    Principals ({subData.principals.length})
                  </CustomTableCell>
                </TableRow>
                <TableRow
                  className={
                    openPrincipals ? classes.showSubRow : classes.hideSubRow
                  }
                >
                  <TableCell>&nbsp;</TableCell>
                  <TableCell colSpan={4}>
                    <Table className={classes.subtable}>
                      <TableBody>
                        {subData.principals.map((principal) => (
                          <TableRow key={principal.principal_id}>
                            <TableCell>&nbsp;</TableCell>
                            <CustomTableCell>
                              {principal.type_name}
                            </CustomTableCell>
                            <CustomTableCell>
                              {principal.company_name}
                            </CustomTableCell>
                            <CustomTableCell>
                              {principal.city_str}
                            </CustomTableCell>
                            <CustomTableCell>
                              {principal.state_abbr}
                            </CustomTableCell>
                            <CustomTableCell>
                              {principal.first_name}
                            </CustomTableCell>
                            <CustomTableCell>
                              {principal.last_name}
                            </CustomTableCell>
                            <CustomTableCell>{principal.phone}</CustomTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    }
    const rowNumber = startingIndex + index + 1;
    return (
      <React.Fragment key={index}>
        <TableRow>
          <CustomTableCell
            size="small"
            padding="checkbox"
            key="rowCounter"
            className={classes.rowCounter}
          >
            {rowNumber}
          </CustomTableCell>
          <CustomTableCell size="small" padding="checkbox" key="rowtoggle">
            <IconButton color="primary" aria-label="expand" {...opts}>
              {openRecord === record.id ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </CustomTableCell>
          {columns.map((field) =>
            createCell(field, record[field.column], record)
          )}
        </TableRow>
        {subrecord}
      </React.Fragment>
    );
  };

  const handleExport = (event, to) => {
    if (records.length < 1) {
      alert('no records to export');
    } else {
      // xls way
      const exportName = `${title.replace(' ', '_').toLowerCase()}.xlsx`;
      const json = [];
      const fields = columns
        .filter((field) => !field.skipExport)
        .map((field) => field);

      stableSort(records, getSorting(order, orderBy)).forEach((r) => {
        const row = {};

        fields.forEach((field) => {
          if (field.valueSuffix) {
            row[field.column] = `${r[field.column]}${field.valueSuffix}`;
          }
          row[field.column] = r[field.column];
        });
        json.push(row);
      });

      const workbook = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(json);
      XLSX.utils.book_append_sheet(workbook, ws, 'jsonsheet');
      XLSX.writeFile(workbook, exportName);
    }
  };

  const createSortHandler = (property) => (event) => {
    if (!sortCol) {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const containerHeight = {
    height,
    maxHeight: height,
  };

  return (
    <Paper className={classes.root}>
      {showExport || title ? (
        <Toolbar>
          <Typography variant="h4" className={classes.title}>
            <>
              {title}
              {showExport && records.length > 0 ? (
                <IconButton
                  className={classes.exportBtn}
                  onClick={handleExport}
                >
                  <Icon className="icon-download" />
                </IconButton>
              ) : (
                <></>
              )}
            </>
          </Typography>
        </Toolbar>
      ) : (
        ''
      )}
      <TableContainer className={classes.container} style={containerHeight}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell key="rowCnt" />
              <CustomTableCell key="icon" />
              {columns.map((field, index) => (
                <CustomTableCell key={field.name}>
                  <TableSortLabel
                    active={orderBy === field.column}
                    direction={orderBy === field.column ? order : 'asc'}
                    onClick={createSortHandler(field.column)}
                  >
                    {field.name}
                  </TableSortLabel>
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.length < 1 ? (
              <TableRow key="nodata">
                <CustomTableCell colSpan={columns.length} align="center">
                  No Data
                </CustomTableCell>
              </TableRow>
            ) : (
              <></>
            )}
            {stableSort(records, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => createRow(row, index, page * rowsPerPage))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[
            MIN_ROW_COUNT,
            25,
            50,
            { label: 'All', value: -1 },
          ]}
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : (
        ''
      )}
    </Paper>
  );
});

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(CustomizedTable);

CustomizedTable.propTypes = {
  title: PropTypes.string,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      column: PropTypes.string,
      valueSuffix: PropTypes.string,
      image: PropTypes.bool,
      skipExport: PropTypes.bool,
      sort: PropTypes.oneOf(['desc', 'asc']),
    })
  ).isRequired,
  rowClick: PropTypes.func,
  height: PropTypes.string,
  perPage: PropTypes.number,
  pagination: PropTypes.bool,
  showExport: PropTypes.bool,
  onPdfDownload: PropTypes.func,
  onCsvDownload: PropTypes.func,
  loading: PropTypes.number,
};

CustomizedTable.defaultProps = {
  title: '',
  rowClick: null,
  height: '300px',
  perPage: MIN_ROW_COUNT,
  pagination: true,
  showExport: true,
  onPdfDownload: () => {},
  onCsvDownload: () => {},
  loading: null,
};
