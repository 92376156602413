import { createMuiTheme } from '@material-ui/core/styles';

const analyticsTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1fcdba',
      contrastText: '#fff',
    },
    secondary: {
      main: '#332211',
      contrastText: '#fff',
    },
    background: {
      default: '#f8fafb',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Courier', 'Helvetica'],
    fontSize: 14,
  },
});

export default analyticsTheme;
