
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    height: '100vh',
  },
}));

export default function LayoutPublic(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {children}
    </div>
  );
}


LayoutPublic.propTypes = {
  children: PropTypes.element.isRequired,
};
