
import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography } from '@material-ui/core';

import { useAuth } from '../hooks/useAuth';


const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflowX: 'auto',
  },
  logo: {
    width: '100%',
    xheight: '100%',
    padding: '20px',
  },
  bottomNav: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
  },
}));


export default function LayoutAdmin(props) {
  const classes = useStyles();
  const { children } = props;
  const auth = useAuth();

  if (!auth.user.is_admin) {
    return (<Redirect to={{ pathname: '/login' }} />);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Link to="/">&lt; Back to Application</Link>
        <Typography variant="h3">Design Analytics Administration</Typography>

        {children}
      </main>
    </div>
  );
}

LayoutAdmin.propTypes = {
  children: PropTypes.element.isRequired,
};
