/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import DataTable from '../components/DataTable';
import ValueLabel from '../components/ValueLabel';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default function Overview() {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState({
    overview: [],
    stats: {
      total: 0,
      cnt: 0,
      withpct: 0,
      withoutpct: 0,
      cat: '',
      second: '',
    },
    loadTime: '∞',

    brandId: 0,
    brandName: 'Loading Brand !!FIXME!!', // TODO: fix this in setup

    categoryColumns: [
      { name: 'Manufacturer Id', column: 'manufacturer_id' },
      { name: 'Name', column: 'name' },
      { name: 'Count', column: 'cnt' },
      { name: 'Pct', column: 'pct', sort: 'desc', digits: 4 },
    ],
  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const ensureFilters = (filters) => {
    if (!(filters.adjacent_category) || filters.adjacent_brand < 1) {
      globalState.setErrors(['Please set anchor brand and adjacent category']);
      return false;
    }
    return true;
  };

  const callAll = async (inFilters) => {
    globalState.setFilterType('adjacency');
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();
    tim.start();

    const filters = cleanFilters(inFilters);

    if (!ensureFilters(filters)) {
      return;
    }


    const brandId = filters.adjacent_brand;

    try {
      EventRecorder('adjacency/overview', { brandId, filters });

      const stateData = await Promise.all([
       // dataToState({ url: `/v2/analytics/brand/${brandId}/name` }, 'brandName'),
        dataToState({ url: `/v2/analytics/adjacency/${brandId}/breakdown/`, filters }, resp => ({
          overview: resp.data,
          stats: resp.stats,
        })),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      document.title = `Adjacency Drilldown : ${state.brandName}`;

      globalState.reset();
      globalState.setAPI(callAll);

      // We have to ensure the browser url has the parameters.
      setBrowserUrl(globalState.filters);

      callAll(globalState.filters);
    };

    fetchData();
  }, []);

  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <ValueLabel title={`Projects with ${state.stats.cat} by ${state.brandName}`} value={state.stats.cnt} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ValueLabel title={`Projects with ${state.stats.cat} by ${state.brandName} w/o ${state.stats.second}`} value={state.stats.withoutpct} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ValueLabel title={`Projects with ${state.stats.cat} by ${state.brandName} w/ ${state.stats.second}`} value={state.stats.withpct} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataTable title="Adjacency" height="600px" columns={state.categoryColumns} records={state.overview} defaultSort={{ dir: 'desc', column: 'grp_score' }} />
        </Grid>
      </Grid>
    </Paper>
  );
}
