import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { makeStyles, Button, IconButton, Icon, Toolbar } from '@material-ui/core';

const XLSX = require('xlsx');

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#f8fafb',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
}));

export default function ColumnChart(props) {
  const classes = useStyles();
  const [title, setTitle] = React.useState('');
  const [state, setState] = React.useState({
    chartOptions: {
      type: 'column',
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: props.yTitle,
        },
      },
      chart: {
        backgroundColor: '#f8fafb',
      },
      plotOptions: {
        series: {
          type: 'column',
        },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 4,
      },
      series: [],
    },
  });

  React.useEffect(() => {
    const setup = async () => {
      const chartOpts = {
        type: 'column',
        series: props.data,
        colors: ['#1fcdba'],
        xAxis: { categories: props.labels },
      };

      setState(prevState => ({ ...prevState, ...{ chartOptions: chartOpts } }));
      setTitle(props.title);
    };
    setup();
  }, [props]);

  const handleExport = () => {
    const data = state.chartOptions.series[0].data;
    const out = [];

    for (let i = 0; i < data.length; i++) {
      out.push({ name: i, value: data[i] });
    }

    const exportName = 'columnchart.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(out);
    XLSX.utils.book_append_sheet(workbook, ws, 'dataset');
    XLSX.writeFile(workbook, exportName);
  };


  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant='h4'>
          {title}
          {(state.chartOptions.series.length > 0) ? (
            <IconButton className={classes.exportBtn} onClick={handleExport}>
              <Icon className="icon-download" />
            </IconButton>
          ) : <></> }
        </Typography>
      </Toolbar>
      <HighchartsReact
        highcharts={Highcharts}
        options={state.chartOptions}
      />
    </Paper>
  );
}

ColumnChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  title: PropTypes.string.isRequired,
  yTitle: PropTypes.string.isRequired,
};
