import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import path from 'path';

import resttp from '../lib/resttp';
import history from '../lib/history';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },

  drilldownWrapper: {
    padding: '20px',
  },

  drilldownText: {
    color: '#828282',
  },

  greenText: {
    color: '#1fcdba',
  },

  drilldownTitle: {
    fontWeight: 100,
    fontSize: '1.7rem',
  },
  acRoot: {
    backgroundColor: '#e3e7ea',
  },
}));

export default function Brand() {
  const classes = useStyles();

  const loc = useLocation();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  // TODO: handle errors
  const fetch = React.useMemo(
    () => throttle((term, callback) => {
      resttp.get('/v2/autocomplete/brand/', { params: { term, starts: true, full: true } }).then(callback);
    }, 200),
    [],
  );
  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results.data || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);


  const handleBrandPick = (data) => {
    const goto = path.join(loc.pathname, data.id.toString());
    history.push(goto);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className={classes.drilldownWrapper}>
      <Typography className={classes.drilldownTitle} variant="h3" gutterBottom>
        How Brand Drilldown Works?
      </Typography>

      <Typography className={classes.drilldownText} variant="body1" gutterBottom>
        Provide a brand name or ID below to load page.
        <br />
        If the brand is not listed, please
        <b className={classes.greenText}> let us know</b>
      </Typography>
      <br />
      <br />
      <br />
      <Autocomplete
        classes={{
          listbox: classes.acRoot,
        }}
        size="small"
        autoComplete
        options={options}
        getOptionLabel={option => option.name}
        filterSelectedOptions
        onChange={(e, v) => handleBrandPick(v)}
        popupIcon={null}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Brand"
            placeholder="Brand"
            fullWidth
            onChange={handleInputChange}
            style={{ borderRadius: '0' }}
          />
        )}
        renderOption={(option, { selected }) => (
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography>{option.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              {option.image ? (
                <img src={option.image} width={48} height={48} alt="Product" />
              ) : ''}
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
}
