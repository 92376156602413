import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import HighchartsExporting from 'highcharts/modules/exporting';
import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}

const defaultOptions = {
  title: {
    text: '',
  },
  chart: {
    type: 'pie',
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      colors: [
        '#0b6349',
        '#15896c',
        '#22b49c',
        '#2cd7c6',
        '#77e9eb',
        '#b9d4da',
      ],
    },
  },
  series: [
    {
      name: 'Market Segments',
      data: [],
    },
  ],
  legend: {
    floating: false,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'middle',
    itemMarginTop: 10,
    itemMarginBottom: 10,
    labelFormatter: function () {
      return `${this.name} (${parseFloat(this.y).toFixed(1)})%`;
    },
  },
  tooltip: {
    formatter: function () {
      return `${this.point.name} (${parseFloat(this.y).toFixed(1)})%`;
    },
  },

  exporting: {
    scale: 1.5,
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
    chartOptions: {
      chart: {
        events: {
          load: function () {
            this.renderer
              .image(
                'https://access.analytics.design/design_analytics_logo.png',
                10,
                340,
                100,
                50
              )
              .add();
          },
        },
      },
      title: {
        text: 'Distribution of Projects',
      },
      subtitle: {
        text: '',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.point.name} (${parseFloat(this.y).toFixed(1)})%`;
            },
          },
          showInLegend: false,
        },
      },
    },
  },
};

const MarketSegmentsPieChart = ({ data, categories, onChartRefUpdate }) => {
  const [options, setOptions] = useState(defaultOptions);

  const chartComponentRef = useRef();

  const setChartOptions = (newData, newCategories) => {
    const series = newData.map((market) => {
      return {
        name: market.name,
        y: market.percentage,
      };
    });

    const newOptions = { ...defaultOptions };
    newOptions.series[0].data = series;
    newOptions.exporting.chartOptions.subtitle.text = (
      newCategories || []
    ).join(',');
    setOptions(newOptions);
  };

  useEffect(() => {
    setChartOptions(data, categories);
  }, [data, categories]);

  useEffect(() => {
    onChartRefUpdate(chartComponentRef?.current?.chart);
  }, [chartComponentRef]);

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <PieChart
        allowChartUpdate
        ref={chartComponentRef}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

MarketSegmentsPieChart.defaultProps = {
  categories: [],
  onChartRefUpdate: () => {},
};

MarketSegmentsPieChart.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    })
  ).isRequired,
  onChartRefUpdate: PropTypes.func,
};

export default MarketSegmentsPieChart;
