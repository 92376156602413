import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth } from '../hooks/useAuth';
import useGlobalState from '../hooks/useGlobalState';
import history from '../lib/history';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatar: {
    marginRight: '8px',
    cursor: 'pointer',
  },
  impersonate: {
    backgroundColor: 'red',
  },
  username: {
    fontSize: '0.875rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
}));

export default function UserDisplay() {
  const classes = useStyles();
  const auth = useAuth();
  const globalState = useGlobalState();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const gotoMyBrands = () => {
    history.push('/mybrands/show');
  };

  const fullName = () => `${globalState.user.first_name} ${globalState.user.last_name}`;
  const initials = () => `${globalState.user.first_name[0]}${globalState.user.last_name[0]}`;

  let cls = null;
  if (auth.user.is_impersonate) {
    cls = classes.impersonate;
  }
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" className={cls}>
      <Box p={1}>
        <Typography className={classes.username}>
          {fullName()}
        </Typography>
      </Box>
      <Box className={classes.avatar}>
        <Tooltip title="Settings /Logout">
          <Avatar
            alt={fullName()}
            src={globalState.user.avatar}
            className={classes.small}
            onClick={handleClick}
          >
            {initials()}
          </Avatar>
        </Tooltip>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {auth.user.is_admin ? (
            <MenuItem component={Link} to="/admin">Administration</MenuItem>
          ) : ''}
          {auth.user.is_impersonate ? (
            <MenuItem onClick={auth.logout}>DeImpersonate</MenuItem>
          ) : ''}
          <MenuItem onClick={auth.logout}>Logout</MenuItem>
        </Menu>
      </Box>
      <Box className={classes.avatar}>
        {(globalState.user.permissions.mybrands) ? (
          <Link onClick={gotoMyBrands} to="/mybrands/show">
            <Tooltip title="Show My Brands">
              <Avatar
                alt={globalState.user.firm_name}
                src={globalState.user.firm_avatar}
                className={classes.small}
              />
            </Tooltip>
          </Link>
        ) : (
          <Avatar
            alt={globalState.user.firm_name}
            src={globalState.user.firm_avatar}
            className={classes.small}
          />
        )}
      </Box>
    </Box>
  );
}
