import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Tooltip, Typography, Box } from '@material-ui/core';

const lockStyle = makeStyles((theme) => ({
  lockIcon: {
    color: '#ff6400',
    fontSize: '40px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    minWidth: '100%',
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    left: '-20px',
  },
  title: {
    fontSize: '.7rem',
    fontWeight: 'bold',
  },
  caption: {
    fontSize: '.7rem',
  },
}));

export default function LockedTooltip(props) {
  const classes = lockStyle();
  const { locked, children } = props;
  if (!locked) {
    return (<>{children}</>);
  }
  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      placement="right"
      arrow
      interactive
      title={(
        <Box display="flex" flexDirection="row" m={1}>
          <Box mx="auto" my="auto">
            <LockIcon className={classes.lockIcon} />
          </Box>
          <Box mx="auto" my="auto">
            <Typography variant="body1" classes={{ root: classes.title }}>
              Your access to this feature is locked
            </Typography>
            <Typography variant="body1" classes={{ root: classes.caption }}>
              Please
              {' '}
              <b>contact us</b>
              {' '}
              to unlock it.
            </Typography>
          </Box>
        </Box>
      )}
    >
      {children}
    </Tooltip>
  );
}

LockedTooltip.propTypes = {
  locked: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LockedTooltip.defaultProps = {
  locked: false,
};
