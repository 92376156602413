import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
  },
  value: {
    fontSize: 48,
    textAlign: 'center',
    color: '#333',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'lighter',
    color: 'rgba(58, 66, 69, 0.5)',
  },
});


const CustomizedTable = React.memo((props) => {
  const { classes, value, title } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.value} color="textSecondary">
          {value}
        </Typography>
        <Typography className={classes.title}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
});

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default withStyles(styles)(CustomizedTable);
