/* global document */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import logo from '../components/BCI_logo.png';
import logoHorizontal from '../components/DA_logo_horizontal.svg';
import resttp from '../lib/resttp';
import history from '../lib/history';
import { useAuth } from '../hooks/useAuth';
import useGlobalState from '../hooks/useGlobalState';
import EventRecorder, { BrowserInfo } from '../lib/eventRecorder';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px 45px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: 'red',
  },
  logo: {
    maxWidth: '210px',
    margin: '0 auto',
    display: 'flex',
    marginTop: '20%',
  },
  inputElement: {
    height: '27px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  hr: {
    border: '0',
    borderTop: '2px solid #eee',
    height: '1px',
    margin: '10px auto 20px auto',
  },
  poweredBy: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    color: '#ccc',
    fontSize: '12px',
    fontStyle: 'italic',
  },
}));

export default function Login() {
  const classes = useStyles();
  const [message, setMessage] = React.useState();
  const [state, setState] = React.useState({ email: '', password: '' });
  const globalState = useGlobalState();
  const auth = useAuth();
  const onChange = (e) => {
    state[e.target.name] = e.target.value;
    setState(state);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = state;
    const result = await resttp.login(email, password);
    if (!result.success) {
      setMessage(result.response);
    } else {
      globalState.resetUser();
      setMessage('');
      auth.login(result.response.token);
      EventRecorder('login', BrowserInfo());
      history.push('/');
    }
  };

  document.title = 'Login';

  return (
    <React.Fragment>
      <CssBaseline />

      <div className="login-el">
        <main
          className={classes.layout}
          style={{ margin: '0 auto', width: '100%' }}
        >
          <img
            className={classes.logo}
            src={logo}
            alt="Design Analtyics Logo"
          />
          <Paper className={classes.paper}>
            <form className={classes.form} onSubmit={onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  style={{
                    height: '27px !important',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={onChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  style={{
                    height: '27px !important',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={onChange}
                />
              </FormControl>
              <Typography component="h1" variant="h5" className={classes.error}>
                {message}
              </Typography>
              <Button
                style={{ height: '48px', boxShadow: 'none' }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign in
              </Button>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <hr className={classes.hr} />
              <div className={classes.poweredBy}>
                <span>Powered by</span>
                <img src={logoHorizontal} alt="Design Analtyics Logo" />
              </div>
            </form>
          </Paper>
        </main>
      </div>
    </React.Fragment>
  );
}
