/* global document, alert */
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, FormLabel, FormHelperText } from '@material-ui/core';

import history from '../lib/history';
import resttp from '../lib/resttp';
import DataTable from '../components/DataTable';
import { useAuth } from '../hooks/useAuth';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
  container: {
    maxHeight: '300px',
    height: '300px',
    overflow: 'auto',
  },
  list: {
    margin: '10px',
    columnCount: 4,
  },
}));

export default function Admin() {
  const classes = useStyles();
  const textInput = useRef(null);
  const userInput = useRef(null);
  const auth = useAuth();

  const [state, setState] = React.useState({
    topbrands: [],
    topproducts: [],
    firmlist: [],
    columns: [
      { name: 'FirmId', column: 'firm_id', sort: 'asc' },
      { name: 'Name', column: 'name' },
      { name: 'Brands Enabled', column: 'has_brands' },
      { name: 'Products Enabled', column: 'has_products' },
      { name: 'Internal Enabled', column: 'has_internal' },
    ],

  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      document.title = 'Firm Administration';

      const res = await resttp.get('/v2/admin/firmlist', {});
      if (res.error) {
        alert('No admin access');
        history.push('/');
      } else {
        updateState({ firmlist: res.data });
      }
    };

    fetchData();
  }, []);

  const showFirmHandler = (record) => {
    history.push(`/admin/${record.firm_id}`);
  };

  const handleAddFirm = () => {
    const firmIdStr = textInput.current.value;
    const firmId = parseInt(firmIdStr, 10).toString();
    if (firmId.toString() === firmIdStr) {
      if (firmId !== 2 && firmId !== 0) {
        history.push(`/admin/${textInput.current.value}`);
      }
    }
  };

  const handleImpersonateUser = async () => {
    const userIdStr = userInput.current.value;
    const userId = parseInt(userIdStr, 10).toString();
    if (userId.toString() === userIdStr) {
      const url = '/v2/admin/impersonate';
      const result = await resttp.post(url, { userId });
      if (!result.data.user) {
        alert('Failed to Impersonate User');
        console.log('resp', result);
      } else {
        auth.login(result.data.token);
        window.location = '/';
      }
    } else {
      alert('Failed to Impersonate User - Bad Data Entry?');
      console.log('bad data entry');
    }
  };

  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormLabel component="legend">Impersonate A User</FormLabel>
          <FormHelperText>
            Enter the user id to impersonate
          </FormHelperText>
          <TextField
            inputRef={userInput}
            className={classes.input}
            placeholder="enter user id"
            inputProps={{ 'aria-label': 'enter user id' }}
            variant="outlined"
            shrink
          />
          <Button variant="contained" onClick={handleImpersonateUser}>Impersonate User</Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button variant="contained" onClick={() => history.push('/admin/sessions')}>View Session Activity</Button> 
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormLabel component="legend">Add a new firm</FormLabel>
          <FormHelperText>
            Enter the firm id to configure the settings
          </FormHelperText>
          <TextField
            inputRef={textInput}
            className={classes.input}
            placeholder="enter firm id"
            inputProps={{ 'aria-label': 'enter firm id' }}
            variant="outlined"
            shrink
          />
          <Button variant="contained" onClick={handleAddFirm}>Add New Firm</Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormLabel component="legend">Existing Firms</FormLabel>
          <FormHelperText>
            Select a firm to edit the settings
            <br />
            The following Firms have access to Design Analytics.
            <br />
            <b>1 == enabled, 0 == disabled</b>
            <br />
            <em>All but Internal DesignerPages firms should have internal functionality disabled</em>
          </FormHelperText>
          <DataTable showExport={false} columns={state.columns} records={state.firmlist} rowClick={showFirmHandler} />
        </Grid>
      </Grid>
    </Paper>
  );
}
