import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function InfoIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 55 55">
      <path d="M27.5,2.6c-13.8,0-25,11.2-25,25s11.2,25,25,25s25-11.2,25-25S41.3,2.6,27.5,2.6z M25.1,15.2
                c0.5-0.5,1.2-0.7,2-0.7s1.4,0.2,2,0.7c0.5,0.5,0.8,1.1,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9c-0.5,0.5-1.2,0.8-2,0.8s-1.4-0.3-2-0.8
                c-0.5-0.5-0.8-1.1-0.8-1.9C24.3,16.3,24.5,15.7,25.1,15.2z M33,40.3c-0.4,0.3-1,0.5-1.7,0.5c-0.2,0-0.5,0-0.8,0
                c-0.3,0-0.7-0.1-1.1-0.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0-0.5-0.1-0.7-0.1c-0.3,0-0.6,0-1,0s-0.7,0.1-1.1,0.1
                c-0.5,0.1-0.9,0.1-1.2,0.1c-0.3,0-0.6,0.1-0.8,0.1c-1.4,0-2.2-0.6-2.2-1.8c0-1.2,0.7-1.8,2-1.8c0.1,0,0.2,0,0.5,0
                c0.3,0,0.5,0.1,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.6,0.4-1.9v-9.6C25.4,24.6,25.3,24,25,24
                c-0.1,0-0.1,0-0.3,0c-0.1,0-0.3,0.1-0.5,0.1c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.4,0c-1.4,0-2-0.6-2-1.8c0-1.2,0.8-1.8,2.3-1.8
                c0.3,0,0.5,0,0.7,0c0.2,0,0.3,0,0.4,0c0.4,0.1,0.7,0.1,0.9,0.1c0.3,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5-0.1l1-0.1
                c1.1,0,1.7,0.7,1.7,2v0.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.1c0,0.1,0,0.5,0,1.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6
                c0,0.5,0,1.1,0,1.6s0,1,0,1.6V34c0,0.6,0,1.1,0,1.5c0,0.4,0,0.6,0,0.7c0.1,0.8,0.2,1.2,0.4,1.2c0,0,0,0,0.2,0c0.2,0,0.4-0.1,0.6-0.1
                l0,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.5,0c1.4,0,2.1,0.6,2.1,1.8C33.7,39.5,33.4,39.9,33,40.3z"
      />
    </SvgIcon>
  );
}
