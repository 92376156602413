/* global window */

import resttp from './resttp';

/**
 * Fire and Forget action recording
 * @param {string} page
 * @param {*} filters
 */
const EventRecorder = async (page, filters) => {

  try {
    const recorderUrl = '/v2/session/event';
    const payload = {
      page,
      filters,
    };

    // console.log('Recording', { page, filters });
    resttp.post(recorderUrl, payload);
  } catch (ex) {
    // console.error('rec err', ex);
  }

  // resolve immediately, regardless of what happens above
  return Promise.resolve(true);
};

/**
 * Simple extraction of browser information we sent to the Event Recorder
 * login event recording uses this information
 */
export function BrowserInfo() {
  let ret = {};
  if (!window) {
    ret = {
      info: 'No window object available, browser info not submitted',
    };
  } else {
    ret = {
      screen: {
        height: (window.screen) ? window.screen.height : 0,
        width: (window.screen) ? window.screen.width : 0,
        colorDepth: (window.screen) ? window.screen.colorDepth : 0,
        pixelDepth: (window.screen) ? window.screen.pixelDepth : 0,
      },
      browser: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      userAgent: (window.navigator) ? window.navigator.userAgent : '',
    };
  }
  return ret;
}

export default EventRecorder;
