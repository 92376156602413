import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import resttp from '../lib/resttp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function CategorySelect(props) {
  const classes = useStyles();
  const { onChange, defaults, multiple } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  // TODO: handle errors
  const fetch = React.useMemo(
    () => throttle((term, callback) => {
      resttp.get('/v2/autocomplete/category/', { params: { term } }).then(callback);
    }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results.data.map(r => r.name) || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={multiple}
        size="small"
        autoComplete
        options={options}
        getOptionLabel={option => option}
        value={defaults}
        filterSelectedOptions
        onChange={(e, v) => onChange(v)}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Categories"
            placeholder="Categories"
            fullWidth
            onChange={handleInputChange}
          />
        )}
      />
    </div>
  );
}


CategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaults: PropTypes.oneOfType(
    [
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ],
  ),
  multiple: PropTypes.bool,
};

CategorySelect.defaultProps = {
  defaults: [],
  multiple: true,
};
