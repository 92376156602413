/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import history from '../lib/history';
import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import LineChart from '../components/LineChart';
import ColumnChart from '../components/ColumnChart';
import DataTable from '../components/DataTable';
import ValueLabel from '../components/ValueLabel';
import CharacteristicsChart from '../components/CharacteristicsChart';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import SourceDialog from '../components/SourceDialog';
import EventRecorder from '../lib/eventRecorder';
import resttp from '../lib/resttp';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#F8FAF6',
    padding: theme.spacing(1),
    width: '100%',
    border: 'none !important',
    boxShadow: 'none',
  },
}));

const defaultState = {
  sampleSize: {
    series: [],
    categories: [],
  },
  specCount: '-',
  medianSpec: '-',
  medianSearch: '-',
  topproducts: [],
  topchart: [],
  loadTime: '∞',

  topProductsColumns: [
    {
      name: 'Product Logo',
      column: 'product_image_url',
      image: true,
      skipExport: true,
    },
    { name: 'Product Name', column: 'product_name' },
    { name: 'Product Id', column: 'product_id', skipDisplay: true },
    { name: 'Manufacturer Name', column: 'manufacturer_name' },
    { name: 'Manufacturer Id', column: 'manufacturer_id', skipDisplay: true },
    {
      name: 'Spec Percent',
      column: 'spec_percent',
      valueSuffix: '%',
      sort: 'desc',
      colorValue: true,
      digits: 4,
    },
    {
      name: 'Growth (Real)',
      column: 'spec_growth_real',
      valueSuffix: '%',
      colorValue: true,
      digits: 4,
    },
    {
      name: 'Growth (Absolute)',
      column: 'spec_growth_absolute',
      valueSuffix: '%',
      colorValue: true,
      digits: 4,
    },
  ],
};

export default function Overview() {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState(defaultState);
  const [loadingSpecs, setLoadingSpecs] = React.useState(true);
  const [specTypes, setSpecTypes] = React.useState([]);

  const updateState = (theState) => {
    setState((prevState) => ({ ...prevState, ...theState }));
  };

  const getSpecTypes = async (filters) => {
    setLoadingSpecs(true);
    if (
      !globalState.configuration.permissions.spectypes ||
      filters.categories.length !== 1
    ) {
      setLoadingSpecs(false);
      return Promise.resolve(true);
    }

    const response = await resttp.post(
      '/v2/analytics/product/specifications',
      filters
    );
    setSpecTypes(response?.data?.data?.specTypes || []);

    setLoadingSpecs(false);
  };

  const callAll = async (inFilters) => {
    const filters = cleanFilters(inFilters);
    const newState = cloneDeep(defaultState);
    if (!filters.qtyShares) {
      newState.topProductsColumns = newState.topProductsColumns.filter(
        (c) => c.column !== 'spec_quantity_share'
      );
    }
    setState(newState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);

    const tim = new APITimer();
    tim.start();

    try {
      EventRecorder('product/overview', filters);

      getSpecTypes(filters);
      const stateData = await Promise.all([
        dataToState(
          { url: '/v2/analytics/product/topproducts', filters },
          (data) => {
            const topchart = data.data.map((r) => r.sum_spec);
            return {
              topproducts: data.data.map((p) => {
                const ret = Object.assign({}, p);
                if (
                  ret.product_name &&
                  ret.product_name.toLowerCase() === 'open spec'
                ) {
                  ret.product_image_url = '/openspec.png';
                }
                return ret;
              }),
              topchart: topchart.slice(0, 50),
            };
          }
        ),
        dataToState(
          { url: '/v2/analytics/product/samplesize', filters },
          (data) => {
            const categories = data.data.map((r) => r.period_group);
            const series = [
              { name: 'Events', data: data.data.map((r) => r.count) },
            ];
            return {
              sampleSize: {
                series,
                categories,
              },
            };

            // const { categories } = data;
            // const series = [{ name: 'Events', data: data.data }];
            // return {
            //   sampleSize: {
            //     series,
            //     categories,
            //   },
            // };
          }
        ),
        dataToState(
          { url: '/v2/analytics/product/speccount', filters },
          (data) => ({ specCount: data.data.toLocaleString() })
        ),
        dataToState(
          { url: '/v2/analytics/product/medians', filters },
          (data) => ({
            medianSpec: `${data.data.spec}%`,
            medianSearch: `${data.data.search}%`,
          })
        ),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(
      `data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`
    );
    globalState.setProcessing(false);
  };

  const topProductHandler = (row) => {
    history.push(`/products/drill/${row.product_id}#manufacturer_name=${row.manufacturer_name}`);
  };

  React.useEffect(() => {
    document.title = 'Products';
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <SourceDialog />
      <Grid container spacing={3}>
        <Grid className="brands-block" item xs={12} sm={4}>
          <ValueLabel title="Products Spec'd" value={state.specCount} />
        </Grid>
        <Grid className="brands-block" item xs={12} sm={4}>
          <ValueLabel title="Median Spec" value={state.medianSpec} />
        </Grid>
        <Grid className="brands-block" item xs={12} sm={4}>
          <ValueLabel title="Median Search" value={state.medianSearch} />
        </Grid>
        <Grid className="brands-block" item xs={12} sm={6}>
          <LineChart
            title="Sample Size"
            yTitle="Count"
            series={state.sampleSize.series}
            labels={state.sampleSize.categories}
          />
        </Grid>
        <Grid className="brands-block" item xs={12} sm={6}>
          <ColumnChart
            title="Top 50 Products Chart"
            yTitle="Spec Score"
            data={[
              { type: 'column', name: 'Spec Score', data: state.topchart },
            ]}
          />
        </Grid>
        <Grid className="brands-block" item xs={12} sm={12}>
          <DataTable
            title="Top Products"
            height="600px"
            perPage={25}
            columns={state.topProductsColumns}
            records={state.topproducts}
            rowClick={topProductHandler}
          />
        </Grid>
        {globalState.configuration.permissions.spectypes && (
          <Grid item xs={12} sm={12}>
            <CharacteristicsChart
              title="Product Characteristics"
              loading={loadingSpecs}
              specTypes={specTypes}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
