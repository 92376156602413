
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { makeStyles, Typography, Button, IconButton, Icon, Toolbar } from '@material-ui/core';
import proj4 from 'proj4';
import mapDataWorld from './world-ultrahighres';
import cloneDeep from 'lodash/cloneDeep';
import markerClusters from 'highcharts/modules/marker-clusters';
// const XLSX = require('xlsx');

markerClusters(Highcharts);

require('highcharts/modules/map')(Highcharts);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#f8fafb',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
}));

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

const worldOpts = {
  chart: {
    map: mapDataWorld,
  },
  title: {
    text: '',
  },
  mapNavigation: {
    enabled: true,
  },
  tooltip: {
    headerFormat: '',
    pointFormat: '<b>{point.name}({point.cnt})</b><br>Lat: {point.lat:.2f}, Lon: {point.lon:.2f}',
  },
  colorAxis: {
    min: 0,
    max: 20,
  },
  plotOptions: {
    mappoint: {
      cluster: {
        enabled: true,
        allowOverlap: false,
        animation: {
          duration: 450,
        },
        layoutAlgorithm: {
          type: 'grid',
          gridSize: 70,
        },
        zones: [{
          from: 1,
          to: 4,
          marker: {
            radius: 13,
          },
        }, {
          from: 5,
          to: 9,
          marker: {
            radius: 15,
          },
        }, {
          from: 10,
          to: 15,
          marker: {
            radius: 17,
          },
        }, {
          from: 16,
          to: 20,
          marker: {
            radius: 19,
          },
        }, {
          from: 21,
          to: 100,
          marker: {
            radius: 21,
          },
        }],
      },
    },
  },
  series: [{
    name: 'Basemap',
    borderColor: '#A0A0A0',
    nullColor: 'rgba(177, 244, 177, 0.5)',
    showInLegend: false,
  }, {
    type: 'mappoint',
    turboThreshold: 100000,
    enableMouseTracking: true,
    colorKey: 'clusterPointsAmount',
    name: 'Cities',
    data: [],
  }],
};

export default function WorldMap(props) {
  const classes = useStyles();
  const [title, setTitle] = React.useState(props.title);
  const [state, setState] = React.useState(worldOpts);

  React.useEffect(() => {
    console.log('enter');

    const defs = cloneDeep(state);
    console.log('cloned');
    defs.series[1].data = Array.from(props.data);
    console.log('propped');
    setState(defs);
    console.log('set');
  }, [props.data]);

  // const handleExport = () => {
  //   const data = state.chartOptions.series[0].data;
  //   const out = data.map(r => ({ state: r['hc-key'], value: r.value }));

  //   const exportName = 'worldmap.xlsx';
  //   const workbook = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet(out);
  //   XLSX.utils.book_append_sheet(workbook, ws, 'dataset');
  //   XLSX.writeFile(workbook, exportName);
  // };


  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="h4">
          {title}
          {/* {(state.chartOptions.series.length > 0) ? (
            <IconButton className={classes.exportBtn} onClick={handleExport}>
              <Icon className="icon-download" />
            </IconButton>
          ) : <></> } */}
        </Typography>
      </Toolbar>
      <HighchartsReact
        highcharts={Highcharts}
        options={state}
        constructorType="mapChart"
      />
    </Paper>
  );
}


WorldMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};
