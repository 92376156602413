/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import LineChart from '../components/LineChart';
import DataTable from '../components/DataTable';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
  productNameLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    marginLeft: 13,
  },
  productNameValue: {
    fontSize: 13,
    color: '#999',
    textTransform: 'none',
  },
}));

const defaultState = {
  productName: '-',
  performance: {
    series: [],
    categories: [],
  },
  average: {
    series: [],
    categories: [],
  },
  growth: {
    series: [],
    categories: [],
  },
  table: [],

  tableColumns: [
    { name: 'Month', column: 'name', sort: 'desc' },
    { name: '% Spec', column: 'spec', valueSuffix: '%', digits: 4 },
    { name: '% Search', column: 'search', valueSuffix: '%', digits: 4 },
  ],

  loadTime: '∞',
};

export default function Product(props) {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState(defaultState);
  const [product, setProduct] = React.useState({
    id: props.match.params.productId,
    name: `Loading Product ${props.match.params.productId}`,
  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    setState(defaultState);
    const filters = cleanFilters(inFilters);

    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);

    const tim = new APITimer();
    tim.start();

    try {
      EventRecorder('product/drill', { productId: product.id, filters });

      const stateData = await Promise.all([
        dataToState({ url: `/v2/analytics/product/${product.id}/name`, filters }, 'productName'),
        dataToState({ url: `/v2/analytics/product/${product.id}/productdetail`, filters }, data => (
          {
            table: data.data.performance.map(r => (
              { name: r.period_group, spec: r.spec_pct, search: r.search_pct }
            )),
            performance: {
              series: [
                { name: 'Spec', data: data.data.performance.map(r => r.spec_pct) },
                { name: 'Search', data: data.data.performance.map(r => r.search_pct) },
              ],
              categories: data.data.performance.map(r => r.period_group),
            },
            average: {
              series: [
                { name: 'Spec', data: data.data.average.map(r => r.spec_pct) },
                { name: 'Search', data: data.data.average.map(r => r.search_pct) },
              ],
              categories: data.data.average.map(r => r.period_group),
            },
            growth: {
              series: [
                { name: 'Spec', data: data.data.growth.map(r => r.spec_pct) },
                { name: 'Search', data: data.data.growth.map(r => r.search_pct) },
              ],
              categories: data.data.growth.map(r => r.period_group),
            },
          })),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    document.title = `Brand Drilldown : ${product.name}`;
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  const getProductNameFromUrlHash = () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.replace('#', '?'));
    return params.has('manufacturer_name') ? `by ${params.get('manufacturer_name')}` : ''
  }

  return (
    <Paper className={classes.content}>
      <h1 className={classes.productNameLabel}>
        PRODUCT:&nbsp;
        <span className={classes.productNameValue}>
          {state.productName} {getProductNameFromUrlHash()}
        </span>
      </h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <LineChart title="Product Performance" useLegend series={state.performance.series}
                     labels={state.performance.categories}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LineChart title="Product Performance Avg" useLegend series={state.average.series}
                     labels={state.average.categories}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LineChart title="Product Performance Growth" useLegend series={state.growth.series}
                     labels={state.growth.categories}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataTable title="Product Performance Table" height="600px" columns={state.tableColumns}
                     records={state.table}/>
        </Grid>
      </Grid>
    </Paper>
  );
}

Product.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
