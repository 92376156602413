import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorDisplay(props) {
  const classes = useStyles();
  const { errors } = props;

  if (errors.length > 0) {
    return (
      <div className={classes.root}>
        <Alert variant="filled" severity="error">
          <AlertTitle>Error</AlertTitle>
          <List>
            {errors.map((err, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>{err}</ListItem>
            ))}
          </List>
        </Alert>
      </div>
    );
  }
  return ('');
}

ErrorDisplay.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
