import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core/';

import { useLocation } from 'react-router-dom';
import path from 'path';

import history from '../lib/history';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },

  drilldownWrapper: {
    padding: '20px',
  },

  drilldownText: {
    color: '#828282',
  },

  greenText: {
    color: '#1fcdba',
  },

  drilldownTitle: {
    fontWeight: 100,
    fontSize: '1.7rem',
  },
  acRoot: {
    backgroundColor: '#e3e7ea',
  },
}));

export default function ChooseFirm() {
  const classes = useStyles();
  const firmInput = useRef(null);
  const loc = useLocation();
  const [inputValue, setInputValue] = React.useState('');

  const handleFirmPick = () => {
    const goto = path.join(loc.pathname, inputValue.toString());
    history.push(goto);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className={classes.drilldownWrapper}>
      <Typography className={classes.drilldownText} variant="body1" gutterBottom>
        Provide a firm ID
      </Typography>
      <Grid container>
        <Grid item xs={2}>
          <TextField
            inputRef={firmInput}
            variant="outlined"
            label="FirmId"
            placeholder="Firm"
            fullWidth
            onChange={handleInputChange}
            style={{ borderRadius: '0' }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" onClick={handleFirmPick}>Choose Firm</Button>
        </Grid>
      </Grid>
    </div>
  );
}
