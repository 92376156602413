import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import LockedTooltip from './LockedTooltip';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  active: {
    color: '#fff !important',
    borderTopRightRadius: '9px',
    borderBottomRightRadius: '9px',
    background: '#009282',
    margin: '5px auto',
  },
  collapseWrapperInner: {
    width: '100%',
    background: '#009282',
    borderBottomRightRadius: '9px',
    '& a': {
      textTransform: 'capitalize',
      fontSize: '0.1rem !important',
      padding: '4px 0 0 26px',
    },
  },
  menuText: {
    fontWeight: '600',
  },
  linkText: {
    color: '#01a891',
  },
  inactiveItem: {
    color: '#01a891',
  },
  activeItem: {
    color: '#fff',
    backgroundColor: '#009282',
    borderTopRightRadius: '9px',
  },
  listul: {
    marginRight: '10px',
  },
  nestedList: {
    paddingTop: 0,
  },
}));

const ListItemLink = React.forwardRef((props, ref) => (
  <ListItem ref={ref} button component={RouterLink} {...props} to={props.href} />
));

function ShowMenuItem(props) {
  const classes = useStyles();

  const { item, handleToggle, isToggled, nested, activeTab, handleActive } = props;

  if (item.hidden) {
    return (<></>);
  }

  if (item.children) {
    const cls = isToggled(item.title) ? classes.activeItem : classes.inactiveItem;

    //
    // Note: If the menu item is locked, we change the href to #, so we don't actually
    //   attempt to load anything.
    //
    // Note: Any parent that has unlocked children, will itself be unlocked.
    //   This means clicking the menu item will attempt to load the href.
    //   If that href goes to a locked page, LayoutPrivate will handle the redirect
    //   it does result in an unnecessary load at times, but we'll accept this.
    //
    return (
      <>
        <ListItemLink
          className={cls}
          href={(item.locked) ? '#' : item.path}
          onClick={handleActive(item.pathkey)}
        >
          <LockedTooltip locked={item.locked}>
            <ListItemText classes={{ primary: classes.menuText }} primary={item.title} />
          </LockedTooltip>
          {isToggled(item.title) ? <ExpandLess color="inherit" /> : <ExpandMore color="inherit" />}
        </ListItemLink>
        <Collapse
          classes={{
            wrapperInner: classes.collapseWrapperInner,
          }}
          in={isToggled(item.title)}
          timeout="auto"
          unmountOnExit
        >
          <ShowMenu
            nested
            menu={item.children}
            activeTab={activeTab}
            handleActive={handleActive}
            isToggled={isToggled}
            handleToggle={handleToggle}
          />
        </Collapse>
      </>
    );
  }
  const cls = {
    className: [],
  };
  if (nested) {
    cls.className.push(classes.nested);
  }
  if (activeTab === item.key) {
    cls.className.push(classes.active);
  }
  cls.className.push(classes.linkText);
  cls.className = cls.className.join(' ');

  // if (item.locked) {
  //   console.log('A LOCKED:', item)
  // } else {
  //   console.log('NOT A LOCKED:', item)
  // }

  return (
    <LockedTooltip locked={item.locked}>
      <ListItemLink
        href={(item.locked) ? '#' : item.path}
        {...cls}
        onClick={handleActive(item.key)}
      >
        <ListItemText classes={{ primary: classes.menuText }} primary={item.title} />
      </ListItemLink>
    </LockedTooltip>
  );
}

export default function ShowMenu(props) {
  const classes = useStyles();

  const { menu, activeTab, handleActive, handleToggle, isToggled, nested } = props;
  if (menu.length < 1) {
    return ('');
  }

  const cls = {
    className: [classes.listul],
  };
  if (nested) {
    cls.className.push(classes.nestedList);
  }

  return (
    <List className={cls.className.join(' ')}>
      {menu.map(item => (
        <ShowMenuItem
          key={item.key}
          item={item}
          activeTab={activeTab}
          handleActive={handleActive}
          handleToggle={handleToggle}
          isToggled={isToggled}
          nested={nested}
        />
      ))}
    </List>
  );
}

ShowMenuItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    children: PropTypes.array,
    locked: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  handleToggle: PropTypes.func.isRequired,
  isToggled: PropTypes.func.isRequired,
  nested: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  handleActive: PropTypes.func.isRequired,
};
ShowMenuItem.defaultProps = {
  nested: false,
};

ShowMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    children: PropTypes.array,
  })).isRequired,
  handleToggle: PropTypes.func.isRequired,
  isToggled: PropTypes.func.isRequired,
  nested: PropTypes.bool,
  activeTab: PropTypes.string.isRequired,
  handleActive: PropTypes.func.isRequired,
};
ShowMenu.defaultProps = {
  nested: false,
};

ListItemLink.propTypes = {
  href: PropTypes.string.isRequired,
};
