import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import useGlobalState from '../hooks/useGlobalState';

import ShowMenu from './ShowMenu';
import logo from './BCI_logo.png';
import logoHorizontal from './DA_logo_horizontal_white.svg';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'uppercase',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: '100%',
    xheight: '100%',
    padding: '20px',
  },
  bottomNav: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
  },
  poweredBy: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'none',
    fontStyle: 'italic',
    gap: '10px',
    padding: '0 20px 20px 10px',
  },
  dataLoaded: {
    color: '#3C8378',
    padding: '0 10px',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
}));

export default function LeftMenu(props) {
  const { menu } = props;
  const classes = useStyles();
  const globalState = useGlobalState();
  const loc = useLocation();
  const [activeTab, setActiveTab] = React.useState('');
  const [toggle, setToggle] = React.useState(null);
  const handleToggle = (name) => (event) => {
    if (toggle === name) {
      setToggle(null);
    } else {
      setToggle(name);
    }
  };

  const handleActive = (name) => (event) => {
    setActiveTab(name);
    handleToggle(name)();
  };

  const isToggled = (name) => {
    return toggle === name;
  };

  const CheckMenu = (menu, location) => {
    const has = menu.filter((item) => {
      if (item.children) {
        const chldActive = CheckMenu(item.children, location);
        if (chldActive) {
          // console.log('child active', item);
          setToggle(item.title);
          return true;
        }
      } else {
        const cntLoc = location.pathname.split('/').length;
        const cntItm = item.path.split('/').length;
        let deep = false;
        if (cntLoc === cntItm || cntLoc + 1 === cntItm) {
          deep = true;
        }
        // console.log('check', { item: item.path, path: location.pathname })
        if (
          deep &&
          location.pathname.substr(0, item.path.length) === item.path
        ) {
          // console.log('MATCH', { key: item.key, itm: item.path, loc: location.pathname});
          setActiveTab(item.key);
          return true;
        }
      }
      return false;
    });
    if (has.length > 0) {
      return true;
    }
  };

  //   React.useEffect(() => {
  //     // this is only done if a firm has a restricted view.
  //     const setMyBrands = async () => {
  //       if (globalState.configuration.permissions.mybrands) {
  //         menu = menu.map(m => {
  //           const kids = (!m.children) ? null : m.children.map(c => {
  //             if (c.path === '/brands/drill/') {
  //               c.path = '/brands/drill/my';
  //               c.locked = false;
  //             } else {
  //               c.locked = true;
  //             }
  //             return c;
  //           });
  //           m.children = kids;
  //           m.locked = true; // if a child is not locked, then this is not locked
  //           if (m.children && m.children.find(c => !c.locked)) {
  //             m.locked = false;
  //           }

  //           return m;
  //         });
  //       }
  //     };

  //     // const setMyMenus = async () => {
  //     //   console.log('setting my menus')
  //     //   menus.push(menu);
  //     //   if (globalState.user.permissions.internal) {
  //     //     menus.push(oldMenu);
  //     //   }
  //     // };
  //     setMyBrands();
  // //    setMyMenus();
  //   }, []);

  React.useEffect(() => {
    const setLocation = async () => {
      // this is garbage.  Since we accept urls directly, we need to ensure
      // that the entered url is properly expanded and marked active.
      // sadly, this has been forced in by first checking the url with our menu items (CheckMenu())
      // and then just setting the parent item as toggled if there is an active child (initToggle())

      // we go backwards, b/c we want to highlight the FIRST matching menu item
      //for(let i = menus.length - 1; i >= 0; i--) {
      CheckMenu(menu, loc);
      //}
      // CheckMenu(oldMenu, loc);
      // CheckMenu(menu, loc);
    };

    setLocation();
  }, [loc]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <img className={classes.logo} src={logo} alt="Design Analtyics Logo" />
      <ShowMenu
        menu={menu}
        activeTab={activeTab}
        handleActive={handleActive}
        handleToggle={handleToggle}
        isToggled={isToggled}
      />
      <div className={classes.bottomNav}>
        <div className={classes.poweredBy}>
          <span>Powered by</span>
          <img src={logoHorizontal} alt="Design Analtyics Logo" />
        </div>
        {globalState.user.permissions.internal && (
          <React.Fragment>
            <Divider />
            <h3 className={classes.dataLoaded}>{globalState.status}</h3>
          </React.Fragment>
        )}
      </div>
    </Drawer>
  );
}
