import React from 'react';
import { Router, Route, Link, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

import { AuthProvider } from './hooks/useAuth';
import GlobalStore from './hooks/globalStore';
import history from './lib/history';

import analyticsTheme from './style/analytics.theme';
import LayoutPrivate from './components/LayoutPrivate';
import LayoutPublic from './components/LayoutPublic';
import LayoutAdmin from './components/LayoutAdmin';

/**
 * Public Components
 */
import Login from './Login/Login';

/**
 * Private Components
 */
import Home from './Home/Home';
import BrandOverview from './Brand/Overview';
import BrandChoose from './Brand/ChooseBrand';
import BrandDrilldown from './Brand/Drill';

import BrandRank from './Brand/BrandRank';
import BrandEventSummary from './Brand/BrandEventSummary';
import BrandEventTypeSummary from './Brand/BrandEventTypeSummary';
import ProductOverview from './Product/Overview';
import ProductChoose from './Product/ChooseProduct';
import ProductDrilldown from './Product/Drill';
import Patterns from './Pattern/Overview';
import SpecAttribute from './Pattern/SpecAttribute';
import Category from './Category/Overview';
import Adjacency from './Adjacency/Overview';

import ChooseFirm from './Firm/ChooseFirm';
import FirmOverview from './Firm/Overview';
import FirmEmail from './Firm/Email';
import InternalTop from './Brand/InternalTop';
import InternalDrill from './Brand/InternalDrill';
import ChooseInternal from './Brand/ChooseInternal';

import Admin from './Admin/Admin';
import AdminOverview from './Admin/Overview';
import SessionLog from './Admin/SessionLog';

import MapOffices from './Map/Offices';
import MapSpec from './Map/Specs';
import MapSearch from './Map/Searches';
import MapProjects from './Map/Projects';

const publicRoutes = [
  {
    path: '/login',
    exact: true,
    main: Login,
    public: true,
  },
];

const adminRoutes = [
  { path: '/admin', exact: true, main: AdminOverview, public: false },
  { path: '/admin/sessions', exact: true, main: SessionLog, public: false },
  { path: '/admin/:firmId', exact: true, main: Admin, public: false },
];

const privateRoutes = [
  { path: '/', exact: true, main: Home },
  { path: '/brands', exact: true, main: BrandOverview },
  { path: '/brands/drill/', exact: true, main: BrandChoose },
  { path: '/brands/drill/:brandId', exact: true, main: BrandDrilldown },
  { path: '/mybrands/:brandId', exact: true, main: BrandDrilldown },

  { path: '/products', exact: true, main: ProductOverview },
  { path: '/products/drill', exact: true, main: ProductChoose },
  { path: '/products/drill/:productId', exact: true, main: ProductDrilldown },

  //
  // these are 'internal' reports
  //
  { path: '/patterns', exact: true, main: Patterns },
  { path: '/specattribute', exact: true, main: SpecAttribute },
  { path: '/adjacency', main: Adjacency },

  { path: '/brandrank/', exact: true, main: BrandChoose },
  { path: '/brandrank/:brandId', exact: true, main: BrandRank },

  { path: '/brandsummary/', exact: true, main: ChooseInternal },
  { path: '/brandsummary/topbrands', exact: true, main: InternalTop },
  { path: '/brandsummary/branddrill', exact: true, main: ChooseInternal },
  {
    path: '/brandsummary/branddrill/:brandids',
    exact: true,
    main: InternalDrill,
  },
  { path: '/brandsummary/:brandids', exact: true, main: BrandEventTypeSummary },

  { path: '/brandeventsummary/', exact: true, main: ChooseInternal },
  {
    path: '/brandeventsummary/:brandids',
    exact: true,
    main: BrandEventSummary,
  },

  { path: '/category', exact: true, main: Category },

  { path: '/firm/', exact: true, main: ChooseFirm },
  { path: '/firm/emails/', exact: true, main: ChooseFirm },
  { path: '/firm/emails/:firmId', exact: true, main: FirmEmail },
  { path: '/firm/:firmId', exact: true, main: FirmOverview },

  { path: '/map/projects', exact: true, main: MapProjects },
  { path: '/map/offices', exact: true, main: MapOffices },
  { path: '/map/specs', exact: true, main: MapSpec },
  { path: '/map/searches', exact: true, main: MapSearch },
];

// this kinda sucks... we want to get the private routes AND any subpages that may be defined.
// if we don't use exact when defining the layout route, we will
// render something like:  brandsXXXXXX/ as a private route
const layoutPrivateRoutes = privateRoutes
  .map((r) => r.path)
  .concat(privateRoutes.map((r) => `${r.path}/*`));
const layoutAdminRoutes = adminRoutes
  .map((r) => r.path)
  .concat(adminRoutes.map((r) => `${r.path}/*`));

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No page match for
      <code>{location.pathname}</code>
    </h3>
    <Link to="/">Go Home</Link>
  </div>
);

NoMatch.propTypes = {
  location: PropTypes.node.isRequired,
};

export default function Application() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        <GlobalStore>
          <MuiThemeProvider theme={analyticsTheme}>
            <Router history={history}>
              <Switch>
                <Route exact path={publicRoutes.map((r) => r.path)}>
                  <LayoutPublic>
                    <Switch>
                      {publicRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.main}
                        />
                      ))}
                    </Switch>
                  </LayoutPublic>
                </Route>
                <Route exact path={layoutPrivateRoutes}>
                  <LayoutPrivate>
                    <Switch>
                      {privateRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.main}
                        />
                      ))}
                      <Route component={NoMatch} />
                    </Switch>
                  </LayoutPrivate>
                </Route>
                <Route exact path={layoutAdminRoutes}>
                  <LayoutAdmin>
                    <Switch>
                      {adminRoutes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          exact={route.exact}
                          component={route.main}
                        />
                      ))}
                      <Route component={NoMatch} />
                    </Switch>
                  </LayoutAdmin>
                </Route>
                <Route component={NoMatch} />
              </Switch>
            </Router>
          </MuiThemeProvider>
        </GlobalStore>
      </AuthProvider>
    </SnackbarProvider>
  );
}
