/**
 * APITimer  :: Simple, helper class to use for timing our API calls.
 *
 * Records timing between starting an API call,
 *  the point the api call finishes,
 *  and the point that the data load call has completed
 *
 */
class APITimer {
  constructor() {
    this.startTime = 0;
    this.endTime = 0;
    this.apiEnd = 0;
  }

  start() {
    this.startTime = Date.now();
    return this.startTime;
  }

  finishAPI() {
    this.apiEnd = Date.now();
    return this.apiEnd;
  }

  stop() {
    this.endTime = Date.now();
    return this.endTime;
  }

  apiTime() {
    return this.apiEnd - this.startTime;
  }

  renderTime() {
    return this.endTime - this.apiEnd;
  }

  totalTime() {
    return this.endTime - this.startTime;
  }
}

export default APITimer;
