import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import resttp from '../lib/resttp';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 400,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function BrandSelect(props) {
  const classes = useStyles();
  const { onChange, defaults, multiple } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  // TODO: handle errors
  const fetch = React.useMemo(
    () => throttle((term, callback) => {
      resttp.get('/v2/autocomplete/brand/', { params: { term } }).then(callback);
    }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        setOptions(results.data || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={multiple}
        size="small"
        autoComplete
        options={options}
        getOptionLabel={option => option.name}
        value={defaults}
        filterSelectedOptions
        onChange={(e, v) => onChange(v)}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Brand"
            placeholder="Brand"
            fullWidth
            onChange={handleInputChange}
          />
        )}
      />
    </div>
  );
}


BrandSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaults: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  multiple: PropTypes.bool,
};

BrandSelect.defaultProps = {
  defaults: [],
  multiple: false,
};
