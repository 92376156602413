import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
  makeStyles,
  IconButton,
  Icon,
  Toolbar,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  Box,
  CircularProgress,
} from '@material-ui/core';
import InfoIcon from '../Icons/InfoIcon';
import { Lens as BulletIcon } from '@material-ui/icons';

const XLSX = require('xlsx');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
    backgroundColor: '#fff !important',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important',
  },
  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
    float: 'right',
  },
  infoBtn: {
    backgroundColor: '#fff',
    color: '#1fcdba',
    padding: '0 2px 0 0',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  typeDropdown: {
    marginLeft: '24px',
  },
  legend: {
    float: 'right',
    marginRight: '24px',
    fontSize: '12px',
  },
  legendPct: {
    fontWeight: 'bold',
    float: 'right',
    paddingLeft: '20px',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

//
// max number of items to show in the legend.
//
const MAX_ITEMS = 10;

const pieColors = [
  '#50efda',
  '#25d4d2',
  '#20c7b6',
  '#1ba799',
  '#189286',
  '#14796f',
  '#105f57',
  '#0b403a',
  '#051f1c',
  '#b6b7b7',
];

export default function CharacteristicsChart(props) {
  const classes = useStyles();
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [title, setTitle] = React.useState('');
  const [current, setCurrent] = React.useState(null);
  const [specTypeData, setSpecTypeData] = React.useState([]);
  const [state, setState] = React.useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      backgroundColor: '#fff',
    },
    title: {
      text: '',
    },
    tooltip: {
      pointFormat: '<b>{point.y}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    legend: {
      useHTML: false,
      layout: 'vertical',
      align: 'right',
      labelFormat: '{name}: {y}%',
      alignColumns: true,
      verticalAlign: 'middle',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        colors: pieColors,
        xshowInLegend: true,
      },
    },
    series: [],
  });

  const { loading } = props;

  const handleExport = () => {
    // we use the raw data for export.
    const data = props.specTypes;
    const out = [];

    for (let i = 0; i < data.length; i++) {
      const spec = data[i];
      for (let j = 0; j < spec.data.length; j++) {
        const row = spec.data[j];
        const rec = {
          spec_type: spec.name,
          spec_type_id: spec.specTypeId,
          key: row.name,
          value: `${row.value}%`,
        };
        out.push(rec);
      }
    }

    const exportName = 'characteristics.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(out);
    XLSX.utils.book_append_sheet(workbook, ws, 'dataset');
    XLSX.writeFile(workbook, exportName);
  };

  const showCharacteristic = (name) => {
    const rec = specTypeData.find((r) => r.name === name);
    if (!rec) {
      return;
    }
    setCurrent(rec);
  };

  const handlePickCharacteristic = (event) => {
    const val = event.target.value;
    showCharacteristic(val);
  };

  React.useEffect(() => {
    const setup = async () => {
      setTitle(props.title);

      if (!props.specTypes || props.specTypes.length < 1) {
        setIsEnabled(false);
        return;
      }

      //
      // initially, We disable the module if there are zero or more than 1 category being shown
      //
      const cats = new Set(props.specTypes.map((st) => st.categoryId));
      if (cats.size < 1) {
        // TODO:     if (cats.size !== 1) {
        setIsEnabled(false);
      }
      setIsEnabled(true);

      //
      // we have to set the max number of items here, and make the rest fall into the OTHER group.
      // we need to sort, then pick max -1, and reduce the rest into 'other'.
      const data = props.specTypes.map((r) => {
        let datas = r.data.map((x) => ({
          name: x.name,
          y: parseFloat(x.value, 10),
        }));
        datas = datas.sort((a, b) => b.y - a.y);

        let top = [];
        if (datas.length > MAX_ITEMS) {
          top = datas.splice(0, MAX_ITEMS - 1);
          if (datas.length > 0) {
            const other = datas
              .map((v) => v.y)
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue
              );
            if (other > 0) {
              top.push({ name: 'Other', y: parseFloat(other.toFixed(4)) });
            }
          }
        } else {
          top = datas;
        }

        const d = {
          name: r.name,
          categoryId: r.categoryId,
          data: top,
        };

        return d;
      });
      setSpecTypeData(
        data.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
      showCharacteristic(data[0].name);
    };
    setup();
  }, [props]);

  React.useEffect(() => {
    if (!current) {
      return;
    }

    const rec = specTypeData.find((r) => r.name === current.name);
    if (!rec) {
      return;
    }
    const series = [
      {
        data: JSON.parse(JSON.stringify(rec.data)), // ugh
      },
    ];
    setState((prevState) => ({ ...prevState, ...{ series } }));
  }, [current]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h4">
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title="Product characteristics are the detailed properties of the products such as color, size, shape, and thickness (where applicable).
                The top ten characteristics will be displayed on the screen. Click the download icon for a complete listing."
                placement="bottom-start"
              >
                <IconButton className={classes.infoBtn} aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {specTypeData.length > 0 ? (
              <IconButton className={classes.exportBtn} onClick={handleExport}>
                <Icon className="icon-download" />
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      {loading ? (
        <>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          {!isEnabled && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Typography variant="h4">
                  No parameters available for this filtration
                </Typography>
              </Grid>
            </Grid>
          )}
          {isEnabled && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Grid className={classes.typeDropdown}>
                  <Select
                    id="choose-characteristic"
                    value={current ? current.name : ''}
                    onChange={handlePickCharacteristic}
                    autoWidth
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Choose Characteristic
                    </MenuItem>
                    {specTypeData.map((t) => (
                      <MenuItem key={t.name} value={t.name}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <HighchartsReact highcharts={Highcharts} options={state} />
              </Grid>
              <Grid item xs={4}>
                <table className={classes.legend}>
                  <tbody>
                    {current &&
                      current.data.map((d, index) => (
                        <tr key={d.name}>
                          <td>
                            <BulletIcon
                              style={{
                                paddingTop: '6px',
                                fontSize: 20,
                                color: pieColors[index],
                              }}
                            />
                          </td>
                          <td>{d.name}</td>
                          <td className={classes.legendPct}>{d.y}%</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Paper>
  );
}

CharacteristicsChart.propTypes = {
  loading: PropTypes.bool.isRequired,
  specTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      specTypeId: PropTypes.number,
      categoryId: PropTypes.number,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};
