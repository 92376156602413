/* global window */
import queryString from 'query-string';

import resttp from './resttp';
import history from './history';

/**
 * helper functions used to massage filter data into the various formats it can be used in.
 */

/**
 * Our default filters.
 */
export const defaultFilters = {
  period_interval: '12',
  period_end: 'qtr',
  regions: [],
  segments: [],
  categories: [],
  subCategories: [],
  projprodstatus: [],
  openspec: false,
  // bod: true,
  qtyShares: false,
  category_brand1: null,
  category_brand2: null,
  category_brand3: null,
  category_brand4: null,
  adjacent_brand: null,
  adjacent_category: null,
  spec_attribute_id: null,
  project_ids: null,
  complex: [],
};

export function mergeFilters(stateFilters, urlFilters) {
  const theFilters = { ...defaultFilters, ...stateFilters, ...urlFilters };

  // Filters MUST have categories, this means if the url has
  // no categories, we must set them to the default
  if (!theFilters.categories || theFilters.categories.length < 1) {
    theFilters.categories = stateFilters.categories || [];
  }

  return theFilters;
}

/**
 * Format our Filters in to a Query Parameter string
 *
 * Filters that require a multiple attributes are stored as hyphen (-) separated values
 *
 * @param {Object} filters The Filters to convert to Query Parameters
 */
function toQueryString(filters) {
  const x = {
    period_interval: filters.period_interval,
    period_end: filters.period_end,
    regions: filters.regions,
    segments: filters.segments,
    categories: filters.categories,
    subCategories: filters.subCategories,
    openspec: filters.openspec,
    // bod: filters.bod,
    qtyShares: filters.qtyShares,

    complex: JSON.stringify(filters.complex),

    // Note:  These are parameters for internal reports.
    projprodstatus: filters.projprodstatus
      .filter((f) => f && f.name)
      .map((f) => `${f.value}-${f.name}`),
    category_brands: [
      filters.category_brand1,
      filters.category_brand2,
      filters.category_brand3,
      filters.category_brand4,
    ]
      .filter((f) => f && f.name)
      .map((f) => `${f.id}-${f.name}`),

    adjacent_category: filters.adjacent_category,
    adjacent_brand:
      filters.adjacent_brand && filters.adjacent_brand.id
        ? `${filters.adjacent_brand.id}-${filters.adjacent_brand.name}`
        : null,

    spec_attribute_id: filters.spec_attribute_id,
    project_ids: filters.project_ids,
  };

  return queryString.stringify(x, { skipNull: true });
}

/**
 * When we build our filters from QueryString parameters, we have to be careful with the input.
 *
 * hypen-separated parameters are merged back into their proper format.
 *
 * This is very hacky and should be cleaned up
 */
function fromQueryString() {
  const qs = queryString.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true,
  });
  if (qs.regions && !Array.isArray(qs.regions)) {
    qs.regions = [qs.regions];
  }
  if (qs.segments && !Array.isArray(qs.segments)) {
    qs.segments = [qs.segments];
  }
  if (qs.categories && !Array.isArray(qs.categories)) {
    qs.categories = [qs.categories];
  }
  if (qs.subCategories && !Array.isArray(qs.subCategories)) {
    qs.subCategories = [qs.subCategories];
  }
  if (qs.projprodstatus && !Array.isArray(qs.projprodstatus)) {
    qs.projprodstatus = [qs.projprodstatus];
  }

  if (qs.category_brands && !Array.isArray(qs.category_brands)) {
    qs.category_brands = [qs.category_brands];
  }

  if (qs.complex) {
    try {
      qs.complex = JSON.parse(qs.complex);
    } catch (e) {
      // console.log('ERROR!  Complex QS value was invalid', qs.complex);
    }
  }

  if (qs.projprodstatus) {
    qs.projprodstatus = qs.projprodstatus.map((pps) => {
      const splittt = pps.split('-', 2);
      const value = parseInt(splittt[0], 10);
      const name = splittt[1];
      return { name, value };
    });
  }

  if (qs.adjacent_brand) {
    const splittt = qs.adjacent_brand.split('-', 2);
    const id = parseInt(splittt[0], 10);
    const name = splittt[1];
    qs.adjacent_brand = { id, name };
  }

  if (qs.category_brands) {
    qs.category_brands = qs.category_brands.map((pps) => {
      const splittt = pps.split('-', 2);
      const id = parseInt(splittt[0], 10);
      const name = splittt[1];
      return { name, id };
    });

    [
      qs.category_brand1,
      qs.category_brand2,
      qs.category_brand3,
      qs.category_brand4,
    ] = qs.category_brands;

    qs.category_brand1 = qs.category_brand1 || null;
    qs.category_brand2 = qs.category_brand2 || null;
    qs.category_brand3 = qs.category_brand3 || null;
    qs.category_brand4 = qs.category_brand4 || null;

    qs.spec_attribute_id = qs.spec_attribute_id || null;
  }

  if (!qs.subCategories) {
    qs.subCategories = [];
  }

  return qs;
}

/**
 * Get any filters that may be on the browser url as Query Parameters
 * Merge these with the default filters
 */
export function getURLFilters() {
  const qs = fromQueryString();

  //  const ret = { ...defaultFilters, ...qs };
  //return ret;
  return qs;
}

/**
 * Check if the browser url has any query params
 */
export function hasQueryString() {
  return history.location.search.length > 0;
}

/**
 * Set the Browser url Query Parameters.
 *
 * This is used to ensure the filter parameters are added to the url
 * when they change.
 * @param {*} filters  The Filters to use
 * @param {*} force    Overwrite any existing parameters
 */
export function setBrowserUrl(filters, force = false) {
  if (!filters) {
    return;
  }

  if (force || !hasQueryString()) {
    history.replace({
      pathname: history.location.pathname,
      search: `?${toQueryString(filters)}${window.location.hash}`
    });
  }
}

/**
 * Clear Browser url Query Parameters.
 *
 */
export function clearBrowserUrlParams() {
  history.replace({
    pathname: history.location.pathname,
    search: '',
  });
}

/**
 * Format the client-side filters to what the api wants.
 * mainly cleans up category_brands, and adjacent_* filters.
 *
 * @param {*} filters  The filters to send to the API
 */
export function cleanFilters(filters) {
  const x = {
    period_interval: filters.period_interval,
    period_end: filters.period_end,
    regions: filters.regions.map((r) => r.toLowerCase()),
    segments: filters.segments,
    categories: filters.categories,
    subCategories: filters.subCategories,
    openspec: filters.openspec,
    // bod: filters.bod,
    qtyShares: filters.qtyShares,

    // send the whole thing for now
    complex: filters.complex,

    projprodstatus: filters.projprodstatus
      ? filters.projprodstatus.filter((f) => f && f.name).map((f) => f.value)
      : [],
    category_brands: [
      filters.category_brand1,
      filters.category_brand2,
      filters.category_brand3,
      filters.category_brand4,
    ]
      .filter((f) => f && f.name)
      .map((f) => f.id),

    adjacent_category: filters.adjacent_category,
    adjacent_brand:
      filters.adjacent_brand && filters.adjacent_brand.id
        ? filters.adjacent_brand.id
        : 0,

    spec_attribute_id: filters.spec_attribute_id,
    project_ids: (filters.project_ids || '').split(',').filter(id => !!id),
  };

  return x;
}

/**
 * dataToState: Given a api endpoint to call, and the location where to store the results
 *              all the api to retrieve the data.
 *
 * @param {*} req       The Request Object, MUST contain a url, MAY contain a filters object
 * @param {*} stateKey  The parameter name, or a function
 * @return {Promise<Object>}  An object with the resulting data
 */
export async function dataToState(req, stateKey) {
  try {
    const response = await resttp.post(req.url, req.filters);

    if (response.error) {
      // if we have any sort of error....  this is bullshit
      const e = response.error;
      if (e.data && e.data.errors) {
        // console.error('rejecting dataToState from post', e.data.errors);
        return Promise.reject(e.data.errors);
      }

      if (e.data && e.data.error) {
        // console.error('rejecting dataToState from post (e)', e);
        if (e.status === 404) {
          e.statusText = 'Didnt find the api call!';
        } else {
          e.statusText = 'some other error!';
        }
        return Promise.reject([e.data.error]);
      }

      // either a request error or a setup error
      // console.error('dataToState from post WITH ERROR', response.error);
      return Promise.reject(response.error);
    }

    const dataset = await response.data;

    if (typeof stateKey === 'function') {
      return stateKey(dataset);
    }
    const x = {};
    x[stateKey] = dataset.data;
    return x;
  } catch (err) {
    // console.error('We got an error!', err);
    return Promise.reject(new Error('prom rej'));
  }
}
