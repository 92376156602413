/**
 * globalStore is the store for our globalState object.
 *
 * see globalReducer and useGlobalState
 */

import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import Reducer from './globalReducer';
import { defaultFilters } from '../lib/massage';

const initialState = {
  status: '',
  errors: [],
  callAPI: null,
  processing: false,
  filterType: '',
  filters: defaultFilters,
  sessiontag: 'nothing yet',
  user: {
    first_name: '',
    last_name: '',
    avatar: '',
    is_admin: false,
    permissions: {},
    firm_name: '',
    firm_avatar: '',
  },
  configuration: {
    can_export: false,
    can_savefilters: false,
    can_favorite: false,
    filters: {
      categories: [],
      subCategories: [],
      regions: [],
      intervals: [],
      periods: [],
      segments: [],
      productStatus: [],
    },
  },
  defaults: {},
};

const GlobalStore = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default GlobalStore;

GlobalStore.propTypes = {
  children: PropTypes.element.isRequired,
};
