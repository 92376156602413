/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { dataToState, cleanFilters, setBrowserUrl, mergeFilters, clearBrowserUrlParams} from '../lib/massage';

import LineChart from '../components/LineChart';
import ValueLabel from '../components/ValueLabel';
import BrandList from '../components/BrandList';
import DataTable from '../components/DataTable';
import MultiMapChart from '../components/MultiMapChart';
import APITimer from '../lib/APITimer';
import useGlobalState from '../hooks/useGlobalState';
import resttp from '../lib/resttp';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
  greenText: {
    color: '#1fcdba',
    fontWeight: 600,
  },
  brandNameLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    marginLeft: 13,
  },
  brandNameValue: {
    fontSize: 13,
    color: '#999',
    textTransform: 'none',
  },
}));

const defaultState = {
  brandName: '-',
  productViews: '-',
  imageViews: '-',
  vendorViews: '-',
  specCount: '-',
  performance: {
    series: [],
    categories: [],
  },
  topProducts: [],
  topStates: [],
  projects: [],
  eventCount: '-',
  competitiveAdj: [],
  complementaryAdj: [],
  topVendors: [],
  stateSearch: [],
  stateSpec: [],

  loadTime: '∞',

  topProductsColumns: [
    { name: 'Logo', column: 'product_image_url', image: true, skipExport: true, span: 0 },
    { name: 'Product Name', column: 'name', span: 2 },
    { name: '% Spec', column: 'spec', valueSuffix: '%', sort: 'desc', digits: 4 },
    { name: '% Search', column: 'search', valueSuffix: '%', digits: 4 },
  ],
  topStatesColumns: [
    { name: 'State', column: 'name' },
    { name: '% Spec', column: 'spec', valueSuffix: '%', sort: 'desc', digits: 4 },
    { name: '% Search', column: 'search', valueSuffix: '%', digits: 4 },
  ],
  projectsColumns: [
    { name: 'Project Name', column: 'name' },
    { name: 'State', column: 'state' },
    { name: '%', column: 'percentage', valueSuffix: '%', sort: 'desc', digits: 4 },
  ],
  competitiveAdjColumns: [
    { name: 'Manufacturer Name', column: 'name' },
    { name: '%', column: 'percentage', valueSuffix: '%', sort: 'desc', digits: 4 },
  ],
  complementaryAdjColumns: [
    { name: 'Manufacturer Name', column: 'name' },
    { name: '%', column: 'percentage', valueSuffix: '%', sort: 'desc', digits: 4 },
  ],
  topVendorsColumns: [
    { name: 'Name', column: 'name' },
    { name: 'Vendor Email', column: 'email' },
    { name: 'City', column: 'city' },
    { name: 'State', column: 'state' },
    { name: '%', column: 'percentage', valueSuffix: '%', sort: 'desc', digits: 4 },
  ],
};

export default function Brand(props) {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState(defaultState);
  const [brandLogos, setBrandLogos] = React.useState([]);
  const [brand, setBrand] = React.useState({
    id: (props.match.params.brandId === 'show') ? 'mybrands' : props.match.params.brandId,
    name: `Loading Brand ${props.match.params.brandId}`,
  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const getBrandLogos = async () => {
    if (globalState.configuration.permissions.mybrands && brand.id === 'mybrands') {
      const res = await resttp.get('/v2/configuration/logos', {});
      setBrandLogos(res.data);
    }
    return Promise.resolve(true);
  };
  const getProjects = async (filters) => {
    if (globalState.configuration.hasfeature.hideprojects) {
      return Promise.resolve(true);
    }
    return dataToState({ url: `/v2/analytics/brand/${brand.id}/topprojects`, filters }, 'projects');
  };

  const callAll = async (inFilters) => {
    setState(defaultState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();
    tim.start();

    const filters = cleanFilters(inFilters);
    try {
      EventRecorder('brand/drill', { brandId: brand.id, filters });

      const stateData = await Promise.all([
        getBrandLogos(),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/name`, filters }, 'brandName'),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/groupcounts`, filters }, data => ({
          eventCount: data.data.events.toLocaleString(),
          imageViews: data.data.images.toLocaleString(),
          vendorViews: data.data.vendors.toLocaleString(),
          productViews: data.data.views.toLocaleString(),
          specCount: data.data.specified.toLocaleString(),
        })),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/complimentary`, filters }, 'complementaryAdj'),

        dataToState({ url: `/v2/analytics/brand/${brand.id}/topproducts`, filters }, (data) => {
          const prods = data.data.map((p) => {
            const ret = Object.assign({}, p);
            if (ret.name && ret.name.toLowerCase() === 'open spec') {
              ret.product_image_url = '/openspec.png';
            }
            return ret;
          });
          return { topProducts: prods };
        }),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/topstates`, filters }, (data) => {
          const spec = data.data.map(itm => [`us-${(itm.name) ? itm.name.toLowerCase() : ''}`, itm.spec]);
          const search = data.data.map(itm => [`us-${(itm.name) ? itm.name.toLowerCase() : ''}`, itm.search]);
          return { stateSpec: spec, stateSearch: search };
        }),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/topvendors`, filters }, 'topVendors'),

        getProjects(filters),

        dataToState({ url: `/v2/analytics/brand/${brand.id}/competitive`, filters }, (data) => {
          return { competitiveAdj: data.data.filter(r => r.manufacturer_id !== parseInt(brand.id, 10)) };
        }),
        dataToState({ url: `/v2/analytics/brand/${brand.id}/brandperformance`, filters }, (data) => {
          const categories = data.data.period;
          const series = [
            { name: 'Spec Percent', data: data.data.spec },
            { name: 'Search Percent', data: data.data.search },
          ];
          return {
            performance: {
              series,
              categories,
            },
          };
        }),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };

  React.useEffect(() => {
    document.title = `Brand Drilldown : ${brand.name}`;
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);

    // My brands view uses different filters!
    // if we have a restricted data view:
    //  DONT use the filters on the url
    //  All available categories are to be used in our search
    //
    if (globalState.user.permissions.mybrands && brand.id === 'mybrands') {
      const urlFilters = {};
      const defs = cloneDeep(globalState.defaults);
      defs.filters.categories = globalState.configuration.filters.categories.map(c => c.id);
      const theFilters = mergeFilters(defs.filters, urlFilters);
      clearBrowserUrlParams();
      callAll(theFilters);
    } else {
      callAll(globalState.filters);
    }
  }, [brand.id]);

  React.useEffect(() => {
    setBrand({
      id: (props.match.params.brandId === 'show') ? 'mybrands' : props.match.params.brandId,
      name: `Loading Brand ${props.match.params.brandId}`,
    });
  }, [props.match.params.brandId]);

  return (
    <Paper className={classes.content}>
      <h1 className={classes.brandNameLabel}>
        BRAND: <span className={classes.brandNameValue}>{state.brandName}</span>
      </h1>
      <Grid container spacing={3}>
        {globalState.configuration.permissions.mybrands && brand.id === 'mybrands' && (
          <Grid item xs={12} sm={12}>
            <BrandList title="My Brand(s)" logos={brandLogos}/>
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <ValueLabel title="Products Specified" value={state.specCount}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ValueLabel title="Product Views" value={state.productViews}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ValueLabel title="Asset Views" value={state.imageViews}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ValueLabel title="Vendor Views" value={state.vendorViews}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LineChart title="Brand Performance Average" useLegend series={state.performance.series}
                     labels={state.performance.categories}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataTable title="Top Products" columns={state.topProductsColumns} records={state.topProducts}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultiMapChart title="Top States" data={{spec: state.stateSpec, search: state.stateSearch}}/>
        </Grid>
        {(!globalState.configuration.hasfeature.hideprojects) && (
          <Grid item xs={12} sm={12}>
            <DataTable title="Projects" columns={state.projectsColumns} records={state.projects}/>
          </Grid>
        )}
        <Grid className={"white-wrapper"} item xs={12} sm={12}>
          <ValueLabel className={classes.greenText} title="Total Events" value={state.eventCount}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataTable title="Competitive Adjacencies" columns={state.competitiveAdjColumns}
                     records={state.competitiveAdj}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataTable title="Complementary Adjacencies" columns={state.complementaryAdjColumns}
                     records={state.complementaryAdj}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataTable title="Top Vendors" columns={state.topVendorsColumns} records={state.topVendors}/>
        </Grid>
      </Grid>
    </Paper>
  );
}

Brand.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brandId: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
