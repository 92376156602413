/* global document */
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  Icon,
  IconButton,
} from '@material-ui/core';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';
import SourceDialog from '../components/SourceDialog';
import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';
import MarketSegmentsPieChart from '../components/MarketSegmentsPieChart';
import resttp from '../lib/resttp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  brandGridList: {
    overflowY: 'visible',
  },
  brandpercentageroot: {
    position: 'absolute',
    bottom: '-15px',
    width: '100%',
  },
  tileroottile: {
    overflow: 'visible',
  },
  tileroot: {
    width: '20%',
    height: '20vw',
    padding: '1vw 1%',
    textAlign: 'center',
  },
  tilerootbrand: {
    '& a': {
      textDecoration: 'none',
    },
    width: '20%',
    height: '20vw',
    padding: '1vw 1%',
    textAlign: 'center',
    borderRight: '1px solid #efefef',
    '&:last-child': {
      borderRight: 'none',
    },
  },

  productItem: {
    background: '#fff',
    height: '4vw',
  },

  productTitleWrap: {
    fontWeight: 'bold',
    color: '#545454',
    fontSize: '14px',
  },

  productSubTitleWrap: {
    color: '#545454',
    fontStyle: 'italic',
    fontSize: '12px',
  },

  productImage: {
    width: '100%',
    borderRadius: '7px',
    border: '1px solid #ccc',
    height: '13.5vw',
  },

  percentage: {
    position: 'absolute',
    top: '7vw',
    left: '1px',
    background: '#1fcdba',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '3px',
    color: '#fff',
  },

  brandpercentage: {
    background: '#1fcdba',
    borderRadius: '16px',
    padding: '3px 10px',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '14px',
  },

  brandImage: {
    width: '50%',
    maxWidth: '7rem',
    minHeight: '7rem',
  },

  viewall: {
    fontSize: '12px',
    color: '#1fcdba',
    marginLeft: '8px',
    fontWeight: '600',
    textDecoration: 'none',
    '&:visited': {
      color: '#1fcdba',
    },
  },

  exportBtn: {
    backgroundColor: 'orange',
    color: '#fff',
    padding: '2px',
    marginTop: '7px',
    marginLeft: '8px',
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: '#1ec5b3',
    },
  },
}));

export default function Overview() {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [loading, setloading] = React.useState(false);
  const [state, setState] = React.useState({
    topbrands: [],
    topproducts: [],
    marketsegments: [],
  });
  const [chartRef, setChartRef] = React.useState(null);

  const updateState = (theState) => {
    setState((prevState) => ({ ...prevState, ...theState }));
  };

  const getTopProducts = async (filters) => {
    if (globalState.configuration.permissions.products) {
      return dataToState(
        {
          url: '/v2/analytics/product/topproducts',
          filters: { ...filters, limit: 10 },
        },
        (data) => {
          return {
            topproducts: data.data.map((p) => {
              const ret = Object.assign({}, p);
              if (
                ret.product_name &&
                ret.product_name.toLowerCase() === 'open spec'
              ) {
                ret.product_image_url = '/openspec.png';
              }
              return ret;
            }),
          };
        }
      );
    }
    return Promise.resolve(true);
  };
  const getTopBrands = async (filters) => {
    if (globalState.configuration.permissions.brands) {
      return dataToState(
        {
          url: '/v2/analytics/brand/topbrands',
          filters: { ...filters, limit: 5 },
        },
        'topbrands'
      );
    }
    return Promise.resolve(true);
  };
  const getMarketSegments = async (filters) => {
    return dataToState(
      {
        url: '/v2/analytics/source/marketsegments',
        filters: { ...filters, limit: 5 },
      },
      'marketsegments'
    );
  };

  const callAll = async (inFilters) => {
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();
    setloading(true);
    tim.start();

    const filters = cleanFilters(inFilters);

    try {
      EventRecorder('home', filters);

      const stateData = await Promise.all([
        getTopProducts(filters),
        getTopBrands(filters),
        getMarketSegments(filters),
      ]);

      tim.finishAPI();

      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }

    tim.stop();
    setloading(false);
    globalState.setStatus(
      `data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`
    );
    globalState.setProcessing(false);
  };

  const getCategoryNames = () => {
    const catIds = globalState.filters.categories;
    const catObjs = globalState.configuration.filters.categories;
    return catIds.map((id) => {
      const obj = catObjs.find((c) => c.id === id);
      return obj.name;
    });
  };

  const handleChartExport = () => {
    chartRef.exportChart({
      type: 'application/pdf',
      filename: `${format(
        new Date(),
        'MM-dd-yyyy_kkmmss'
      )}_projects_distribution`,
    });
  };

  React.useEffect(() => {
    document.title = 'Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    // We have to ensure the browser url has the parameters.
    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  return (
    <div>
      <SourceDialog />
      {globalState.user.permissions.brands && (
        <>
          <div className="label-main">
            Top 5 Brands{' '}
            <Link to="/brands/" className={classes.viewall}>
              View All
            </Link>
          </div>
          <Grid container spacing={0}>
            <Grid className="brand-container" item xs={12}>
              {loading ? <div>Loading Brands</div> : ''}
              {!loading && state.topbrands.length < 1 ? (
                <div>No Brands for this filterset</div>
              ) : (
                ''
              )}
              <GridList
                className={classes.brandGridList}
                cellHeight="auto"
                cols={5}
              >
                {state.topbrands.map((brand) => (
                  <GridListTile
                    classes={{
                      root: classes.tilerootbrand,
                      tile: classes.tileroottile,
                    }}
                    key={`${brand.manufacturer_id}-${brand.sum_spec}`}
                  >
                    <Link to={`/brands/drill/${brand.manufacturer_id}`}>
                      <div className={classes.brandpercentageroot}>
                        <span className={classes.brandpercentage}>
                          {brand.spec_percent}%
                        </span>
                      </div>
                      <img
                        className={classes.brandImage}
                        alt={brand.name}
                        src={brand.image_url}
                      />
                    </Link>
                  </GridListTile>
                ))}
              </GridList>
            </Grid>
          </Grid>
        </>
      )}
      {globalState.user.permissions.products && (
        <>
          <div className="label-main top-spacer-lg">
            Top 10 Products
            <Link to="/products/" className={classes.viewall}>
              View All
            </Link>
          </div>
          <Grid className="products-container" item xs={12}>
            {loading ? <div>Loading Products</div> : ''}
            {!loading && state.topproducts.length < 1 ? (
              <div>No Products for this filterset</div>
            ) : (
              ''
            )}
            <GridList cellHeight="auto" cols={5}>
              {state.topproducts.map((product) => (
                <GridListTile
                  className={classes.tileroot}
                  // not sure why we need to specify style here, even though defined in tileroot class.
                  style={{ height: '20vw', padding: '1vw 1%' }}
                  key={`${product.product_id}-${product.sum_spec}`}
                >
                  <Link to={`/products/drill/${product.product_id}#manufacturer_name=${product.manufacturer_name}`}>
                    <img
                      className={classes.productImage}
                      src={product.product_image_url}
                      alt={product.product_name}
                    />
                    <div className={classes.percentage}>
                      {product.spec_percent}%
                    </div>
                    <GridListTileBar
                      classes={{
                        root: classes.productItem,
                        title: classes.productTitleWrap,
                        subtitle: classes.productSubTitleWrap,
                      }}
                      title={product.product_name}
                      subtitle={<span> by {product.manufacturer_name}</span>}
                    />
                  </Link>
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </>
      )}
      <div className="label-main top-spacer-sm">
        Project Type Distribution
        <IconButton className={classes.exportBtn} onClick={handleChartExport}>
          <Icon className="icon-download" />
        </IconButton>
      </div>
      <Grid className="market-segments-container" item xs={12}>
        <MarketSegmentsPieChart
          categories={getCategoryNames()}
          data={state.marketsegments || []}
          onChartRefUpdate={setChartRef}
        />
      </Grid>
    </div>
  );
}
