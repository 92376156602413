import React, {useCallback, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import GridOnIcon from '@material-ui/icons/GridOn';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  Toolbar,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import resttp from '../lib/resttp';
import { dataToState, cleanFilters } from '../lib/massage';
import DataTable from './DataTable';
import DataSubTable from './DataSubTable';
import EventRecorder from '../lib/eventRecorder';
import { format } from 'date-fns';
import { ExportConfirmDialog } from './ExportConfirmDialog';

const XLSX = require('xlsx');

const drawerWidth = 48;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  paperRoot: {
    flexGrow: 1,
    padding: '10px 40px 10px 10px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#1fcdba',
  },
  whiteText: {
    color: '#fff',
  },
  dialog: {
    position: 'absolute',
    zIndex: '100000',
  },
  dialogcontainer: {
    margin: 0,
    justifyContent: 'left',
  },
  dialogpaper: {
    margin: 0,
  },
  raw: {
    position: 'absolute',
    // just guessing here.
    top: '-62px',
    right: '0',
    height: '60px',
    width: '40px',
    borderRadius: '60px',
  },
  tabWrapper: {
    background: '#f9fafb',
  },
  sourceDataTitle: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: 1000000,
    color: '#fff',
  },
  sourceBorder: {
    borderBottom: '1px solid #e3e7ea',
  },

  exportButton: {
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 600,
    marginLeft: 10,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SourceDialog(props) {
  const classes = useStyles();
  const globalState = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();

  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(null);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [exportType, setExportType] = React.useState('PDF');
  const [csvType, setCsvType] = React.useState('specs');

  const [showRawDataBtn, setShowRawDataBtn] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [state, setState] = React.useState({
    projects: [],
    projectColumns: [
      { name: 'Project Name', column: 'name' },
      { name: 'State', column: 'state' },
      { name: '%', column: 'percentage', valueSuffix: '%', sort: 'desc' },
      { name: '', print: true },
    ],

    owners: [],
    architects: [],
    contractors: [],
    principalColumns: [
      { name: 'Firm Name', column: 'name' },
      { name: '%', column: 'percentage', sort: 'desc' },
    ],
  });
  const [subData, setSubData] = React.useState({
    projectId: 0,
    principals: [],
    products: [],
  });

  const updateState = (theState) => {
    setState((prevState) => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();

    tim.start();

    const filters = cleanFilters(inFilters);

    try {
      EventRecorder('source', filters);

      const stateData = await Promise.all([
        dataToState(
          { url: '/v2/analytics/source/projects', filters },
          'projects'
        ),
        dataToState(
          { url: '/v2/analytics/source/principals', filters },
          (data) => {
            return {
              owners: data.principals.owner,
              architects: data.principals.architect,
              contractors: data.principals.contractor,
            };
          }
        ),
      ]);

      tim.finishAPI();

      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }

    tim.stop();
    globalState.setStatus(
      `data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`
    );
    globalState.setProcessing(false);
  };

  const loadProjectData = async (record) => {
    setLoading(record.id);
    const result = await resttp.post(
      `/v2/analytics/source/projects/${record.id}`,
      cleanFilters(globalState.filters)
    );
    setSubData({
      projectId: record.id,
      principals: result.data.principals,
      products: result.data.products,
    });
    setLoading(null);
  };

  const downloadProjectPdf = async (record) => {
    await resttp.download(
      `/v2/analytics/source/projects/${record.id}/pdf`,
      `${record.id}_${record.name
        .replace(/[^\w\s!?]/g, '')
        .replace(/\s/g, '_')}.pdf`,
      cleanFilters(globalState.filters)
    );
  };

  const downloadProjectCsv = async (record, type = 'specs') => {
    await resttp.download(
      `/v2/analytics/source/projects/${record.id}/csv?type=${type}`,
      `${record.id}_${record.name
        .replace(/[^\w\s!?]/g, '')
        .replace(/\s/g, '_')}_${type}.csv`,
      cleanFilters(globalState.filters)
    );
  };

  const downloadProjectsFull = async (type) => {
    setBackdropOpen(true);
    const query = type === 'CSV' ? `?type=${csvType}` : '';
    try {
      await resttp.download(
        `/v2/analytics/source/projects/full/${type.toLowerCase()}${query}`,
        `${format(
          new Date(),
          'MM-dd-yyyy_kkmmss'
        )}_Projects_${csvType}.${type.toLowerCase()}`,
        cleanFilters(globalState.filters)
      );
      enqueueSnackbar(`${type} Downloaded`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`The ${type} generation failed`, { variant: 'error' });
    }
    setBackdropOpen(false);
  };

  const handleDialogOpen = () => {
    setShowDialog(true);
    // load the data here.
    callAll(globalState.filters);
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [principalType, setPrincipalType] = React.useState(0);
  const handlePrincipalTypeChange = (event, newValue) => {
    setPrincipalType(newValue);
  };

  const exportProjects = async () => {
    // we have to call to download the expanded project dataset since we need all subdata
    const data = await resttp.post(
      '/v2/analytics/source/projects/full',
      cleanFilters(globalState.filters)
    );
    const arr = data.data.data;
    const exportName = 'sourcedata_projects.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(workbook, ws, 'jsonsheet');
    XLSX.writeFile(workbook, exportName);
    setBackdropOpen(false);
  };

  const exportPrincipals = () => {
    // we can use the existing principal dataset, just need to 'merge'
    const data = state.owners.concat(state.architects, state.contractors);
    const exportName = 'sourcedata_principals.xlsx';
    const workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, ws, 'jsonsheet');
    XLSX.writeFile(workbook, exportName);
    setBackdropOpen(false);
  };

  const handleExport = async () => {
    setBackdropOpen(true);
    if (value === 0) {
      exportProjects();
    } else {
      exportPrincipals();
    }
  };


  const handleKeyPress = useCallback((event) => {
    if (
      (event.ctrlKey && event.shiftKey === true && event.key === 'R') ||
      (event.altKey && event.shiftKey === true && event.key === 'R') ||
      (event.altKey && event.shiftKey === true && event.ctrlKey === true)
    ) {
      console.log(`Hot Key pressed: ${event.key}`);
      setShowRawDataBtn(!showRawDataBtn);
    }
  }, [showRawDataBtn]);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <ExportConfirmDialog
        open={showConfirm}
        type={exportType}
        csvType={csvType}
        onClose={() => setShowConfirm(false)}
        onAccept={async () => {
          await downloadProjectsFull(exportType);
          setShowConfirm(false);
        }}
      />
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Button
          variant="contained"
          color="primary"
          className={classes.raw}
          onClick={handleDialogOpen}
          style={{ display: showRawDataBtn ? 'block' : 'none' }}
        >
          <Icon style={{ fontSize: '32px' }} className="icon-RAW" />
        </Button>
        <Dialog
          fullScreen
          classes={{
            root: classes.dialog,
            container: classes.dialogcontainer,
            paper: classes.dialogpaper,
          }}
          onClose={handleDialogClose}
          aria-labelledby="simple-dialog-title"
          open={showDialog}
          TransitionComponent={Transition}
        >
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <CloseIcon
              className={classes.whiteText}
              onClick={handleDialogClose}
            />
          </Drawer>
          <Paper className={classes.paperRoot}>
            <Toolbar>
              <Typography variant="h4" className={classes.sourceDataTitle}>
                Source Data
              </Typography>
              <Button
                className={classes.exportButton}
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                <GridOnIcon />
                &nbsp; Export All
              </Button>
              {value === 0 && (
                <Button
                  className={classes.exportButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExportType('PDF');
                    setShowConfirm(true);
                  }}
                >
                  <PictureAsPdfIcon />
                  &nbsp; PDF All
                </Button>
              )}
              {value === 0 && (
                <Button
                  className={classes.exportButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExportType('CSV');
                    setCsvType('specs');
                    setShowConfirm(true);
                  }}
                >
                  <GridOnIcon />
                  &nbsp; Specs All
                </Button>
              )}
              {value === 0 && (
                <Button
                  className={classes.exportButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExportType('CSV');
                    setCsvType('contacts');
                    setShowConfirm(true);
                  }}
                >
                  <GridOnIcon />
                  &nbsp; Contacts All
                </Button>
              )}
            </Toolbar>

            {globalState.isProcessing && <LinearProgress variant="query" />}

            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.sourceBorder}
            >
              <Tab label="Projects" />
              <Tab label="Principals" />
            </Tabs>
            <TabPanel className={classes.tabWrapper} value={value} index={0}>
              <DataSubTable
                showExport={false}
                height="fit-content"
                rowClick={loadProjectData}
                columns={state.projectColumns}
                records={state.projects}
                subData={subData}
                onPdfDownload={downloadProjectPdf}
                onCsvDownload={downloadProjectCsv}
                loading={loading}
              />
            </TabPanel>
            <TabPanel className={classes.tabWrapper} value={value} index={1}>
              <Tabs
                value={principalType}
                onChange={handlePrincipalTypeChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Architects" />
                <Tab label="Contractors" />
                <Tab label="Owners" />
              </Tabs>
              <TabPanel
                className={classes.tabWrapper}
                value={principalType}
                index={0}
              >
                <DataTable
                  showExport={false}
                  height="fit-content"
                  columns={state.principalColumns}
                  records={state.architects}
                />
              </TabPanel>
              <TabPanel
                className={classes.tabWrapper}
                value={principalType}
                index={1}
              >
                <DataTable
                  showExport={false}
                  height="fit-content"
                  columns={state.principalColumns}
                  records={state.contractors}
                />
              </TabPanel>
              <TabPanel
                className={classes.tabWrapper}
                value={principalType}
                index={2}
              >
                <DataTable
                  showExport={false}
                  height="fit-content"
                  columns={state.principalColumns}
                  records={state.owners}
                />
              </TabPanel>
            </TabPanel>
          </Paper>
        </Dialog>
      </div>
    </>
  );
}
