import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Box } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    height: '100%',
  },
  value: {
    textAlign: 'center',
    color: '#333',
    backgroundColor: '#e3e7ea',
    borderRadius: '5px',
    lineHeight: 1,
  },
  title: {
    fontSize: 14,
    textAlign: 'left',
    fontWeight: 'bold',
    color: 'rgba(58, 66, 69, 0.5)',
    textTransform: 'uppercase',
    padding: '5px 0',
  },

  img: {
    maxWidth: '100px',
    maxHeight: '100px',
    padding: '5px',
    minWidth: '100px',
    borderRadius: '12px',
    border: '1px solid #b9b9b9',
    background: '#ffffff',
    margin: '10px',
  },
});


const CustomizedTable = React.memo((props) => {
  const { classes, logos, title } = props;

  if (logos.length < 1) {
    return (<></>);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.value}>
          {logos.map(logo => (
            <img key={logo} alt="" className={classes.img} src={logo} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
});

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(CustomizedTable);
