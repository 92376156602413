/* global document, alert */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { FormLabel, FormHelperText } from '@material-ui/core';
import { lightFormat, parseISO, formatDistance } from 'date-fns';

import history from '../lib/history';
import resttp from '../lib/resttp';
import DataTable from '../components/DataTable';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
  container: {
    maxHeight: '300px',
    height: '300px',
    overflow: 'auto',
  },
  list: {
    margin: '10px',
    columnCount: 4,
  },
}));

export default function Admin() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    topbrands: [],
    topproducts: [],
    sessionlist: [],
    columns: [
      { name: 'UserId', column: 'user_id' },
      { name: 'UserName', column: 'user_name' },
      { name: 'UserEmail', column: 'email' },
      { name: 'FirmId', column: 'firm_id' },
      { name: 'FirmName', column: 'firm_name' },
      { name: 'User Session #', column: 'user_session_id' },
      { name: 'Start', column: 'session_start', sort: 'desc' },
      { name: 'End', column: 'session_end', sort: 'desc' },
      { name: 'Length', column: 'session_length' },
    ],

  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      document.title = 'Firm Administration';

      const res = await resttp.get('/v2/admin/sessions', {});
      if (res.error) {
        alert('No admin access');
        history.push('/');
      } else {
        updateState({ sessionlist: res.data.map((record) => {
          return {
            user_id: record.user_id,
            user_name: `${record.first_name} ${record.last_name}`,
            email: record.email,
            firm_id: record.firm_id,
            firm_name: record.firm_name,
            user_session_id: record.user_session_id,
            session_start: lightFormat(parseISO(record.session_start), 'yyyy-MM-dd HH:mm:ss'),
            session_end: lightFormat(parseISO(record.session_end), 'yyyy-MM-dd HH:mm:ss'),
            session_length: formatDistance(record.session_length * 1000, 0),
          };
        }) });
      }
    };

    fetchData();
  }, []);

  return (
    <Paper className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormLabel component="legend">Session Activity</FormLabel>
          <FormHelperText>
            User activity summary (last 10k events)
          </FormHelperText>
          <DataTable showExport={false} columns={state.columns} records={state.sessionlist} />
        </Grid>
      </Grid>
    </Paper>
  );
}
