/* global localStorage */

/*
   Request HTTP client.

   Wraps AXIOS http client.

   Creates an axios instance, handles authentication/jwt, and provides a few other helpers

*/
import history from './history';

const FileDownload = require('js-file-download');

const axios = require('axios');

const tokenName = 'jwtToken';

// if fake is set, we actually dont have a valid instance yet.
// this allows us to pass objects on the instance w/o worrying if instance itself is null
let instance = { fake: true };

function getJWT() {
  return localStorage.getItem(tokenName);
}
function clearJWT() {
  localStorage.removeItem(tokenName);
}
function setJWT(token) {
  localStorage.setItem(tokenName, token);
}
function hasJWT() {
  return localStorage.getItem(tokenName);
}

function getAuthorizationHeader() {
  const jwt = getJWT();
  return { Authorization: `Bearer ${jwt}` };
}

function isValidInstance() {
  return !instance.fake;
}

function setInstance() {
  if (hasJWT()) {
    instance = axios.create({
      baseURL: process.env.REACT_APP_API_PATH || '//localhost:4000/api',
      timeout: 350000,
      headers: getAuthorizationHeader(),
    });
  } else {
    instance = { fake: true };
  }

  console.log('ENV', process.env);
}

setInstance();

function login(data) {
  const ret = {
    success: false,
    response: null,
  };

  return axios
    .post(
      process.env.REACT_APP_API_AUTH_PATH || '//localhost:4000/auth/login',
      data
    )
    .then((response) => {
      if (response.status !== 200) {
        // TODO: this is no good   if 200 is the only status we allow,
        // then other statuses need to be handled in a safe and consistent manner
        const err = new Error();
        err.status = response.status;
        err.message = `Unexpected Error ${err.status}`;
        throw err;
      }
      ret.success = true;
      ret.response = response.data;
      setJWT(ret.response.token);
      setInstance();

      return ret;
    })
    .catch((error) => {
      // console.debug('REQ GOT AN ERROR', error);
      const resp = error.response;
      if (!resp.status) {
        ret.response = 'network error';
      } else if (resp.status === 401) {
        ret.response = 'email or password is incorrect';
      } else if (resp.status === 429) {
        ret.response = 'too many requests';
      } else {
        ret.response = error.message;
      }

      return ret;
    });
}

function caller(meth, ...parameters) {
  if (isValidInstance()) {
    return meth(...parameters).catch((error) => {
      // console.debug('resttp caller catch', error.toJSON());
      if (error.response && error.response.status === 401) {
        // unauthorized.
        // since we could be grouping our api calls, we need to be sure to only push
        // the login page to history once.
        if (history.location.pathname !== '/login') {
          history.push('/login');
        }
        return {
          status: 401,
          statusText: 'Unauthorized, please log in',
          error: null,
        };
      }

      const re = {
        status: null,
        statusText: '',
        error: null,
      };

      if (error.response) {
        // we got a response, which means we have a status
        re.status = error.response.status;
        re.statusText = error.response.statusText;
        re.error = error.response;
        // console.error('RESP Err', re);
      } else if (error.request) {
        if (error.message === 'Network Error') {
          re.status = 500;
          re.statusText = 'Network Error';
          re.error = new Error('Network Error');
          // console.error('NETWORK Err', re);
        } else {
          re.status = 500;
          re.statusText = 'Request Error';
          re.error = new Error('Request Error');
          // console.error('REQ Err', re);
        }
      } else {
        re.status = 501;
        re.statusText = 'Setup Error';
        re.error = new Error('Setup error');
        // console.error('501 Err', re);
      }
      return re;
    });
  }
  // no login yet
  return {
    status: 401,
    statusText: 'Unauthorized, log in first',
    error: null,
  };
}

/*
  Our api is just a thin wrapper around the axios instance, with a couple of additional helpers
*/
const api = {
  // has the instance been created, via a successful login.
  isAuthenticated: () => isValidInstance,

  logout: () => {
    clearJWT();
    history.push('/login');
  },

  // returns:
  //  { success: boolean, message: 'error or success message for end-user' }
  login: (email, password) => login({ email, password }),

  //
  // everything below is a wrapper around the axios instance.
  //
  request: (config) => caller(instance.request, config),
  get: (url, config) => caller(instance.get, url, config),
  delete: (url, config) => caller(instance.delete, url, config),
  head: (url, config) => caller(instance.head, url, config),
  options: (url, config) => caller(instance.options, url, config),
  post: (url, data, config) => caller(instance.post, url, data, config),
  put: (url, data, config) => caller(instance.put, url, data, config),
  patch: (url, data, config) => caller(instance.patch, url, data, config),
  getUri: (config) => caller(instance.getUri, config),

  download: async (url, filename, data = null) => {
    try {
      // need arraybuffer to download properly.
      const response = data
        ? await instance.post(url, { ...data }, { responseType: 'arraybuffer' })
        : await instance.get(url, { responseType: 'arraybuffer' });
      //      const response = await apit.get(url, { responseType: 'arraybuffer' });
      FileDownload(response.data, filename);
    } catch (error) {
      throw new Error('Error Downloading File');
    }
  },
};

export default api;
