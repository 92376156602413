
import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LinearProgress, Backdrop, CircularProgress } from '@material-ui/core';

import LeftMenu from './LeftMenu';
import ErrorDisplay from './ErrorDisplay';
import UserInfo from './UserInfo';
import FilterPanel from './Filters';
import { useAuth } from '../hooks/useAuth';
import useGlobalState from '../hooks/useGlobalState';
import CookieConsent from './CookieConsent';

import resttp from '../lib/resttp';
import { defaultFilters, getURLFilters, mergeFilters } from '../lib/massage';

// keys are placeholders right now
let startMenu = [
  {
    title: 'Home',
    path: '/',
    key: 'a',
    locked: true,
    hidden: false,
    access: ['brand', 'product'],
  },
  {
    title: 'Brands',
    path: '/brands/',
    pathkey: 'b',
    children: [
      { title: 'Top Brands', path: '/brands/', key: 'b', locked: true, access: 'brand', hidden: false },
      { title: 'Brand Drilldown', path: '/brands/drill/', key: 'c', locked: true, access: 'brand', hidden: false },
    ],
    key: 'bb',
    locked: true,
    hidden: false,
    access: 'brand',
  },
  { title: 'My Brands', path: '/mybrands/show', key: 'cc', locked: true, access: 'mybrand', hidden: true },
  {
    title: 'Products',
    path: '/products/',
    pathkey: 'd',
    children: [
      { title: 'Top Products', path: '/products/', key: 'd', locked: true, access: 'product', hidden: false },
      { title: 'Product Drilldown', path: '/products/drill/', key: 'e', locked: true, access: 'product', hidden: false },
    ],
    key: 'dd',
    locked: true,
    hidden: false,
    access: 'product',
  },
  {
    title: 'Specs',
    path: '/patterns/',
    pathkey: 'h',
    children: [
      { title: 'Patterns', path: '/patterns/', key: 'h', locked: false, access: 'internal', hidden: false },
      { title: 'Spec Attribute', path: '/specattribute/', key: 'i', locked: false, access: 'internal', hidden: false },
    ],
    key: 'hh',
    locked: false,
    hidden: false,
    access: 'internal',
  },
  { title: 'Adjacency', path: '/adjacency/', key: 'j', locked: false, access: 'internal', hidden: false },
  {
    title: 'Category',
    path: '/category/',
    pathkey: 'k',
    children: [
      { title: 'Categories', path: '/category/', key: 'k', locked: false, access: 'internal', hidden: false },
      { title: 'Brand Category Rank', path: '/brandrank/', key: 'l', locked: false, access: 'internal', hidden: false },
    ],
    key: 'kk',
    locked: false,
    hidden: false,
    access: 'internal',
  },
  {
    title: 'Firm',
    path: '/firm/emails/',
    pathkey: 'm',
    children: [
      { title: 'Emails', path: '/firm/emails/', key: 'm', locked: false, access: 'internal', hidden: false },
      { title: 'User Counts', path: '/firm/', key: 'n', locked: false, access: 'internal', hidden: false },
    ],
    key: 'mm',
    locked: false,
    hidden: false,
    access: 'internal',
  },
  {
    title: 'Brand Info',
    path: '/brandsummary/topbrands/',
    pathkey: 'z',
    children: [
      { title: 'Top Brands (All)', path: '/brandsummary/topbrands/', key: 'z', locked: false, access: 'internal', hidden: false },
      { title: 'Multi Drilldown', path: '/brandsummary/branddrill/', key: 'y', locked: false, access: 'internal', hidden: false },
    ],
    key: 'zz',
    locked: false,
    hidden: false,
    access: 'internal',
  },

  {
    title: 'Events',
    path: '/brandsummary/',
    pathkey: 'o',
    children: [
      { title: 'Event Type Count', path: '/brandsummary/', key: 'o', locked: false, access: 'internal', hidden: false },
      { title: 'Event Summary', path: '/brandeventsummary/', key: 'p', locked: false, access: 'internal', hidden: false },
    ],
    key: 'oo',
    locked: false,
    hidden: false,
    access: 'internal',
  },

  {
    title: 'Maps',
    path: '/map/projects',
    pathkey: 'q',
    children: [
      { title: 'Specs', path: '/map/specs', key: 'q', locked: false, access: 'internal', hidden: false },
      { title: 'Offices', path: '/map/offices', key: 's', locked: false, access: 'internal', hidden: false },
      { title: 'Projects', path: '/map/projects', key: 'r', locked: false, access: 'internal', hidden: false },
      { title: 'Searches', path: '/map/searches', key: 't', locked: false, access: 'internal', hidden: false },
    ],
    key: 'qq',
    locked: false,
    hidden: false,
    access: 'internal',
  },

];

const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflowX: 'auto',
  },
  logo: {
    width: '100%',
    xheight: '100%',
    padding: '20px',
  },
  bottomNav: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
  },
  backdrop: {
    zIndex: 1000000,
    color: '#fff',
  },
}));


export default function LayoutPrivate(props) {
  const classes = useStyles();
  const { children } = props;
  const auth = useAuth();
  const loc = useLocation();
  const [status, setStatus] = useState({ loading: true, error: false, message: 'Loading...' });
  const globalState = useGlobalState();
  const [menu, setMenu] = useState([]);
  const [redirect, setRedirect] = useState(null);

  // given a menu, find the first menu item that is unlocked
  const getFirstActivePath = (theMenu) => {
    if (Array.isArray(theMenu)) {
      for (let i = 0; i < theMenu.length; i++) {
        const m = theMenu[i];
        const cp = getFirstActivePath(m);
        if (cp) {
          return cp;
        }
      }
    }
    if (theMenu.children) {
      const cp = getFirstActivePath(theMenu.children);
      if (cp) {
        return cp;
      }
    }

    if (!theMenu.locked) {
      return theMenu.path;
    }
    return null;
  };


  //
  // if a user enters a path they dont have access to, we redirect them to the FIRST path they
  // do have access to.
  //
  const findPath = (path, theMenu) => {
    for (let i = 0; i < theMenu.length; i++) {
      const m = theMenu[i];
      if (Array.isArray(m)) {
        const xyu = findPath(path, m);
        if (xyu) {
          return xyu;
        }
      }
      if (m.children) {
        const cp = findPath(path, m.children);
        if (cp) {
          return cp;
        }
      }

      if (path === m.path && m.locked === false) {
        return m;
      }

      //
      // we have to check paths like: /brands/drill/12345
      // where the 12345 will not match a menu item
      const cntLoc = path.split('/').length;
      const cntItm = m.path.split('/').length;
      let deep = false;
      if (cntLoc === cntItm || cntLoc + 1 === cntItm) {
        deep = true;
      }
      if (deep && m.locked === false && path.substr(0, m.path.length) === m.path) {
        return m;
      }
    }

    return null;
  };

  //
  //  Given a path... is it locked?
  //
  const validPath = (path, theMenu) => {
    const xx = findPath(path, theMenu);
    if (!xx || xx.locked) {
      return false;
    }
    return true;
  };

  const fetchData = async () => {
    try {
      const res = await resttp.get('/v2/configuration', {});
      // this can't be good right?
      // we dont want to do anything until we have our config.
      // as well, our filters need to be configured ahead of time too.
      await globalState.setDefaults(res.data.defaults);
      const urlFilters = getURLFilters();
      const theFilters = mergeFilters(res.data.defaults.filters, urlFilters);

      globalState.setFilters(theFilters);

      // HACK! -- we want the permissions on the user object.
      res.data.user.permissions = res.data.configuration.permissions;
      globalState.setUser(res.data.user);
      globalState.setConfiguration(res.data.configuration);
      //setStatus({ loading: false, error: false });
    } catch (e) {
      setStatus({ loading: false, error: true, message: 'Error Loading configuration' });
    }
  };
  //
  //  When we load initally, we need to load the user's configuration
  //  this *must* be done.
  React.useEffect(() => {
    fetchData();
  }, []);

  // React.useEffect(() => {
  //   console.log('useffect userchange')
  //   fetchData();
  // }, [globalState.user.permissions]);


  const updateMenuItemByKey = (mm, key, cb) => {
    return mm.map((m) => {
      if (m.key === key) {
        return cb(m);
      }
      if (m.children) {
        m.children = updateMenuItemByKey(m.children, key, cb);
      }
      return m;
    });
  };

  const checkMenuAccess = (menuItem) => {

    const newItem = Object.assign({}, menuItem);
    newItem.children = (!menuItem.children) ? null : menuItem.children.map(c => {
      return checkMenuAccess(c);
    });

    const access = Array.isArray(menuItem.access) ? menuItem.access : [menuItem.access];
    newItem.locked = true;
    if (access.includes('brand') && globalState.configuration.permissions.brands) {
      newItem.locked = false;
    } else if (access.includes('product') && globalState.configuration.permissions.products) {
      newItem.locked = false;
    } else if (access.includes('mybrand') && globalState.configuration.permissions.mybrands) {
      newItem.locked = false;
    } else if (access.includes('internal')) {
      if (globalState.configuration.permissions.internal) {
        newItem.locked = false;
      } else {
        newItem.hidden = true;
      }
    }

    if (newItem.children && newItem.children.find(c => !c.locked)) {
      newItem.locked = false;
    }
    return newItem;
  };

  //
  //  If the configuration changes, we *must* reset our menus.
  //
  React.useEffect(() => {
    //
    // given our menus, make sure we turn on/off access based on our permissions
    //
    const prepareMenus = async () => {
      // console.log('PREPING', [globalState.user, globalState.configuration])
      if (!globalState.user.user_id) return;
      if (!globalState.configuration.permissions) return;

      if (globalState.user.user_id) {
        let prep = startMenu.map(m => checkMenuAccess(m));

        // one exception...
        // if we have brands turned off, and mybrands turned on, we need the menu item
        // to reflect the mybrands item.
        if (!globalState.configuration.permissions.brands
          && globalState.configuration.permissions.mybrands) {
       //     console.log('SETTING UP SPECIAL CASE')
        //  prep = updateMenuItemByKey(prep, 'c', (m) => { m.hidden = true; return m; });
         // prep = updateMenuItemByKey(prep, 'cc', (m) => { m.title = 'Brand Drilldown'; m.hidden = false; return m; });
        //  prep = updateMenuItemByKey(prep, 'bb', (m) => { m.path = '/brands/drill/my'; return m; });
         // console.log(prep);
        }
        setMenu(prep);
        setStatus({ loading: false, error: false });
      }
    };

    prepareMenus();
  }, [globalState.configuration]);




  //
  // when the location changes, we need to ensure we have a valid location
  //
  const checkpath = () => {
    // Check if we are trying to load a locked path
    // if we are, redirect to the first unlocked page.
    // if we have NO valid access, then redirect to no access page.
    if (!validPath(loc.pathname, menu)) {
      let safePath = getFirstActivePath(menu);
      if (!safePath) {
        safePath = '/nomatch';
      }
      console.log('setting a redirect', {loc, menu, safePath})
      return safePath;
    } else {
      return null;
    }
  };

  if (!auth.user) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  // TODO: This is a bad check to ensure we don't continue until we have our config data
  if (status.loading) {
    return (<div>Loading....</div>);
  }
  if (status.error) {
    return (
      <div>
        <h1>ERROR!</h1>
        <p>
          {status.message}
        </p>
      </div>
    );
  }

  const redir = checkpath();
  if (redir) {
    console.log('redirecting to', redir);
    return (
      <Redirect
        to={{
          pathname: redir,
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <LeftMenu menu={menu} />
      <main className={classes.content}>
        <UserInfo />
        {globalState.isProcessing && <LinearProgress variant="query" />}
        <Backdrop className={classes.backdrop} open={globalState.isProcessing}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {globalState.errors.length > 0 ? (
          <ErrorDisplay errors={globalState.errors} />
        ) : ''}

        {loc.pathname !== '/mybrands/show' && <FilterPanel />}

        {children}
      </main>
      <CookieConsent />
    </div>
  );
}

LayoutPrivate.propTypes = {
  children: PropTypes.element.isRequired,
};
