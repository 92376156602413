/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash/cloneDeep';

import history from '../lib/history';
import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import LineChart from '../components/LineChart';
import MapChart from '../components/MapChart';
import DataTable from '../components/DataTable';
import ValueLabel from '../components/ValueLabel';

import useGlobalState from '../hooks/useGlobalState';

import APITimer from '../lib/APITimer';
import SourceDialog from '../components/SourceDialog';

import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f8fafb',
    padding: theme.spacing(1),
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 400,
  },
  brandsBlock: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 400,
  },
}));

const defaultState = {
  topbrands: [],
  specCount: '-',
  medianSpec: '-',
  medianSearch: '-',
  mapInfo: [],
  sampleSize: {
    series: [],
    categories: [],
  },
  topbrandsCols: [
    { name: 'Logo', column: 'image_url', image: true, skipExport: true },
    { name: 'Name', column: 'name' },
    { name: 'Manufacturer Id', column: 'manufacturer_id', skipDisplay: true },
    {
      name: 'Spec Percent',
      column: 'spec_percent',
      valueSuffix: '%',
      sort: 'desc',
      colorValue: true,
      digits: 4,
    },
    {
      name: 'Growth (Real)',
      column: 'spec_growth_real',
      valueSuffix: '%',
      colorValue: true,
      digits: 4,
    },
    {
      name: 'Growth (Absolute)',
      column: 'spec_growth_absolute',
      valueSuffix: '%',
      colorValue: true,
      digits: 4,
    },
  ],
};

export default function Overview() {
  const classes = useStyles();
  const globalState = useGlobalState();

  const [state, setState] = React.useState(defaultState);

  const updateState = (theState) => {
    setState((prevState) => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    const filters = cleanFilters(inFilters);

    const newState = cloneDeep(defaultState);
    if (!filters.qtyShares) {
      newState.topbrandsCols = newState.topbrandsCols.filter(
        (c) => c.column !== 'spec_quantity_share'
      );
    }

    setState(newState);
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);
    const tim = new APITimer();

    tim.start();

    try {
      EventRecorder('brand/overview', filters);

      const stateData = await Promise.all([
        dataToState(
          { url: '/v2/analytics/brand/topbrands', filters },
          'topbrands'
        ),
        dataToState({ url: '/v2/analytics/brand/mapdata', filters }, (data) => {
          const items = Object.keys(data.data).map((key) => [
            `us-${key.toLowerCase()}`,
            data.data[key],
          ]);
          return { mapInfo: items };
        }),
        dataToState(
          { url: '/v2/analytics/brand/samplesize', filters },
          (data) => {
            const categories = data.data.map((r) => r.period_group);
            const series = [
              { name: 'Events', data: data.data.map((r) => r.count) },
            ];
            return {
              sampleSize: {
                series,
                categories,
              },
            };
          }
        ),
        dataToState(
          { url: '/v2/analytics/brand/speccount', filters },
          (data) => ({ specCount: data.data.toLocaleString() })
        ),
        dataToState(
          { url: '/v2/analytics/brand/medians', filters },
          (data) => ({
            medianSpec: `${data.data.spec}%`,
            medianSearch: `${data.data.search}%`,
          })
        ),
      ]);

      tim.finishAPI();

      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }

    tim.stop();
    globalState.setStatus(
      `data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`
    );
    globalState.setProcessing(false);
  };

  const topBrandHandler = (row) => {
    history.push(`/brands/drill/${row.manufacturer_id}`);
  };

  React.useEffect(() => {
    document.title = 'Brand Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    // We have to ensure the browser url has the parameters.
    setBrowserUrl(globalState.filters);

    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <SourceDialog />
      <Grid container spacing={3}>
        <Grid className="brands-block" item xs={12} sm={4}>
          <ValueLabel title="Brands Spec'd" value={state.specCount} />
        </Grid>
        <Grid item className="brands-block" xs={12} sm={4}>
          <ValueLabel title="Median Spec" value={state.medianSpec} />
        </Grid>
        <Grid item className="brands-block" xs={12} sm={4}>
          <ValueLabel title="Median Search" value={state.medianSearch} />
        </Grid>
        <Grid item className="brands-block" xs={12} sm={6}>
          <LineChart
            title="Sample Size"
            yTitle="Count"
            series={state.sampleSize.series}
            labels={state.sampleSize.categories}
          />
        </Grid>
        <Grid item className="brands-block" xs={12} sm={6}>
          <MapChart title="Top States" data={state.mapInfo} />
        </Grid>
        <Grid item className="brands-block top-brands-block" xs={12} sm={12}>
          <DataTable
            height="600px"
            perPage={25}
            title="Brands List"
            columns={state.topbrandsCols}
            records={state.topbrands}
            rowClick={topBrandHandler}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
