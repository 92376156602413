/* global document */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { dataToState, cleanFilters, setBrowserUrl } from '../lib/massage';

import DataTable from '../components/DataTable';

import useGlobalState from '../hooks/useGlobalState';
import APITimer from '../lib/APITimer';
import EventRecorder from '../lib/eventRecorder';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  content: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default function Overview(props) {
  const classes = useStyles();
  const globalState = useGlobalState();
  const [state, setState] = React.useState({
    userPeriod: [],
    userPeriodColumns: [
      { name: 'Period Group', column: 'period_group', sort: 'asc' },
      { name: 'User Count', column: 'count' },
    ],
    firmId: props.match.params.firmId,
    firmName: `Loading Firm ${props.match.params.firmId}`,

  });

  const updateState = (theState) => {
    setState(prevState => ({ ...prevState, ...theState }));
  };

  const callAll = async (inFilters) => {
    const { firmId } = state;
    globalState.setProcessing(true);
    globalState.setStatus('...Reticulating splines...');
    globalState.setErrors([]);

    const tim = new APITimer();
    tim.start();

    const filters = cleanFilters(inFilters);
    try {
      EventRecorder('firm/overview', { firmId, filters });

      const stateData = await Promise.all([
       // dataToState({ url: `/v2/analytics/firm/${firmId}/name` }, 'firmName'),
        dataToState({ url: `/v2/analytics/firm/${firmId}/users`, filters }, 'userPeriod'),
      ]);

      tim.finishAPI();
      const stateObj = Object.assign({}, ...stateData);
      updateState(stateObj);
    } catch (e) {
      // push an error message here.
      if (e instanceof Error) {
        globalState.setErrors(e.message);
      } else {
        globalState.setErrors(e);
      }
      tim.finishAPI();
    }
    tim.stop();
    globalState.setStatus(`data loaded in ${tim.apiTime()}ms shown in ${tim.renderTime()}ms`);
    globalState.setProcessing(false);
  };


  React.useEffect(() => {
    document.title = 'Firm Dashboard';
    globalState.reset();
    globalState.setAPI(callAll);

    setBrowserUrl(globalState.filters);
    callAll(globalState.filters);
  }, []);

  return (
    <Paper className={classes.content}>
      <h2>FirmId: {state.firmId}</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <DataTable title="Firm User Counts" columns={state.userPeriodColumns} records={state.userPeriod} />
        </Grid>
      </Grid>
    </Paper>
  );
}
